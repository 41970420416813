import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TeamMemberService } from '../../../services/settings/team-member/team-member.service';
import { MemberForDisplay } from '../../../../../shared_models/invited-user';
import { DashboardUser, LoggedInDashboardUser } from '../../../../../shared_models/dashboard-user';
import moment from 'moment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as Claims from '../../../../../shared_models/claims';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { HelperService } from 'src/app/services/helper/helper.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DeleteModalService } from '../../../services/delete-modal/delete-modal.service';
import { DeleteModalOptions } from '../../../../../shared_models/deleteModalOptions';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { InviteMemberModalComponent } from './invite-member-modal/invite-member-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgStyle, NgFor, AsyncPipe } from '@angular/common';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { TableHeaderOptions } from '@shared_models/aw-components/tableHeaderOptions';
import { Sort } from '@shared_models/search-params/FilterSortParams';
import { AwDotPopupButtonComponent } from '../../misc/aw-dot-popup-button/aw-dot-popup-button.component';
import { PageLayoutComponent } from '../../misc/aw-page-layout/page-layout.component';

@Component({
    selector: 'app-team-members',
    templateUrl: './team-members.component.html',
    styleUrls: ['./team-members.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, NgFor, MatPaginator, LoadingComponent, InviteMemberModalComponent, AsyncPipe, TranslateModule, PaginatePipe, AwTableComponent, AwDotPopupButtonComponent, PageLayoutComponent]
})
export class TeamMembersComponent implements OnInit {
    teamMembers: MemberForDisplay[];
    openPopupBoxIndex: string;
    teamMemberToEdit: MemberForDisplay;
    user: DashboardUser;
    deleteMemberModalOptions: DeleteModalOptions = { titleTranslationString: 'misc.warning', descriptionTranslateString: 'team_members.delete_confirm_description', buttonTranslationString: 'team_members.delete_member' };
    role$: Observable<Claims.Roles> = this.authService.getRole;
    initLoading: boolean = true;
    isMobile: boolean;
    dropdownComponents: AwDotPopupButtonComponent[] = [];
    dotButtonLoadingIndex: number = -1;
    sortBy = { key: 'name', order: 'asc' };

    totalItems = 0;
    pageSize = 15;
    pageNumber = 0;
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'name',
            title: this.translate.instant('misc.name'),
            width: '25%',
            sortDirection: 'asc'
        },
        {
            sortKey: 'mail',
            title: this.translate.instant('misc.email'),
            width: '27%',
            alignment: 'left',
            sortDirection: 'desc'
        },
        {
            sortKey: 'phone',
            title: this.translate.instant('misc.phone_no'),
            width: '13%',
            alignment: 'left',
            sortDirection: 'desc'
        },
        {
            sortKey: 'role',
            title: this.translate.instant('team_members.role'),
            width: '13%',
            alignment: 'left',
            sortDirection: 'desc'
        },
        {
            sortKey: 'last_active',
            title: this.translate.instant('team_members.last_active'),
            width: '14%',
            alignment: 'left',
            sortDirection: 'desc'
        },
        {
            sortKey: '',
            title: this.translate.instant('navbar.settings'),
            width: '8%',
            alignment: 'right'
        }
    ];

    constructor(
        private helperService: HelperService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private teamMemberService: TeamMemberService,
        private breakpointObserver: BreakpointObserver,
        private deleteModalService: DeleteModalService,
        public authService: AuthService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        await this.getTeamMembers();
        this.initLoading = false;
    }

    async getTeamMembers(): Promise<void> {
        await this.teamMemberService.getTeamMembers().then((response: MemberForDisplay[]) => {
            this.teamMembers = response;
            this.totalItems = this.teamMembers.length;
        });
    }

    async removeMember(member: MemberForDisplay): Promise<void> {
        this.openPopupBoxIndex = '';
        const isConfirmed: boolean = await this.deleteModalService.userHasConfirmedDeletion(this.deleteMemberModalOptions);
        if (isConfirmed) {
            await this.teamMemberService.removeTeamMember(member).then(() => {
                const idx: number = this.teamMembers.indexOf(member);
                this.teamMembers.splice(idx, 1);
                this.teamMembers = this.teamMembers.concat();
                this.totalItems = this.teamMembers.length;
                this.deleteModalService.closeAndResetModal();
            });
        }
    }

    hasMemberBeenCreated(member: MemberForDisplay) {
        if (member.role === Claims.Roles.bookkeeper) {
            return false;
        } else {
            return member.last_active === 0;
        }
    }

    addOrEditEmittedTeamMember(member: MemberForDisplay) {
        this.openPopupBoxIndex = '';
        const editedMember: MemberForDisplay = this.teamMembers.find((teamMember: MemberForDisplay): boolean => {
            return teamMember.mail === member.mail;
        });
        if (editedMember) {
            const index: number = this.teamMembers.indexOf(editedMember);
            this.teamMembers[index] = member;
        } else {
            this.teamMembers.push(member);
        }
        this.teamMembers = this.teamMembers;
    }
    openPopUp(index: string): void {
        this.openPopupBoxIndex = index === this.openPopupBoxIndex ? null : index;
    }

    openEditMemberModal(member: MemberForDisplay, modal: any) {
        this.teamMemberToEdit = member;
        this.openPopupBoxIndex = '';
        this.openModal(modal);
    }
    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {},
            () => {}
        );
    }
    getLastActiveLabel(val: number, role: any): string {
        if (role === Claims.Roles.bookkeeper) {
            return '-';
        } else if (val === 0) {
            return this.translate.instant('customers.invited');
        } else {
            const result = `${moment(val, 'X').locale(this.translate.currentLang).fromNow()}`;
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
    }

    getRoleString(role: Claims.Roles): string {
        return this.translate.instant(`team_members.${role}`);
    }
    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    async resendInvite(member: MemberForDisplay): Promise<void> {
        const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser;
        await this.teamMemberService.resendInviteLink(member, loggedInUser.name).then(() => {
            this.helperService.defaultHtmlToast(this.translate.instant('team_members.invite_resent'), '', 'Success');
        });
        this.openPopupBoxIndex = '';
    }

    async catchSortChanged(event: Sort) {
        function propCompare(prop: string, ascending: boolean) {
            return function compare(a, b) {
                a[prop] === '-' ? (a[prop] = 0) : null;
                b[prop] === '-' ? (b[prop] = 0) : null;
                if (typeof a[prop] === 'string') {
                    return ascending ? a[prop].localeCompare(b[prop], 'en', { numeric: true, sensitivity: 'base' }) : b[prop].localeCompare(a[prop], 'en', { numeric: true, sensitivity: 'base' });
                }
                return ascending ? a[prop] - b[prop] : b[prop] - a[prop];
            };
        }
        console.log(event);

        this.teamMembers = this.teamMembers.slice().sort(propCompare(event.key, event.order === 'asc'));
        if (this.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.sortBy = event;
        this.pageNumber = this.pageNumber;
    }

    async catchDotMenuClicked(event: string, member: MemberForDisplay, index: number, editMemberModal: any): Promise<void> {
        switch (event) {
            case 'team_members.remove':
                this.removeMember(member);
                break;
            case 'team_members.resend_invite_link':
                this.resendInvite(member);
                break;
            case 'team_members.edit_member':
                this.openEditMemberModal(member, editMemberModal);
                break;
        }

        this.dotButtonLoadingIndex = index;
        this.dotButtonLoadingIndex = -1;
    }

    getMenuOptions(data: MemberForDisplay): string[] {
        if (data.role === Claims.Roles.bookkeeper) return ['team_members.remove'];
        if (data.last_active === 0) {
            return ['team_members.resend_invite_link', 'team_members.edit_member', 'team_members.remove'];
        } else {
            return ['team_members.edit_member', 'team_members.remove'];
        }
    }

    onDropdownOpened(openDropdown: AwDotPopupButtonComponent): void {
        this.dropdownComponents.forEach(dropdown => {
            if (dropdown !== openDropdown) {
                dropdown.closeDropdown();
            }
        });
    }
}
