import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // If using Firebase auth
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DataSets, LineComponent } from '../line-chart/line.component';
import { AuthService } from '../../services/auth/auth.service';
import { CustomerService } from '../../services/customer/customer.service';
import { HelperService } from '../../services/helper/helper.service';
import { ChartService } from '../../services/charts/chart.service';
import { LocationService } from '../../services/location/location.service';
import { OverviewService } from '../../services/overview/overview.service';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { Location } from 'shared_models/location';
import { OnboardingState, Details, Persons } from 'shared_models/details';
import { DashboardUser } from 'shared_models/dashboard-user';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { OperatorService } from '../../services/operator/operator.service';
import { AccountState } from '../../../../shared_models/details';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActionsService } from '../../services/actions/actions.service';
import { Action } from '../../../../shared_models/operator/actions';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { SortService } from '../../services/sorting/sort.service';
import { ShallowUserRecord } from 'shared_models/auth-user-record';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import * as Claims from 'shared_models/claims';
import { ContractExpiringListComponent } from './contract-expiring-list/contract-expiring-list.component';
import { ContractPerformanceListComponent } from './contract-performance-list/contract-performance-list.component';
import { SmallOverviewActionsCardComponent } from '../operator/actions/small-overview-actions-card/small-overview-actions-card.component';
import { CustomToolTipComponent } from '../misc/custom-tool-tip/custom-tool-tip.component';
import { FormsModule } from '@angular/forms';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {PayoutDetails, PayoutSettings} from 'shared_models/payouts';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, OnboardingComponent, FormsModule, CustomToolTipComponent, LineComponent, RouterLink, NgFor, SmallOverviewActionsCardComponent, LottieComponent, ContractPerformanceListComponent, ContractExpiringListComponent, AsyncPipe, TranslateModule]
})
export class OverviewComponent implements OnInit, OnDestroy {
    @ViewChild(LineComponent, { static: false }) line: LineComponent;
    isOperator$: Observable<boolean> = this.authService.isOperator;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    isMobile = false;
    showLoadingIndicator = true;
    showLoadingIndicatorForPayoutDetails = true;
    viewOptionForCharts: 'week' | 'month' | 'year' = 'month';
    activeUsersData: {
        week: { active_users_present: number; diffInActiveUsersFromPastToPresent: number };
        month: { active_users_present: number; diffInActiveUsersFromPastToPresent: number };
        year: { active_users_present: number; diffInActiveUsersFromPastToPresent: number };
    };
    active_users_present = 0;
    diffInActiveUsersFromPastToPresent = 0;
    totalRevenue: string = this.helperService.localizeNumberWithCurrency(0);
    nextPayoutDay: string;
    sumPayout: string;
    dataSets: DataSets;
    showSmallLoadingIndicator = false;
    customerDetails: Details;
    isLife: boolean;
    state: OnboardingState = {
        external_account: false,
        capabilities: 'inactive',
        billing: false,
        account_state: AccountState.complete
    };
    persons: Persons = {
        representative: {
            first_name: '',
            last_name: '',
            title: ''
        },
        verified: false
    };
    user: DashboardUser = this.helperService.getUser();
    actions: Action[] = [];
    isSkeleton = true;
    actionLoading = false;
    fromDate: number;
    toDate: number;
    payoutSettings: PayoutSettings;

    options: AnimationOptions = {
        // https://www.npmjs.com/package/ngx-lottie
        path: '../../../assets/animations/jumping_star.json'
    };
    controlledUserActive: boolean;
    onboardingComplete = false;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private authService: AuthService,
        private customerService: CustomerService,
        private helperService: HelperService,
        private chartService: ChartService,
        private locationService: LocationService,
        private toast: ToastrService,
        private overviewService: OverviewService,
        private router: Router,
        private translate: TranslateService,
        public actionService: ActionsService,
        private operatorService: OperatorService,
        private breakpointObserver: BreakpointObserver,
        private onboardingService: OnboardingService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
        const returnUrl: string = localStorage.getItem('returnUrl');
        if (returnUrl) {
            localStorage.removeItem('returnUrl');
            this.router.navigate([`${returnUrl.split('?')[0]}`]); // removing params, router.navigate can not hanlde these
        }
        moment.locale(this.translate.currentLang);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.fromDate = moment().subtract(29, 'days').startOf('day').unix() + moment().utcOffset() * 60;
        this.controlledUserActive = this.helperService.isUserControlled();
        this.showLoadingIndicator = true;
        this.setSubscriptions();
        this.getActions();
    }

    async getCustomerDetails(): Promise<void> {
        return new Promise(resolve => {
            const customerDetailsSub: Subscription = this.customerService
                .readCustomerDetails(this.user.uid)
                .snapshotChanges()
                .subscribe(async (customerDetailsSnapAction: SnapshotAction<Details>) => {
                    customerDetailsSub.unsubscribe();
                    this.customerDetails = customerDetailsSnapAction.payload.val();
                    if (!customerDetailsSnapAction.payload.child('onboarding_state').exists()) return;

                    this.showLoadingIndicator = false;
                    return resolve();
                });
        });
    }

    async setSubscriptions() {
        await this.getCustomerDetails().then(async () => {
            this.onboardingComplete = await this.onboardingService.getOnboardingStatus(this.customerDetails, this.user);
        });
        this.isLife = this.customerDetails && this.customerDetails.agreement_type === 'life';
        this.handleVerification();

        this.state = this.customerDetails.onboarding_state;

        if (this.onboardingComplete === true) {
            const persons = this.customerDetails?.persons;
            if (persons) {
                for (const key in persons) {
                    if (persons[key]?.relationship?.representative) {
                        this.persons = {
                            representative: {
                                first_name: persons[key]?.first_name,
                                last_name: persons[key]?.last_name,
                                title: persons[key]?.relationship?.title
                            },
                            verified: true
                        };
                    }
                    // if requirements is found on any of the persons attached to the account, the verified state should be false.
                    this.persons.verified = persons[key].requirements ? false : this.persons.verified;
                }
            }

            this.active_users_present = 0;
            this.diffInActiveUsersFromPastToPresent = 0;
            this.getNextPayoutAmount();
            // backend_translates
            const translates = {
                current_lang: this.translate.currentLang,
                overview_today: this.translate.instant('overview.today')
            };

            this.chartService.getChartData(translates).then(orderStats => {
                this.dataSets = orderStats;
                if (this.line) {
                    this.line.setChart(this.dataSets ? this.dataSets.last30Days.line : []);
                    this.viewOptionForCharts = 'month';
                }
            });


            this.getActiveUserData();
        }
    }

    goToStripeKYC() {
        this.showSmallLoadingIndicator = true;
        this.onboardingService.getStripeKYCUrl().catch(error => {
            console.error(error);
            this.showSmallLoadingIndicator = false;
        });
    }

    async handleVerification() {
        // verify_email is a value in UNIX UTC seconds set on account creation.
        if (this.customerDetails && this.customerDetails.verify_email) {
            if (this.controlledUserActive) {
                await this.overviewService
                    .fetchUserAsAdmin()
                    .then(async data => {
                        const userRecord: ShallowUserRecord = data;
                        if (!userRecord.email_verified && parseInt(moment().utc(false).format('x')) > parseInt(moment(this.customerDetails.verify_email, 'X').utc(false).add(24, 'hour').format('x'))) {
                            if (userRecord.email) {
                                this.verifyEmailToast(userRecord.email);
                            } else {
                                this.toast.error(this.translate.instant('overview.no_email_found'), this.translate.instant('misc.error'), { disableTimeOut: true });
                            }
                        } else {
                            this.customerService.removeVerifyEmailTimestamp(this.user.uid); // fire and forget
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else if (!firebase.auth().currentUser.emailVerified) {
                // If emailed not verified, and it is more than 24h since the account was created
                if (!firebase.auth().currentUser.emailVerified && parseInt(moment().utc(false).format('x')) > parseInt(moment(this.customerDetails.verify_email, 'X').utc(false).add(24, 'hour').format('x'))) {
                    this.verifyEmailToast(firebase.auth().currentUser.email);
                }
            } else {
                this.customerService.removeVerifyEmailTimestamp(this.user.uid); // fire and forget
            }
        }
    }

    verifyEmailToast(email: string) {
        // This is a workaround to quickly implement a click for a HTML enabled toast (instead of making a custom toast).
        document.addEventListener(
            'click',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (event: any) => {
                // If the clicked element doesn't have the right selector, bail
                if (!event.target.matches('.verifyEmailClikEvent')) return;

                // Don't follow the link
                event.preventDefault();

                // Desired actions below
                this.toast.clear();
                const sendingMessage: string = this.translate.instant('overview.sending_email');
                this.helperService.defaultHtmlToast(this.translate.instant('overview.wait'), sendingMessage, 'Info');

                this.authService.verifyEmail(email).then(() => {
                    for (const toast of this.toast.toasts) {
                        if (toast.message.includes(sendingMessage)) this.toast.remove(toast.toastId); // clears the 'Please wait' toast
                    }
                    this.helperService.defaultHtmlToast(this.translate.instant('overview.email_sent'), this.translate.instant('overview.check_inbox'), 'Success');
                });
            },
            false
        );

        this.toast.info(
            `
            <div>
                <div>
                    <span class="toast-span-bold">${this.translate.instant('overview.verify_account')}</span>
                    </br></br>
                    <span>${this.translate.instant('overview.to_verify')} ${email}</span>
                    </br></br>
                    <span>${this.translate.instant('overview.note_spam')}</span>
                    </br></br>
                    <span>${this.translate.instant('overview.didnt_receive')}</span>
                    </br></br>
                </div>
                <p class="verifyEmailClikEvent btn btn-primary btn-tiny">${this.translate.instant('overview.resend_email')}</p>
            </div>
            `,
            this.translate.instant('misc.info'),
            {
                disableTimeOut: true,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false
            }
        );
    }

    handleDateChange(value: string) {
        switch (value) {
            case 'week':
                this.week();
                break;
            case 'month':
                this.month();
                break;
            case 'year':
                this.year();
                break;
        }
    }

    week() {
        this.setFromDateForChild(7);
        if (this.dataSets && this.line) {
            this.line.setChart(this.dataSets ? this.dataSets.last7Days.line : []);
        }
        this.viewOptionForCharts = 'week';
        this.setActiveUser();
        this.UpdateRevenueOverPeriod();
    }

    month() {
        this.setFromDateForChild(30);
        if (this.dataSets && this.line) {
            this.line.setChart(this.dataSets ? this.dataSets.last30Days.line : []);
        }
        this.viewOptionForCharts = 'month';
        this.setActiveUser();
        this.UpdateRevenueOverPeriod();
    }

    year() {
        this.setFromDateForChild(365);
        if (this.dataSets && this.line) {
            this.line.setChart(this.dataSets ? this.dataSets.last12Months.line : []);
        }
        this.viewOptionForCharts = 'year';
        this.setActiveUser();
        this.UpdateRevenueOverPeriod();
    }

    getActiveUserData() {
        this.chartService.getUserStats().then(users_stats => {
            this.activeUsersData = users_stats;
            this.setActiveUser();
        });
    }

    setActiveUser() {
        this.active_users_present = this.activeUsersData[this.viewOptionForCharts].active_users_present;
        this.diffInActiveUsersFromPastToPresent = this.activeUsersData[this.viewOptionForCharts].diffInActiveUsersFromPastToPresent;
    }

    setFromDateForChild(days) {
        this.fromDate = moment().subtract(days, 'days').startOf('day').unix() + moment().utcOffset() * 60;
        this.operatorService.setFrom(this.fromDate);
    }

    async getNextPayoutAmount() {
        this.overviewService.getNextPayoutAmount().then(async (payoutDetails: PayoutDetails) => {
            this.payoutSettings = await this.overviewService.getPayoutSettings();
            if (!payoutDetails) return;

            const giveEstimatedPayoutDay = (): string => {
                if (this.payoutSettings['interval'] === 'weekly') {
                    const dayOfWeek = moment().isoWeekday();
                    const payoutDay = this.payoutSettings.week_day ? this.payoutSettings.week_day : 1;
                    let daysUntilNextPayout = payoutDay - dayOfWeek;
                    if (daysUntilNextPayout < 0) {
                        daysUntilNextPayout += 7;
                    }
                    const estimatedNextPayoutDay = moment().add(daysUntilNextPayout, 'days');
                    return estimatedNextPayoutDay.format('Do MMM');
                } else {
                    const estimatedNextPayoutDay = moment().add(1, 'month').date(1);
                    const weekDay = estimatedNextPayoutDay.isoWeekday();
                    if (weekDay == 7) {
                        estimatedNextPayoutDay.add(1, 'day');
                    } else if (weekDay == 6) {
                        estimatedNextPayoutDay.add(2, 'day');
                    } else if (weekDay == 5) {
                        estimatedNextPayoutDay.add(3, 'day');
                    } else {
                        estimatedNextPayoutDay.add(1, 'day');
                    }
                    return estimatedNextPayoutDay.format('Do MMM');
                }
            };

            this.sumPayout = this.helperService.localizeNumberWithCurrency(payoutDetails.amount ? payoutDetails.amount / 100 : 0);
            this.nextPayoutDay = payoutDetails.arrival_date ? moment(payoutDetails.arrival_date, 'X').format('Do MMM') : giveEstimatedPayoutDay();
            this.showLoadingIndicatorForPayoutDetails = false;
            this.showSmallLoadingIndicator = false;
        });
    }

    async getActions() {
        this.actionLoading = true;
        this.actions = await this.actionService.getActionsFromBackend().then(response => {
            this.actionLoading = false;
            const actions: Action[] = this.actionService.convertActionsToArray(response);
            this.isSkeleton = false;
            actions.sort(SortService.byPropertiesOf(['log'], 'timestamp'));
            return this.filterActionsToOnlyThree(actions);
        });
    }

    showNoActionsBox(): boolean {
        return this.actionService.showNoActionsBox(this.actions, false);
    }

    filterActionsToOnlyThree(actions: Action[]): Action[] {
        let index = 0;
        const filteredActions: Action[] = [];
        for (const action of actions) {
            if (action.active_actions) {
                if (index < 3) {
                    index++;
                    filteredActions.push(action);
                }
            }
        }
        return filteredActions;
    }

    UpdateRevenueOverPeriod() {
        if (!this.dataSets) {
            return;
        }

        switch (this.viewOptionForCharts) {
            case 'week': {
                let sum = 0;
                this.dataSets.last7Days.line.forEach(elem => {
                    sum += !isNaN(elem.amount) ? elem.amount : 0;
                });
                sum = sum / 100;
                this.totalRevenue = this.helperService.localizeNumberWithCurrency(sum ? sum : 0);
                break;
            }
            case 'month': {
                let sum = 0;
                this.dataSets.last30Days.line.forEach(elem => {
                    sum += !isNaN(elem.amount) ? elem.amount : 0;
                });
                sum = sum / 100;
                this.totalRevenue = this.helperService.localizeNumberWithCurrency(sum ? sum : 0);
                break;
            }
            case 'year': {
                let sum = 0;
                this.dataSets.last12Months.line.forEach(elem => {
                    sum += !isNaN(elem.amount) ? elem.amount : 0;
                });
                sum = sum / 100;
                this.totalRevenue = this.helperService.localizeNumberWithCurrency(sum ? sum : 0);
                break;
            }
        }
    }
}
