import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth/auth.service";
import { Observable, Subscription, lastValueFrom } from "rxjs";
import { Roles } from "../../../../../shared_models/claims";
import { SubCustomer, SubCustomerPermission } from "../../../../../shared_models/sub-customer";
import { CustomerService } from "../../../services/customer/customer.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import moment from "moment/moment";
import { StripeRegions } from "../../../../../shared_models/stripe";
import { DeleteModalOptions } from "../../../../../shared_models/deleteModalOptions";
import { DeleteModalService } from "../../../services/delete-modal/delete-modal.service";
import { AccountService } from "../../../services/account/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "../../../services/helper/helper.service";
import { DashboardUser } from "../../../../../shared_models/dashboard-user";
import { SnapshotAction } from "@angular/fire/compat/database/interfaces";
import { LoadingComponent } from '../../loading/loading.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { AwSwitchComponent } from '@components/misc/aw-switch/aw-switch.component';

interface BalanceStats {
    total: number;
    customerShare: number;
    operatorShare: number
}

@Component({
    selector: 'app-location-control-panel',
    templateUrl: './location-control-panel.component.html',
    styleUrls: ['./location-control-panel.component.scss'],
    standalone: true,
    imports: [AwSwitchComponent, NgIf, CustomToolTipComponent, FormsModule, ReactiveFormsModule, LoadingComponent, AsyncPipe, TranslateModule]
})
export class LocationControlPanelComponent implements OnInit {

    subCustomer: SubCustomer;
    @Input() subCustomerPermissions: SubCustomerPermission;
    @Input() isOperator = false;

    isExpanded = false;
    openAccordions: number[] = [];

    role$: Observable<Roles> = this.authService.getRole
    user: DashboardUser;
    isCustomerOperated = false;
    permissionsUpdating = false;
    disableSaveButton: boolean;
    revenueShareForm: UntypedFormGroup;
    subCustomerBalanceStats: BalanceStats = { total: 0, customerShare: 0, operatorShare: 0 };
    deletingAccount: boolean;

    deleteCustomerModalOptions: DeleteModalOptions = {
        titleTranslationString: 'account.warning',
        descriptionTranslateString: 'locations.are_you_sure',
        buttonTranslationString: 'account.delete_account'
    };
    changingForm = false;
    subCustomerUid: string | null;
    uid: string;
    userCountryCode: string;

    constructor(
        protected authService: AuthService,
        private customerService: CustomerService,
        protected translate: TranslateService,
        private deleteModalService: DeleteModalService,
        private toast: ToastrService,
        private accountService: AccountService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private elementRef: ElementRef,
        private helperService: HelperService,
    ) {
    }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.subCustomerUid = this.route.snapshot.paramMap.get('sub_customer_id');
        this.uid = this.subCustomerUid ? `${this.subCustomerUid}_operated_by_${this.user.uid}` : this.user.uid;
        await this.getSubcustomer();
        this.userCountryCode = this.user.settings.country;
        this.isCustomerOperated = !!window.location.pathname.split('/').includes('customers');
        await this.readSubCustomerDetails();

    }

    private async getSubcustomer() {
        const subcustomerAction: SnapshotAction<SubCustomer> = await lastValueFrom(this.customerService.readSubCustomer(this.user.uid, this.subCustomerUid).snapshotChanges().pipe(take(1)));
        this.subCustomer = subcustomerAction.payload.val();
        this.subCustomer.share = this.subCustomer.share ?? 0;
        this.subCustomer.washer_share = this.subCustomer.washer_share ?? 0;
        this.subCustomer.dryer_share = this.subCustomer.dryer_share ?? 0;
        this.subCustomer.other_share = this.subCustomer.other_share ?? 0;
    }

    openAccordion(index: number): void {
        if (this.openAccordions.includes(index)) {
            this.openAccordions = this.openAccordions.filter(item => item !== index);
        } else {
            this.openAccordions.push(index);
        }
    }

    toggleFilter(): void {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.openAccordions = [];
        }
    }

    @HostListener('document:mousedown', ['$event'])
    clickOut(event: MouseEvent): void {
        if (this.isExpanded && !this.elementRef.nativeElement.contains(event.target)) {
            this.toggleFilter()
        }
    }

    toggleAdvancedShare() {
        this.changingForm = true;
        this.subCustomerPermissions.allow_advanced_share = !this.subCustomerPermissions.allow_advanced_share;
        this.changeControlsOnForm(this.subCustomerPermissions.allow_advanced_share)
        this.revenueShareForm.updateValueAndValidity()
        this.changingForm = false;
    }

    formatAmmount(val: number): string {
        return this.helperService.localizeNumberWithCurrency(val / 100, 2, 2, this.subCustomer.currency)
    }

    onFocus(divRef: HTMLElement) {
        divRef.classList.add('focused');
    }

    onBlur(divRef: HTMLElement) {
        divRef.classList.remove('focused');
    }


    async deleteAccount() {
        const isConfirmed: boolean = await this.deleteModalService.userHasConfirmedDeletion(this.deleteCustomerModalOptions)
        if (isConfirmed) {
            this.accountService.deleteAccountOnBehalf(this.subCustomerUid, false).then(() => {
                this.router.navigate(['/operator/customers']);
                this.toast.success('Success deleting customer', this.translate.instant('misc.success'));
            }).catch(err => {
                console.error(err);
                if (err.status === 400) {
                    this.toast.warning(err.error.error.message, this.translate.instant('misc.attention'), { timeOut: 10 * 1000 });
                } else {
                    this.toast.warning(err.message, this.translate.instant('misc.attention'));
                }
            }).finally(() => {
                this.deleteModalService.closeAndResetModal()
            })
        }
    }

    changeControlsOnForm(allowAdvancedShare: boolean) {
        if (!allowAdvancedShare) {
            this.share.enable();
            this.share.setValue(Math.round(this.subCustomer.share * 100).toString() || '0');
            this.washer_share.disable();
            this.washer_share.setValue(Math.round(this.subCustomer.washer_share * 100).toString() || '-');
            this.dryer_share.disable();
            this.dryer_share.setValue(Math.round(this.subCustomer.dryer_share * 100).toString() || '-');
            this.other_share.disable();
            this.other_share.setValue(Math.round(this.subCustomer.other_share * 100).toString() || '-');
        } else {
            this.share.disable();
            this.share.setValue(Math.round(this.subCustomer.share * 100).toString() || '-');
            this.washer_share.enable();
            this.washer_share.setValue(Math.round(this.subCustomer.washer_share * 100).toString() || '0');
            this.dryer_share.enable();
            this.dryer_share.setValue(Math.round(this.subCustomer.dryer_share * 100).toString() || '0');
            this.other_share.enable();
            this.other_share.setValue(Math.round(this.subCustomer.other_share * 100).toString() || '0');
        }
        this.revenueShareForm.markAsDirty();
    }

    async readSubCustomerDetails() {
        return new Promise(async (resolve: any, reject: any) => {
            if (this.isOperator && this.isCustomerOperated) {
                const allowAdvancedShare: boolean = this.subCustomerPermissions.allow_advanced_share;
                this.setupRevenueForm(allowAdvancedShare);

                const roleSub: Subscription = this.role$.subscribe((role: Roles) => {
                    if (!this.authService.hasLimitedAccess('control_panel_revenue_share', role)) {
                        this.revenueShareForm.disable()
                    }
                })
                roleSub.unsubscribe()


                const subSubBalance = await lastValueFrom(this.customerService.readSubCustomerBalance(
                    this.uid.split('_operated_by_')[1],
                    this.uid.split('_')[0],
                    moment().subtract(29, 'days').startOf('day').unix().toString(),
                    moment().endOf('day').unix().toString()
                ).snapshotChanges().pipe(take(1)));

                for (const item of subSubBalance) {
                    const balance: any = subSubBalance.length ?
                        item.payload.val() :
                        { total: 0, operator_amount: 0 };
                    balance.operator_amount = balance.operator_amount ? balance.operator_amount : 0; // if balance.operator amount is undefined: (balance.total - balance.operator_amount > 0) === false
                    if (this.subCustomerBalanceStats) {
                        this.subCustomerBalanceStats.total += balance.total;
                        this.subCustomerBalanceStats.operatorShare += balance.operator_amount ? balance.operator_amount : 0;
                        this.subCustomerBalanceStats.customerShare += balance.total - balance.operator_amount > 0 ? balance.total - balance.operator_amount : 0;
                    } else {
                        this.subCustomerBalanceStats = {
                            total: balance.total,
                            operatorShare: balance.operator_amount,
                            customerShare: balance.total - balance.operator_amount > 0 ? balance.total - balance.operator_amount : 0
                        };
                    }
                }
            }
            return resolve();
        })
    }

    private setupRevenueForm(allowAdvancedShare: boolean) {
        this.revenueShareForm = this.fb.group({
            minimum_revenue: [this.helperService.localizeNumber(this.subCustomer.minimum_revenue / 100), [Validators.required]],
            share: [{
                value: this.subCustomer.share ? (Math.round(this.subCustomer.share * 10000) / 100).toString() : (!allowAdvancedShare ? '0' : '-'),
                disabled: allowAdvancedShare,
            }, [Validators.required]],
            washer_share: [{
                value: this.subCustomer.washer_share ? (Math.round(this.subCustomer.washer_share * 10000) / 100).toString() : (allowAdvancedShare ? '0' : '-'),
                disabled: !allowAdvancedShare
            }, [Validators.required]],
            dryer_share: [{
                value: this.subCustomer.dryer_share ? (Math.round(this.subCustomer.dryer_share * 10000) / 100).toString() : (allowAdvancedShare ? '0' : '-'),
                disabled: !allowAdvancedShare
            }, [Validators.required]],
            other_share: [{
                value: this.subCustomer.other_share ? (Math.round(this.subCustomer.other_share * 10000) / 100).toString() : (allowAdvancedShare ? '0' : '-'),
                disabled: !allowAdvancedShare
            }, [Validators.required]]
        });
    }

    async switchPermission(permission: string) {
        this.permissionsUpdating = true;
        const updateObj: SubCustomerPermission = { ...this.subCustomerPermissions }
        switch (permission) {
            case 'refund':
                updateObj.allow_refund = !this.subCustomerPermissions?.allow_refund;
                break;

            case 'location':
                updateObj.allow_location = !this.subCustomerPermissions?.allow_location;
                break;

            case 'billing':
                updateObj.allow_billing = !this.subCustomerPermissions?.allow_billing;
                await this.customerService.updateBillingAllowedForSubcustomer(`${this.uid}`, updateObj.allow_billing)
                break;

            case 'coupon':
                updateObj.allow_coupon = !this.subCustomerPermissions?.allow_coupon;
                break;
            default:
                break;
        }

        await this.customerService.updateSubCustomerSettings(`${this.uid}`, updateObj).then(async () => {
            await this.saveChangesFinished(false, this.translate.instant('locations.permissions_updated'));
            this.subCustomerPermissions = updateObj;
        }).catch(async (err) => {
            await this.saveChangesFinished(true)
            console.error(err);
        })
        this.permissionsUpdating = false;
    }

    async saveChanges() {
        if (!this.revenueShareForm.dirty) {
            this.toast.warning(this.translate.instant('locations.no_changes'), this.translate.instant('misc.attention'))
        }

        if (this.revenueShareForm.dirty) {
            this.disableSaveButton = true;
            let revenueShareFormValid = true;

            const allowAdvancedShare = this.subCustomerPermissions.allow_advanced_share;
            const keysToValidate = allowAdvancedShare ? ['washer_share', 'dryer_share', 'other_share'] : ['share'];

            keysToValidate.forEach((key) => {
                if (this.checkValueInvalid(this.revenueShareForm.value[key])) {
                    this.disableSaveButton = false;
                    revenueShareFormValid = false;
                    this.revenueShareForm.get(key).setErrors({ 'invalidNumber': true });
                } else {
                    this.revenueShareForm.get(key).setErrors(null);
                }
            });

            if (allowAdvancedShare) {
                this.revenueShareForm.value.washer_share = this.revenueShareForm.value.washer_share.replaceAll(',', '.')
                this.revenueShareForm.value.dryer_share = this.revenueShareForm.value.dryer_share.replaceAll(',', '.')
                this.revenueShareForm.value.other_share = this.revenueShareForm.value.other_share.replaceAll(',', '.')
            } else {
                this.revenueShareForm.value.share = this.revenueShareForm.value.share.replaceAll(',', '.')

            }

            if (revenueShareFormValid) {
                const revenueShareFormCopy = { ...this.revenueShareForm.value }
                revenueShareFormCopy.minimum_revenue = parseInt(revenueShareFormCopy.minimum_revenue.replaceAll(',', '').replaceAll('.', ''));
                if (allowAdvancedShare) {
                    revenueShareFormCopy.washer_share /= 100
                    revenueShareFormCopy.dryer_share /= 100
                    revenueShareFormCopy.other_share /= 100
                } else {
                    revenueShareFormCopy.share = revenueShareFormCopy.share / 100;
                }

                await this.customerService.updateRevenueShare(this.user.uid, this.subCustomerUid, revenueShareFormCopy, allowAdvancedShare).then(async () => {

                    const noShare = !(revenueShareFormCopy.share || revenueShareFormCopy.washer_share || revenueShareFormCopy.dryer_share || revenueShareFormCopy.other_share);
                    this.customerService.updateSubCustomerSettings(this.uid, { ...this.subCustomerPermissions, no_share: noShare });

                    this.revenueShareForm.patchValue({
                        share: !allowAdvancedShare ? (revenueShareFormCopy.share * 100).toString() : '-',
                        washer_share: allowAdvancedShare ? (revenueShareFormCopy.washer_share * 100).toString() : '-',
                        dryer_share: allowAdvancedShare ? (revenueShareFormCopy.dryer_share * 100).toString() : '-',
                        other_share: allowAdvancedShare ? (revenueShareFormCopy.other_share * 100).toString() : '-'
                    });

                    await this.getSubcustomer();

                    this.saveChangesFinished(false, this.translate.instant('locations.revenue_settings_saved'))
                }).catch((err) => {
                    this.saveChangesFinished(true)
                    console.error(err);
                })
            }
            this.revenueShareForm.markAsPristine();
        }
    }

    async saveChangesFinished(isError: boolean, msg?: string) {
        await this.sleep(0.4).then(() => {
            this.disableSaveButton = false;
        })
        isError ? this.toast.error(this.translate.instant('locations.failed_update'), this.translate.instant('misc.error')) : this.toast.success(msg, this.translate.instant('misc.success'));
    }

    checkValueInvalid(value: number) {
        return !value || ((value > 100 || value < 0) && this.allowRevenueSplit())
    }

    sleep = (seconds: number) => {
        return new Promise(res => {
            setTimeout(res, seconds * 1000);
        });
    };

    // US and AU does not allow direct charges to be split
    allowRevenueSplit(): boolean {
        if (this.user.settings.stripe_region === StripeRegions.EU && (this.userCountryCode === 'AU' || this.userCountryCode === 'US')) {
            return false;
        } else if (this.user.settings.stripe_region === StripeRegions.US && this.userCountryCode === 'IS') { // ISK has some crazy conversion in currency ISK --> USD --> EUR. Payout job cannot handle this therefore locked down until we have a solution for this.
            return false;
        }

        return true;
    }

    storeValue(event: any) {
        let minimumRevenue = event.target.value.replaceAll(',', '.')
        let decimalFactor = 1;

        if (minimumRevenue.includes('.')) {
            const lengthAfterPoint = minimumRevenue.substring(minimumRevenue.lastIndexOf('.') + 1).length;
            decimalFactor = lengthAfterPoint === 0 ? 1 : lengthAfterPoint === 1 ? 10 : lengthAfterPoint === 2 ? 100 : 1;
        }

        minimumRevenue = String(minimumRevenue).replaceAll('.', '')
        minimumRevenue = minimumRevenue / decimalFactor;
        const valueToSet: string = this.helperService.localizeNumber(minimumRevenue, 2, 2)

        if (valueToSet === 'NaN') {
            this.revenueShareForm.get('minimum_revenue').setErrors({ 'invalidNumber': true });
        } else {
            this.revenueShareForm.patchValue({ minimum_revenue: this.helperService.localizeNumber(minimumRevenue, 2, 2) })
            this.revenueShareForm.get('minimum_revenue').setErrors(null);
        }
    }

    // operator control panel save changes revenue share
    get minimum_revenue() {
        return this.revenueShareForm.get('minimum_revenue')
    }

    get share() {
        return this.revenueShareForm.get('share')
    }

    get washer_share() {
        return this.revenueShareForm.get('washer_share')
    }

    get dryer_share() {
        return this.revenueShareForm.get('dryer_share')
    }

    get other_share() {
        return this.revenueShareForm.get('other_share')
    }

}
