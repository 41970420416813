import { Injectable } from '@angular/core';
import { DetailedUser, User } from 'shared_models/user-management';
import { UserStore } from 'shared_models/user-management';
import { HttpService, RequestTypes } from '../helper/http.service';
import { Coupon } from 'shared_models/coupon';

export interface ShallowAppUser {
    displayName: string;
    phoneNumber: string;
}
export interface Options {
    params?: Record<string, string | number | boolean>;
    body?: any;
    onBehalf?: string;
}
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private users: User[];

    constructor(private httpService: HttpService) {}

    setUsers(users: User[]) {
        this.users = users;
    }

    async readUsers(options: { cursor?: string; order_by: string; direction: 'asc' | 'dsc'; customer: 'all' | string; location: 'all' | string }): Promise<UserStore> {
        const users = await this.httpService.dynamicHttp<UserStore>('api_users/', RequestTypes.GET, { params: { params: JSON.stringify(options) } });
        return users ?? null;
    }

    async readUsersCount(uid: string): Promise<number> {
        return this.httpService.dynamicHttp('api_users/users_count', RequestTypes.GET, { params: { uid } });
    }

    async getCurrentUser(userId: string, owner_uid?: string): Promise<DetailedUser> {
        return this.httpService.dynamicHttp(`api_users/${userId}`, RequestTypes.GET, { onBehalf: owner_uid });
    }

    async getCoupons(userId: string, owner_uid: string): Promise<{ applied_coupons: DetailedUser['applied_coupons']; available_coupons: DetailedUser['available_coupons'] }> {
        return this.httpService.dynamicHttp(`api_users/${userId}/coupons`, RequestTypes.GET, { onBehalf: owner_uid });
    }

    removeUsers() {
        this.users = null;
    }

    async createUser(user: ShallowAppUser, onBehalf?: string) {
        const newUser = await this.httpService.dynamicHttp('api_users/', RequestTypes.POST, { body: user, onBehalf });
        return newUser;
    }

    async checkUserExists(phoneNo: string, fingerprint: string) {
        return this.httpService.dynamicHttp<{user_exists:boolean, user_name:string, as_yours: boolean, fingerprint: string}>('api_users/check_user_exist/', RequestTypes.GET, { params: { phoneNo, fingerprint } });
    }

    updateUserPaymentInfo(value: any) {
        return this.httpService.dynamicHttp('api_users/update_user_payment_info', RequestTypes.POST, { body: value });
    }

    removeCreditPayment(value: any) {
        return this.httpService.dynamicHttp('api_users/remove_credit_payment', RequestTypes.POST, { body: value });
    }

    newPaymentCode(value: any) {
        return this.httpService.dynamicHttp('api_users/new_payment_code', RequestTypes.POST, { body: value });
    }

    handleUserBlock(userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/handle_user_block', RequestTypes.POST, { body: { userId }, onBehalf });
    }

    handleTestUser(userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/handle_test_user', RequestTypes.POST, { body: { userId }, onBehalf });
    }

    deleteComment(commentId: string, userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/', RequestTypes.DELETE, { body: { commentId, userId, onBehalf } });
    }

    excludeCoupons(coupons: Coupon[], userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/exclude_coupons', RequestTypes.POST, { body: { coupons, userId, onBehalf } });
    }

    applyCoupons(coupons: Coupon[], userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/apply_coupons', RequestTypes.POST, { body: { coupons, userId, onBehalf } });
    }
}
