import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { TranslateService } from "@ngx-translate/core";
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(localeData)
@Injectable({
    providedIn: 'root'
})

/**
 * Service for handling date and time formatting.
 * @class DateTimeService
 * @constructor
 * @param {TranslateService} translateService - Service for handling translations.
 * @method getDateAndTimeWithSeconds - Returns string in format "DD/MM/YYYY hh:mm:ss" or "MM/DD/YYYY hh:mm:ss" depending on location. Returns "Today" if date is today.
 * @method getDateAndTime - Returns string in format "DD/MM/YYYY hh:mm:ss" or "MM/DD/YYYY hh:mm:ss" depending on location. Returns "Today" if date is today.
 * @method adaptToTimeZone - Returns dayjs date that is adapted to timezone, should always be used to ensure that timezone is accounted for.
 * @example
 * dateTimeService.getDateAndTimeWithSeconds(1620000000, false, false, true)
 * dateTimeService.getDateAndTime(1620000000, false, false, true)
 * 
 * 
 * This should be the used service for handling date and time formatting, all further development should be done in this service.
 * But, this is a dying service, we need to switch to date-fns.
 */
export class DateTimeService {

    constructor(
        private translateService: TranslateService
    ) {
    }

    /**
     * Returns string in format "DD/MM/YYYY hh:mm:ss" or "MM/DD/YYYY hh:mm:ss" depending on location. Returns "Today" if date is today
     * @param {number} timestamp - provided timestamp.
     * @param {boolean} sliceYears - conditional, if true, returns date without year.
     * @param skipTime - conditional, if true, returns time without seconds.
     * @param useSeconds - conditional, if true, returns time with seconds.
     */
    getDateAndTimeWithSeconds(timestamp: number, sliceYears: boolean, skipTime: boolean, useSeconds?: boolean): string {
        // const zoneAdaptedTimestamp = this.adaptToLocale(timestamp)
        // let date = navigator.language === 'en-US' ? zoneAdaptedTimestamp.format('MM/DD/YYYY').replace(/([.])/g, '/') : zoneAdaptedTimestamp.format('L').replace(/([.])/g, '/')
        // // const time = zoneAdaptedTimestamp.format(useSeconds ? 'h:mm:ss' : 'h:mm').replace(/([.])/g, '/')
        // let time: string;
        // if (navigator.language === 'en-US') {
        //     time = zoneAdaptedTimestamp.format(useSeconds ? 'h:mm:ss A' : 'h:mm A');
        // } else {
        //     time = zoneAdaptedTimestamp.format(useSeconds ? 'h:mm:ss' : 'h:mm');
        // }
        // const zoneAdaptedToday = this.adaptToLocale()

        // const currentDate = navigator.language === 'en-US' ? zoneAdaptedToday.format('MM/DD/YYYY').replace(/([.])/g, '/') : zoneAdaptedToday.format('L').replace(/([.])/g, '/')

        // if (skipTime) {
        //     return `${date}`
        // }

        const zoneAdaptedTimestamp = this.adaptToLocale(timestamp)
        let date = zoneAdaptedTimestamp.format('L').replace(/([.])/g, '/')
        // const time = zoneAdaptedTimestamp.format(useSeconds ? 'h:mm:ss' : 'h:mm').replace(/([.])/g, '/')
        let time = zoneAdaptedTimestamp.format('LTS');
        if (!useSeconds) {
            time = zoneAdaptedTimestamp.format('LT');
        }
        const zoneAdaptedToday = this.adaptToLocale()

        const currentDate = zoneAdaptedToday.format('L').replace(/([.])/g, '/')

        if (skipTime) {
            return `${date}`
        }

        if (date === currentDate) {
            return `${this.translateService.instant("overview.today")} ${time}`
        }

        const currentYear = zoneAdaptedToday.year()
        const timeStampYear = zoneAdaptedTimestamp.year()

        if (currentYear === timeStampYear && sliceYears) {
            date = date.slice(0, 5)
            return `${date} - ${time}`
        }
        return `${date} - ${time}`

    }

    getDateAndTime(timestamp: number, sliceYears: boolean, skipTime: boolean, useSeconds?: boolean): string {
        return this.getDateAndTimeWithSeconds(timestamp * 1000, sliceYears, skipTime, useSeconds)
    }

    /**
     * Returns dayjs date that is adapted to timezone, should always be used to ensure that timezone is accounted for.
     * @param {number} timestamp - Uses timestamp if provided, else uses today.
     */
    private adaptToLocale(timestamp?: number): dayjs.Dayjs {
        return dayjs(timestamp).locale(navigator.language)
    }

    getDateFormat(): string {
        try {
            const format = dayjs.localeData().longDateFormat('L')
            return format;
        } catch (e) {
            return dayjs.Ls.en.formats.L;
        }
    }

}

