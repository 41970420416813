/*
Data merged into one single array from JSON object hosted on this website: http://country.io/data/
*/

export const phoneNumberCountryData: {
    code: string;
    country: string;
    callCode: string;
    currency: string;
    lang_code: string;
    phLengthMax: number;
    phLengthMin: number;
    phLength: string;
}[] = [
        {
            "code": "AR",
            "country": "Argentina",
            "callCode": "+54",
            "currency": "ARS",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "CR",
            "country": "Costa Rica",
            "callCode": "+506",
            "currency": "CRC",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "AM",
            "country": "Armenia",
            "callCode": "+374",
            "currency": "AMD",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "AU",
            "country": "Australia",
            "callCode": "+61",
            "currency": "AUD",
            "lang_code": "",
            "phLengthMax": 15,
            "phLengthMin": 5,
            "phLength": "5 to 15"
        },
        {
            "code": "AT",
            "country": "Austria",
            "callCode": "+43",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 13,
            "phLengthMin": 4,
            "phLength": "4 to 13"
        },
        {
            "code": "BY",
            "country": "Belarus",
            "callCode": "+375",
            "currency": "BYR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 9,
            "phLength": "9 to 10"
        },
        {
            "code": "BE",
            "country": "Belgium",
            "callCode": "+32",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "BR",
            "country": "Brazil",
            "callCode": "+55",
            "currency": "BRL",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "BG",
            "country": "Bulgaria",
            "callCode": "+359",
            "currency": "BGN",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 7,
            "phLength": "7 to 9"
        },
        {
            "code": "CA",
            "country": "Canada",
            "callCode": "+1",
            "currency": "CAD",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "CL",
            "country": "Chile",
            "callCode": "+56",
            "currency": "CLP",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "CN",
            "country": "China",
            "callCode": "+86",
            "currency": "CNY",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 5,
            "phLength": "5 to 12"
        },
        {
            "code": "CO",
            "country": "Colombia",
            "callCode": "+57",
            "currency": "COP",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 8,
            "phLength": "8 or 10"
        },
        {
            "code": "HR",
            "country": "Croatia",
            "callCode": "+385",
            "currency": "HRK",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 8,
            "phLength": "8 to 12"
        },
        {
            "code": "CY",
            "country": "Cyprus",
            "callCode": "+357",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 8,
            "phLength": "8 or 11"
        },
        {
            "code": "CZ",
            "country": "Czech Republic",
            "callCode": "+420",
            "currency": "CZK",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 4,
            "phLength": "4 to 12"
        },
        {
            "code": "DK",
            "country": "Denmark",
            "callCode": "+45",
            "currency": "DKK",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "EC",
            "country": "Ecuador",
            "callCode": "+593",
            "currency": "USD",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "EG",
            "country": "Egypt",
            "callCode": "+20",
            "currency": "EGP",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 7,
            "phLength": "7 to 9"
        },
        {
            "code": "EE",
            "country": "Estonia",
            "callCode": "+372",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 7,
            "phLength": "7 to 10"
        },
        {
            "code": "FO",
            "country": "Faroe Islands",
            "callCode": "+298",
            "currency": "DKK",
            "lang_code": "",
            "phLengthMax": 6,
            "phLengthMin": 6,
            "phLength": "6"
        },
        {
            "code": "FI",
            "country": "Finland",
            "callCode": "+358",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 5,
            "phLength": "5 to 12"
        },
        {
            "code": "FR",
            "country": "France",
            "callCode": "+33",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "GE",
            "country": "Georgia",
            "callCode": "+995",
            "currency": "GEL",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "DE",
            "country": "Germany",
            "callCode": "+49",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 13,
            "phLengthMin": 6,
            "phLength": "6 to 13"
        },
        {
            "code": "GI",
            "country": "Gibraltar",
            "callCode": "+350",
            "currency": "GIP",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "GR",
            "country": "Greece",
            "callCode": "+30",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "GL",
            "country": "Greenland",
            "callCode": "+299",
            "currency": "DKK",
            "lang_code": "",
            "phLengthMax": 6,
            "phLengthMin": 6,
            "phLength": "6"
        },
        {
            "code": "HK",
            "country": "Hong Kong",
            "callCode": "+852",
            "currency": "HKD",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 4,
            "phLength": "4, 8 to 9"
        },
        {
            "code": "HU",
            "country": "Hungary",
            "callCode": "+36",
            "currency": "HUF",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "IS",
            "country": "Iceland",
            "callCode": "+354",
            "currency": "EUR", // us stripe does not support ISK as default currency
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 7,
            "phLength": "7 or 9"
        },
        {
            "code": "IN",
            "country": "India",
            "callCode": "+91",
            "currency": "INR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 7,
            "phLength": "7 to 10"
        },
        {
            "code": "ID",
            "country": "Indonesia",
            "callCode": "+62",
            "currency": "IDR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 5,
            "phLength": "5 to 10"
        },
        {
            "code": "IR",
            "country": "Iran",
            "callCode": "+98",
            "currency": "IRR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 6,
            "phLength": "6 to 10"
        },
        {
            "code": "IQ",
            "country": "Iraq",
            "callCode": "+964",
            "currency": "IQD",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 8,
            "phLength": "8 to 10"
        },
        {
            "code": "IE",
            "country": "Ireland",
            "callCode": "+353",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 7,
            "phLength": "7 to 11"
        },
        {
            "code": "IL",
            "country": "Israel",
            "callCode": "+972",
            "currency": "ILS",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "IT",
            "country": "Italy",
            "callCode": "+39",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 0,
            "phLength": "up to 11"
        },
        {
            "code": "JM",
            "country": "Jamaica",
            "callCode": "+1-876",
            "currency": "JMD",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "(876) + 7"
        },
        {
            "code": "JP",
            "country": "Japan",
            "callCode": "+81",
            "currency": "JPY",
            "lang_code": "",
            "phLengthMax": 13,
            "phLengthMin": 5,
            "phLength": "5 to 13"
        },
        {
            "code": "LV",
            "country": "Latvia",
            "callCode": "+371",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 7,
            "phLength": "7 or 8"
        },
        {
            "code": "LB",
            "country": "Lebanon",
            "callCode": "+961",
            "currency": "LBP",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 7,
            "phLength": "7 to 8"
        },
        {
            "code": "LY",
            "country": "Libya",
            "callCode": "+218",
            "currency": "LYD",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "LI",
            "country": "Liechtenstein",
            "callCode": "+423",
            "currency": "CHF",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 7,
            "phLength": "7 to 9"
        },
        {
            "code": "LT",
            "country": "Lithuania",
            "callCode": "+370",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "LU",
            "country": "Luxembourg",
            "callCode": "+352",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 4,
            "phLength": "4 to 11"
        },
        {
            "code": "MK",
            "country": "Macedonia",
            "callCode": "+389",
            "currency": "MKD",
            "lang_code": "",
            "phLengthMax": 0,
            "phLengthMin": 0,
            "phLength": ""
        },
        {
            "code": "MY",
            "country": "Malaysia",
            "callCode": "+60",
            "currency": "MYR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 7,
            "phLength": "7 to 9"
        },
        {
            "code": "MT",
            "country": "Malta",
            "callCode": "+356",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "MX",
            "country": "Mexico",
            "callCode": "+52",
            "currency": "MXN",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "MD",
            "country": "Moldova",
            "callCode": "+373",
            "currency": "MDL",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "MC",
            "country": "Monaco",
            "callCode": "+377",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 5,
            "phLength": "5 to 9"
        },
        {
            "code": "ME",
            "country": "Montenegro",
            "callCode": "+382",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 4,
            "phLength": "4 to 12"
        },
        {
            "code": "MA",
            "country": "Morocco",
            "callCode": "+212",
            "currency": "MAD",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "NL",
            "country": "Netherlands",
            "callCode": "+31",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "NZ",
            "country": "New Zealand",
            "callCode": "+64",
            "currency": "NZD",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 3,
            "phLength": "3 to 10"
        },
        {
            "code": "NG",
            "country": "Nigeria",
            "callCode": "+234",
            "currency": "NGN",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 7,
            "phLength": "7 to 10"
        },
        {
            "code": "NO",
            "country": "Norway",
            "callCode": "+47",
            "currency": "NOK",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 5,
            "phLength": "5 or 8"
        },
        {
            "code": "OM",
            "country": "Oman",
            "callCode": "+968",
            "currency": "OMR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 7,
            "phLength": "7 to 8"
        },
        {
            "code": "PK",
            "country": "Pakistan",
            "callCode": "+92",
            "currency": "PKR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 8,
            "phLength": "8 to 11"
        },
        {
            "code": "PA",
            "country": "Panama",
            "callCode": "+507",
            "currency": "PAB",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 7,
            "phLength": "7 or 8"
        },
        {
            "code": "PY",
            "country": "Paraguay",
            "callCode": "+595",
            "currency": "PYG",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 5,
            "phLength": "5 to 9"
        },
        {
            "code": "PE",
            "country": "Peru",
            "callCode": "+51",
            "currency": "PEN",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 8,
            "phLength": "8 to 11"
        },
        {
            "code": "PH",
            "country": "Philippines",
            "callCode": "+63",
            "currency": "PHP",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 8,
            "phLength": "8 to 10"
        },
        {
            "code": "PL",
            "country": "Poland",
            "callCode": "+48",
            "currency": "PLN",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 6,
            "phLength": "6 to 9"
        },
        {
            "code": "PT",
            "country": "Portugal",
            "callCode": "+351",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 9,
            "phLength": "9 or 11"
        },
        {
            "code": "QA",
            "country": "Qatar",
            "callCode": "+974",
            "currency": "QAR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 3,
            "phLength": "3 to 8"
        },
        {
            "code": "RO",
            "country": "Romania",
            "callCode": "+40",
            "currency": "RON",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "RU",
            "country": "Russia",
            "callCode": "+7",
            "currency": "RUB",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "SA",
            "country": "Saudi Arabia",
            "callCode": "+966",
            "currency": "SAR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "RS",
            "country": "Serbia",
            "callCode": "+381",
            "currency": "RSD",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 4,
            "phLength": "4 to 12"
        },
        {
            "code": "SG",
            "country": "Singapore",
            "callCode": "+65",
            "currency": "SGD",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 8,
            "phLength": "8 to 12"
        },
        {
            "code": "SK",
            "country": "Slovakia",
            "callCode": "+421",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 4,
            "phLength": "4 to 9"
        },
        {
            "code": "SI",
            "country": "Slovenia",
            "callCode": "+386",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "KR",
            "country": "South Korea",
            "callCode": "+82",
            "currency": "KRW",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 8,
            "phLength": "8 to 11"
        },
        {
            "code": "ES",
            "country": "Spain",
            "callCode": "+34",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "SE",
            "country": "Sweden",
            "callCode": "+46",
            "currency": "SEK",
            "lang_code": "",
            "phLengthMax": 13,
            "phLengthMin": 7,
            "phLength": "7 to 13"
        },
        {
            "code": "CH",
            "country": "Switzerland",
            "callCode": "+41",
            "currency": "CHF",
            "lang_code": "",
            "phLengthMax": 12,
            "phLengthMin": 4,
            "phLength": "4 to 12"
        },
        {
            "code": "SY",
            "country": "Syria",
            "callCode": "+963",
            "currency": "SYP",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 8,
            "phLength": "8 to 10"
        },
        {
            "code": "TW",
            "country": "Taiwan",
            "callCode": "+886",
            "currency": "TWD",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "TH",
            "country": "Thailand",
            "callCode": "+66",
            "currency": "THB",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 or 9"
        },
        {
            "code": "TN",
            "country": "Tunisia",
            "callCode": "+216",
            "currency": "TND",
            "lang_code": "",
            "phLengthMax": 8,
            "phLengthMin": 8,
            "phLength": "8"
        },
        {
            "code": "TR",
            "country": "Turkey",
            "callCode": "+90",
            "currency": "TRY",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "UA",
            "country": "Ukraine",
            "callCode": "+380",
            "currency": "UAH",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 9,
            "phLength": "9"
        },
        {
            "code": "AE",
            "country": "United Arab Emirates",
            "callCode": "+971",
            "currency": "AED",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 8,
            "phLength": "8 to 9"
        },
        {
            "code": "GB",
            "country": "United Kingdom",
            "callCode": "+44",
            "currency": "GBP",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 7,
            "phLength": "7 to 10"
        },
        {
            "code": "US",
            "country": "United States",
            "callCode": "+1",
            "currency": "USD",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "UY",
            "country": "Uruguay",
            "callCode": "+598",
            "currency": "UYU",
            "lang_code": "",
            "phLengthMax": 11,
            "phLengthMin": 4,
            "phLength": "4 to 11"
        },
        {
            "code": "VA",
            "country": "Vatican",
            "callCode": "+379",
            "currency": "EUR",
            "lang_code": "",
            "phLengthMax": 0,
            "phLengthMin": 0,
            "phLength": ""
        },
        {
            "code": "VE",
            "country": "Venezuela",
            "callCode": "+58",
            "currency": "VEF",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10"
        },
        {
            "code": "VN",
            "country": "Vietnam",
            "callCode": "+84",
            "currency": "VND",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 7,
            "phLength": "7 to 10"
        },
        {
            "code": "YE",
            "country": "Yemen",
            "callCode": "+967",
            "currency": "YER",
            "lang_code": "",
            "phLengthMax": 9,
            "phLengthMin": 6,
            "phLength": "6 to 9"
        },
        {
            "code": "ZA",
            "country": "South Africa",
            "callCode": "+27",
            "currency": "ZAR",
            "lang_code": "",
            "phLengthMax": 10,
            "phLengthMin": 10,
            "phLength": "10",
        },
    ]
