<app-custom-modal>
    <ng-container modal-title>
        <h4>
            {{
                showDeleteContract
                    ? ("operator.contract_modal.delete_contract" | translate)
                    : this.isEdit
                      ? ("operator.contract_modal.edit_contract" | translate)
                      : ("operator.contract_modal.create_contract" | translate)
            }}
        </h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="!showDeleteContract ? modalService.dismissAll('Cancel') : (showDeleteContract = false)"
            ><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"
        /></a>
    </ng-container>
    <ng-container modal-body>
        <form [formGroup]="contractForm" [hidden]="showDeleteContract">
            <input type="text" id="closeButton" hidden *ngIf="isEdit" />

            <!--    Contract ID-->
            <div class="input-group-wrapper-feedback custom-tool-tip-wrapper">
                <div class="flex-div" style="justify-content: space-between">
                    <label for="contract_id">{{ "operator.contract_modal.contract_id" | translate }}</label>
                    <img
                        (mouseenter)="showInfoHoverContract = true"
                        (mouseleave)="showInfoHoverContract = false"
                        *ngIf="!isMobile"
                        src="../../../assets/icons/info-circle.svg"
                        alt="{{ 'operator.contract_modal.contract_id_tooltip' | translate }}"
                    />
                    <div class="custom-tool-tip-wrapper custom-tool-tip placement-down" *ngIf="showInfoHoverContract" style="margin-top: 40px; min-width: 520px">
                        <div class="mb-2">
                            <span><img style="margin-bottom: 2.5px" src="../../../assets/icons/modal-info-icon.svg" alt="{{ 'overview.active_users' | translate }}" /></span>
                            <span class="tooltip-title">{{ "operator.contract_modal.contract_id" | translate }}</span>
                        </div>
                        <div>
                            <span>{{ "operator.contract_modal.contract_id_tooltip" | translate }}</span>
                        </div>
                    </div>
                </div>
                <input class="form-control" id="contract_id" type="text" required formControlName="contract_id" name="contract_id" />
                <div class="feedback-container">
                    @if (formSubmitted && contract_id.invalid) {
                        <div class="error-msg" *ngIf="contract_id.errors.pattern">
                            {{ "misc.invalid_pattern" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="contract_id.errors.required">
                            {{ "operator.contract_modal.contract_id_error" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="contract_id.errors.maxlength">
                            {{ "operator.contract_modal.contract_id_error_max" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!--    Revenue Target-->
            <div class="input-group-wrapper-feedback">
                <label for="rev-target">{{ "operator.contract_modal.revenue_target" | translate }}</label>
                <div class="input-group">
                    <input class="form-control" type="text" id="rev-target" formControlName="revenue_target" (change)="storeValue($event)" />
                    <div class="input-group-append">
                        <span class="input-group-text">{{ this.currency ? this.currency.toUpperCase() : "-" }}</span>
                    </div>
                </div>
                <div class="feedback-container">
                    @if (formSubmitted && revenue_target.invalid) {
                        <div class="error-msg" *ngIf="revenue_target.errors.required">
                            {{ "operator.contract_modal.revenue_target_error_missing" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="revenue_target.errors.min">
                            {{ "operator.contract_modal.revenue_target_error_zero" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="revenue_target.errors.invalidNumber">
                            {{ "operator.contract_modal.revenue_target_error_invalid" | translate }}
                        </div>
                    }
                </div>
                <mat-radio-group class="aw-radio-wrapper mt-2" formControlName="timespan">
                    <mat-radio-button class="aw-radio" value="monthly" labelPosition="before">
                        {{ "operator.contract_modal.monthly" | translate }}
                    </mat-radio-button>
                    <mat-radio-button class="aw-radio" value="yearly" labelPosition="before" [checked]="!isEdit">
                        {{ "operator.contract_modal.yearly" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="formSubmitted && timespan.invalid">
                    <div class="error-msg" *ngIf="timespan.errors.required">
                        {{ "operator.contract_modal.timespan_error" | translate }}
                    </div>
                </div>
            </div>

            <!--    Billable Amount-->
            <div class="input-group-wrapper">
                <div class="flex-div custom-tool-tip-wrapper">
                    <label>{{ "operator.contract_modal.billable" | translate }}</label>
                    <img
                        (mouseenter)="showInfoHoverBillable = true"
                        (mouseleave)="showInfoHoverBillable = false"
                        *ngIf="!isMobile"
                        src="../../../assets/icons/info-circle.svg"
                        alt="{{ 'operator.contract_modal.billable_tooltip' | translate }}"
                    />
                    <div class="custom-tool-tip-wrapper custom-tool-tip placement-down" *ngIf="showInfoHoverBillable" style="margin-top: 35px; min-width: 520px">
                        <div class="mb-2">
                            <span><img style="margin-bottom: 2.5px" src="../../../assets/icons/modal-info-icon.svg" alt="{{ 'overview.active_users' | translate }}" /></span>
                            <span class="tooltip-title">{{ "operator.contract_modal.billable" | translate }}</span>
                        </div>
                        <div>
                            <span>{{ "operator.contract_modal.billable_tooltip" | translate }}</span>
                        </div>
                    </div>
                </div>
                <mat-checkbox formControlName="billable" value="show" disableRipple="true" style="width: 18px; height: 18px">
                    {{ "operator.contract_modal.billable_text" | translate }}
                </mat-checkbox>
            </div>

            <!--  Customer-->
            <div class="input-group-wrapper">
                <label>{{ this.isEdit ? ("misc.customer" | translate) : ("operator.contract_modal.choose_customer" | translate) }}</label>
                <input *ngIf="loading" disabled class="form-select loading-text" value="{{ 'operator.contract_modal.fetching_customer' | translate }}" />
                <select id="customer" name="customer" class="form-select" (change)="onChangeCustomer($event.target.value)" [hidden]="loading" [disabled]="isEdit">
                    <option *ngFor="let customer of customers" [value]="customer.id" [selected]="customer.id === uid">
                        {{ checkTranslate(customer.name) }}
                    </option>
                </select>
            </div>

            <!--      Locations-->
            <div class="input-group-wrapper-feedback">
                <label>{{ "operator.contract_modal.choose_locations" | translate }}</label>
                <div class="locations-div">
                    <div *ngIf="loading" class="loading-box" style="height: 100%">
                        <loading-indicator [size]="'large'" [color]="'secondary'" class="loading-box"></loading-indicator>
                    </div>
                    <cdk-virtual-scroll-viewport itemSize="{{ possibleLocations.length }}" style="height: 100%" id="scroll-bar" *ngIf="!loading">
                        <div class="circle-checkbox location-item" *cdkVirtualFor="let location of possibleLocations">
                            <input
                                type="checkbox"
                                id="{{ location.id }}"
                                [value]="location.id"
                                [disabled]="location.name === ''"
                                [checked]="isEdit && this.checkIfIsOnContract(location.id)"
                                (change)="onLocationChange($event)"
                            />
                            <label for="{{ location.id }}">{{ location.name }}</label>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
                <div class="feedback-container">
                    @if (formSubmitted && locations.invalid) {
                        <div class="error-msg" *ngIf="locations.errors.required">
                            {{ "operator.contract_modal.choose_locations_error" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!-- Dates -->
            <div class="input-group-wrapper">
                <div class="flex-div">
                    <label>{{ "operator.contract_modal.start_date" | translate }}</label>
                    <label class="optional-text"
                        ><i>({{ "misc.optional" | translate }})</i></label
                    >
                </div>
                <input
                    id="contract-start"
                    name="start"
                    class="form-select form-control data-select pointer datepicker-field"
                    readonly
                    placeholder="{{ 'operator.filter_bar.date_from' | translate }}"
                    type="text"
                    (changeDate)="datePickerChanged('start')"
                />
            </div>

            <div class="input-group-wrapper">
                <div class="flex-div">
                    <label>{{ "operator.contract_modal.end_date" | translate }}</label>
                    <label class="optional-text"
                        ><i>({{ "misc.optional" | translate }})</i></label
                    >
                </div>
                <input
                    id="contract-end"
                    name="end"
                    class="form-select form-control data-select pointer datepicker-field"
                    readonly
                    placeholder="End date"
                    type="text"
                    [min]=""
                    (changeDate)="datePickerChanged('end')"
                />
                <div *ngIf="formSubmitted && end_date.invalid">
                    <div class="error-msg" *ngIf="end_date.errors.min">
                        {{ "operator.contract_modal.end_date_error_min" | translate }}
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="showDeleteContract">
            <div>
                <p>{{ "operator.contract_modal.confirm_delete" | translate }}</p>
            </div>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-grid gap-2" *ngIf="!showDeleteContract">
            <button class="btn btn-secondary full-width" (click)="onSubmit()" [disabled]="sendingData || loading">
                {{ sendingData ? "" : buttonText }}
                <loading-indicator [size]="'small'" [color]="'white'" *ngIf="this.sendingData"></loading-indicator>
            </button>
            <button type="button" class="btn-link-dark-dark" *ngIf="isEdit" (click)="showDeleteContract = true">{{ "operator.contract_modal.delete_contract" | translate }}</button>
        </div>
        <div class="d-grid gap-2" *ngIf="showDeleteContract">
            <button class="btn btn-danger" [disabled]="deletingContractLoading" (click)="deleteContract()">
                {{ deletingContractLoading ? "" : ("operator.contract_modal.delete_contract_now" | translate) }}
                <loading-indicator *ngIf="deletingContractLoading" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>
