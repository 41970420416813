import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/compat/database";
import { AuthService } from "../auth/auth.service";
import { AccountResponse } from 'shared_models/account';
import { InvitedUserDetails } from "../../../../shared_models/invited-user";
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AccountService {
    baseUrl: string = environment.baseUrl

    constructor(
        private authService: AuthService,
        private db: AngularFireDatabase,
        private firebaseAuth: AngularFireAuth,
        private http: HttpClient,
    ) { }

    async getAccount(): Promise<AccountResponse> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(
                `${this.baseUrl}/api_account/get_account/`,
                await this.authService.getHeadersWithoutOwner()
            )).then((resp: any) => {
                return resolve(resp).data
            }).catch((err) => {
                return reject(err);
            });
        })
    }

    async updateTeamMemberEmailSettings(uid: string, details: InvitedUserDetails): Promise<any> {
        try {
            await this.db.list(`/customers/${uid}`).set('details', details);
        } catch (e) {
            console.error("Error updating team member email settings", e.message)
        }
    }



    async updateAccount(data: any): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${this.baseUrl}/api_account`, { data },
            )).then((resp: any) => {
                return resolve(resp)
            }).catch((err) => {
                return reject(err);
            });
        })
    }

    async updateEmailAsAdmin(email: string, uid: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${this.baseUrl}/api_account/admin_update_email`, { email, uid },
                await this.authService.onlyUseBaseHeaders()
            )).then((resp: any) => {
                return resolve(resp)
            }).catch((err) => {
                return reject(err)
            })
        })
    }

    async updateEmailInDB(email: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${this.baseUrl}/api_account/update_email`, { email },
                await this.authService.getBaseHeadersControl()
            )).then(() => {
                return resolve(true)
            }).catch((err) => {
                return reject(err);
            })
        })
    }
    async adminUpdateEmailInDB(email: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${this.baseUrl}/api_account/admin_update_user`, { email },
            )).then(() => {
                return resolve(true)
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    async updateEmailInFirebaseAuth(email: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            const currentUser = await this.firebaseAuth.currentUser.catch((err: any) => {
                return reject(err)
            });
            return currentUser.updateEmail(email).then(() => {
                return resolve();
            }).catch((err: any) => {
                return reject(err)
            })
        })
    }

    async deleteAccount(signOut: boolean): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.delete(
                `${this.baseUrl}/api_account/delete_and_reject`,
                await this.authService.getBaseHeadersControl()))
                .then(() => {
                    if (signOut)
                        this.authService.signOut();
                    return resolve(true)
                }).catch((err) => {
                    return reject(err);
                });
        })
    }
    async deleteAccountOnBehalf(uid: string, signOut: boolean): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.delete(
                `${this.baseUrl}/api_account/delete_and_reject`,
                await this.authService.addHeaders(uid)))
                .then(() => {
                    if (signOut)
                        this.authService.signOut();
                    return resolve(true)
                }).catch((err) => {
                    return reject(err);
                });
        })
    }

    getBusinessType(uid: string): AngularFireObject<string> {
        return this.db.object(`customers/${uid}/details/business_type`);
    }


}