import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../../helper/http.service';
import { ShallowActivityDetails } from 'shared_models/user-management';

@Injectable({
    providedIn: 'root'
})
export class ActivityLoggingService {

    constructor(
        private httpService: HttpService
    ) { }

    async logActivity(activityDetails: ShallowActivityDetails, userId: string, onBehalf: string) {
        return new Promise((resolve, reject) => {
            const params = {
                userId,
                onBehalf,
                comment: activityDetails.comment,
                author: activityDetails.author ? activityDetails.author : null,
                status_type: activityDetails.status_type,
                amount: activityDetails.amount ? activityDetails.amount : null,
            }
            this.httpService.dynamicHttp('api_users/log_activity',
                RequestTypes.POST, { body: params }).then((res) => {
                    resolve(res);
                }
                ).catch((err) => {
                    reject(err);
                })
        })
    }



}