<div class="container mb-5">
    <div class="title-area-inner">
        <div class="d-flex justify-content-between">
            <h2 class="mb-3" *ngIf="!deletingAccount">{{ "account.account" | translate }}</h2>
            <app-animated-button>
                <div class="animated-button-btns-wrapper">
                    <button class="btn btn-secondary btn-tiny" (click)="openModal(emailDialog)">{{ "account.change_email" | translate }}</button>
                    <button class="btn btn-secondary btn-tiny" (click)="openModal(pass)">{{ "account.change_password" | translate }}</button>
                    <button class="btn btn-secondary btn-tiny" (click)="openModal(pickLanguage)">{{ "account.change_language" | translate }}</button>
                    <button class="btn btn-secondary btn-tiny" (click)="deleteAccount()">{{ "account.delete_account" | translate }}</button>
                </div>
            </app-animated-button>
        </div>
    </div>
    <div class="box" *ngIf="formRdy">
        <div class="form-group">
            <div class="email-title-and-btn-wrapper">
                <p class="large-label my-0">{{ "account.email_addr" | translate }}</p>
                <!-- <button class="btn btn-secondary email-btn" (click)="openModal(emailDialog)">{{'account.change_email' |
                    translate}}</button> -->
            </div>
            <div class="email-address">{{ details.email }}</div>
        </div>
        <!--    Account Owner Form-->
        <form [formGroup]="accountForm" *ngIf="isOwner">
            <div class="horizonatal-line"></div>

            <div class="d-flex justify-content-between">
                <p class="large-label">{{ "account.email_settings" | translate }}</p>
            </div>

            <div class="input-group-wrapper">
                <div class="checkbox d-flex justify-content-between">
                    <input type="checkbox" id="accounting_email_check" [checked]="true" formControlName="accounting_email_check" name="accounting_email_check" />
                    <label for="accounting_email_check">{{ "misc.accounting_emails" | translate }}</label>
                    <app-custom-tool-tip
                        *ngIf="!hasBookkeeper"
                        description="{{ 'account.accounting_emails_checker_disabled_info' | translate }}"
                        [forInput]="true"
                        tooltipTitle="{{ 'misc.accounting_emails' | translate }}"
                    ></app-custom-tool-tip>
                </div>
            </div>

            <div class="input-group-wrapper">
                <div class="checkbox">
                    <input type="checkbox" id="action_email_check" formControlName="action_email_check" name="action_email_check" />
                    <label for="action_email_check">{{ "misc.action_notif_emails" | translate }}</label>
                </div>
            </div>

            <div class="horizonatal-line"></div>

            <div class="input-group-wrapper" *ngIf="businessType !== 'individual'">
                <div class="input-group-wrapper mb-0 d-flex justify-content-between">
                    <div style="width: 45%">
                        <label for="vat_nunmber_type">{{ "account.vat_number_type" | translate }}</label>
                        <select class="form-select" id="vat_nunmber_type" formControlName="vat_number_type">
                            <option selected disabled value="null">{{ "account.vat_number_type" | translate }}</option>
                            <option [ngValue]="type" *ngFor="let type of TAX_ID_TYPES">{{ countryFromCode(type) }} - {{ formatVATTypeId(type.tax_id, type.country) }}</option>
                        </select>
                        <div class="input-description" *ngIf="vat_number_type.value">
                            {{ ("misc.example" | translate) + ": " + vat_number_type.value.example }}
                        </div>
                    </div>

                    <div style="width: 54%">
                        <app-custom-tool-tip forInput="true" label="{{ 'account.vat_number' | translate }}" description="{{ 'account.vat_number_description' | translate }}"></app-custom-tool-tip>
                        <input autocomplete="new-vat_number" name="new-vat_number" class="form-control" type="text" formControlName="vat_number" placeholder="-" />

                        <div
                            [className]="
                                !isEuCountry(vatCountry)
                                    ? 'input-description verified'
                                    : details?.vat_number_details?.verification?.status === 'verified'
                                      ? 'input-description verified'
                                      : details?.vat_number_details?.verification?.status === 'pending'
                                        ? 'input-description pending'
                                        : 'input-description unverified'
                            "
                            *ngIf="
                                !vat_number_not_provided.value &&
                                (details?.vat_number_details?.verification?.status === 'pending' || details?.vat_number_details?.verification?.status === 'failed' || details?.vat_number_details?.verification?.status === 'verified')
                            "
                        >
                            @if (isEuCountry(vatCountry)) {
                                {{
                                    details?.vat_number_details?.verification?.status === "pending"
                                        ? ("account.vat_number_state.pending" | translate)
                                        : details?.vat_number_details?.verification?.status === "failed"
                                          ? ("account.vat_number_state.failed" | translate)
                                          : details?.vat_number_details?.verification?.status === "verified"
                                            ? ("account.vat_number_state.verified" | translate)
                                            : ""
                                }}
                            } @else {
                                {{ "account.vat_number_state.verified" | translate }}
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="formSubmitted && (vat_number_type.invalid || vat_number.invalid) && businessType !== 'individual'">
                <div class="error-msg" *ngIf="(vat_number_type.errors && vat_number_type.errors.required) || (vat_number.errors && vat_number.errors.required)">
                    {{ "account.vat_type_and_number_required" | translate }}
                </div>
            </div>

            <div class="input-group-wrapper mb-4" *ngIf="businessType !== 'individual'">
                <div class="checkbox">
                    <input type="checkbox" id="vat_number_not_provided" formControlName="vat_number_not_provided" name="vat_number_not_provided" />
                    <label for="vat_number_not_provided">{{ (businessType === "company" ? "account.vat_number_not_provided_company" : "account.vat_number_not_provided") | translate }}</label>
                </div>
            </div>

            <div *ngIf="isOperator$ | async">
                <div class="input-group-wrapper">
                    <app-custom-tool-tip forInput="true" label="{{ 'account.support_url' | translate }}" description="{{ 'account.support_url_description' | translate }}"></app-custom-tool-tip>
                    <input autocomplete="new-support_url" name="new-support_url" maxlength="255" class="form-control" type="text" formControlName="support_url" placeholder="-" />
                    <div *ngIf="formSubmitted && support_url.invalid">
                        <div class="error-msg" *ngIf="support_url.errors.formatIsWrong">
                            {{ "account.url_invalid" | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div *ngIf="businessType !== 'individual' || (isOperator$ | async)" class="horizonatal-line"></div>

                <p class="large-label">{{ "account.bank_info" | translate }}</p>
                <app-external-account-list [customerDetails]="details"></app-external-account-list>

                <div class="horizonatal-line"></div>
            </div>
        </form>

        <!--    Team member form-->
        <form [formGroup]="roleSettingsForm" *ngIf="!isOwner">
            <div class="horizonatal-line"></div>

            <div class="input-group-wrapper-feedback">
                <label for="first_name">First name</label>
                <input autocomplete="new-first_name" id="first_name" name="new-first_name" maxlength="255" class="form-control" type="text" formControlName="first_name" />
                <div class="feedback-container">
                    @if (formSubmitted && first_name.invalid) {
                        <div class="error-msg" *ngIf="first_name.errors.required">
                            {{ "account.first_name_required" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="first_name.errors.formatIsWrong">
                            {{ "account.url_invalid" | translate }}
                        </div>
                    }
                </div>

                <div class="input-group-wrapper-feedback">
                    <label for="last_name">Last name</label>
                    <input autocomplete="new-last_name" id="last_name" name="new-last_name" maxlength="255" class="form-control" type="text" formControlName="last_name" />

                    <div class="feedback-container">
                        @if (formSubmitted && last_name.invalid) {
                            <div class="error-msg" *ngIf="last_name.errors.required">
                                {{ "account.last_name_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="last_name.errors.formatIsWrong">
                                {{ "account.url_invalid" | translate }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="horizonatal-line"></div>

            <div class="d-flex justify-content-between">
                <p class="large-label">{{ "account.email_settings" | translate }}</p>
                <app-custom-tool-tip description="Email description here" [forInput]="true" tooltipTitle="{{ 'account.email_settings' | translate }}"></app-custom-tool-tip>
            </div>

            <div class="input-group-wrapper">
                <div class="checkbox">
                    <input type="checkbox" id="action_email" formControlName="action_email" name="action_email" />
                    <label for="action_email">{{ "misc.action_notif_emails" | translate }}</label>
                </div>
            </div>

            <div class="horizonatal-line"></div>
        </form>

        <div class="d-flex flex-column mt-4">
            <div class="d-grid gap-2 my-3">
                <button (click)="updateAccountSettings(accountForm)" class="btn btn-primary" type="submit" [disabled]="showSmallLoadingIndicator">
                    {{ showSmallLoadingIndicator ? "" : ("account.save_account" | translate) }}
                    <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'large'" [color]="'white'"></loading-indicator>
                </button>
            </div>
        </div>
    </div>

    <div class="box" *ngIf="!formRdy">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>

    <div class="box mt-4 p-4" *ngIf="deletingAccount">
        <h4>{{ "account.deleting_account" | translate }}</h4>
        <h6>{{ "account.logging_out" | translate }}</h6>
    </div>

    <ng-template #emailDialog let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "account.change_email" | translate }}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
            </ng-container>
            <ng-container modal-body>
                <p>{{ "account.continue" | translate }}</p>
                <div [formGroup]="emailForm">
                    <label class="titleInputTransaction" id="MailLabel">{{ "account.email_addr" | translate }}</label>
                    <input formControlName="email" class="form-control" type="email" name="email" id="MailInput" />
                    <div *ngIf="email.invalid">
                        <div class="error-msg" *ngIf="email.errors.required">Email is required</div>
                    </div>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="mt-4 d-grid">
                    <button class="btn btn-secondary" tabindex="-1" (click)="updateEmail(emailForm)" [disabled]="showEmailLoadingIndicator">
                        {{ showEmailLoadingIndicator ? "" : ("account.change_email" | translate) }}
                        <loading-indicator *ngIf="showEmailLoadingIndicator" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <ng-template #pass let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "account.change_password" | translate }}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
            </ng-container>
            <ng-container modal-body>
                <p>{{ "account.to_change_password" | translate }}</p>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid gap-2">
                    <button class="btn btn-secondary" tabindex="-1" (click)="resetPassword()">
                        {{ "account.change_password" | translate }}
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <ng-template #reAuth let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "account.confirm_account" | translate }}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
            </ng-container>
            <ng-container modal-body>
                <p>{{ "account.to_change_email" | translate }}</p>
                <div [formGroup]="reAuthForm" class="mb-3">
                    <label class="titleInputTransaction">{{ "misc.email" | translate }}</label>
                    <input formControlName="reAuthEmail" class="form-control" type="email" name="reAuthEmail" />
                    <div *ngIf="reAuthFormSubmitted && reAuthEmail.invalid">
                        <div class="error-msg" *ngIf="reAuthEmail.errors.required">
                            {{ "misc.email_required" | translate }}
                        </div>
                    </div>
                    <label class="titleInputTransaction">Password</label>
                    <input formControlName="reAuthPassword" class="form-control" type="password" name="password" />
                    <div *ngIf="reAuthFormSubmitted && reAuthPassword.invalid">
                        <div class="error-msg" *ngIf="reAuthPassword.errors.required">
                            {{ "account.password_required" | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid gap-2">
                    <button class="btn btn-secondary" tabindex="-1" [disabled]="reAuthFormValidated" (click)="confirmReAuth(reAuthForm)">
                        {{ "sign-in.confirm" | translate }}
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <ng-template #builtWith let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "account.third_party" | translate }}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
            </ng-container>
            <ng-container modal-body>
                <table class="table table-striped licenses-table">
                    <thead>
                        <tr>
                            <td class="licenses-title">{{ "account.package" | translate }}</td>
                            <td class="licenses-title">{{ "account.license" | translate }}</td>
                            <td class="licenses-title">{{ "account.repository" | translate }}</td>
                            <td class="licenses-title">{{ "account.publisher" | translate }}</td>
                        </tr>
                    </thead>
                    <tbody class="tablebody-reduce-font-size">
                        <tr *ngFor="let key of licenses">
                            <td class="">{{ key }}</td>
                            <td class="">{{ resultFile[key].licenses }}</td>
                            <td class="">{{ resultFile[key].repository }}</td>
                            <td class="">{{ resultFile[key].publisher }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <ng-container modal-footer>
                <div class="no-footer"></div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <!-- <div *ngIf="formRdy && !deletingAccount" class="version-license-wrapper"> -->
    <div class="version-license-wrapper" *ngIf="formRdy">
        <div>Version: {{ version }}</div>
        <div class="license-link" (click)="licensesModal(builtWith)">{{ "account.third_party" | translate }}</div>
    </div>
</div>

<ng-template #pickLanguage let-modal>
    <app-language-picker-modal></app-language-picker-modal>
</ng-template>
