import { TaxIdObject } from 'shared_models/details';

export const TaxIdTypes: TaxIdObject[] = [
    // {
    //     country: "AD",
    //     tax_id: "ad_nrt",
    //     description: "Andorran NRT number",
    //     example: "A-123456-Z",
    // },
    {
        country: 'AR',
        tax_id: 'ar_cuit',
        description: 'Argentinian tax ID number',
        example: '12-3456789-01'
    },
    {
        country: 'AU',
        tax_id: 'au_abn',
        description: 'Australian Business Number (AU ABN)',
        example: '12345678912'
    },
    {
        country: 'AU',
        tax_id: 'au_arn',
        description: 'Australian Taxation Office Reference Number',
        example: '123456789123'
    },
    {
        country: 'AT',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'ATU12345678'
    },
    {
        country: 'BE',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'BE0123456789'
    },
    // {
    //     country: "BO",
    //     tax_id: "bo_tin",
    //     description: "Bolivian tax ID",
    //     example: "123456789",
    // },
    // {
    //     country: "BR",
    //     tax_id: "br_cnpj",
    //     description: "Brazilian CNPJ number",
    //     example: "01.234.456/5432-10",
    // },
    // {
    //     country: "BR",
    //     tax_id: "br_cpf",
    //     description: "Brazilian CPF number",
    //     example: "123.456.789-87",
    // },
    // {
    //     country: "BG",
    //     tax_id: "bg_uic",                                            // Stripe does not currently support Bulgarian UIC number validation
    //     description: "Bulgaria Unified Identification Code",
    //     example: "123456789",
    // },
    {
        country: 'BG',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'BG0123456789'
    },
    {
        country: 'CA',
        tax_id: 'ca_bn',
        description: 'Canadian BN',
        example: '123456789'
    },
    {
        country: 'CA',
        tax_id: 'ca_gst_hst',
        description: 'Canadian GST/HST number',
        example: '123456789RT0002'
    },
    {
        country: 'CA',
        tax_id: 'ca_pst_bc',
        description: 'Canadian PST number (British Columbia)',
        example: 'PST-1234-5678'
    },
    {
        country: 'CA',
        tax_id: 'ca_pst_mb',
        description: 'Canadian PST number (Manitoba)',
        example: '123456-7'
    },
    {
        country: 'CA',
        tax_id: 'ca_pst_sk',
        description: 'Canadian PST number (Saskatchewan)',
        example: '1234567'
    },
    {
        country: 'CA',
        tax_id: 'ca_qst',
        description: 'Canadian QST number (Québec)',
        example: '1234567890TQ1234'
    },
    {
        country: 'CL',
        tax_id: 'cl_tin',
        description: 'Chilean TIN',
        example: '12.345.678-K'
    },
    // {
    //     country: "CN",
    //     tax_id: "cn_tin",
    //     description: "Chinese tax ID",
    //     example: "123456789012345678",
    // },
    {
        country: 'CO',
        tax_id: 'co_nit',
        description: 'Colombian NIT number',
        example: '123.456.789-0'
    },
    {
        country: 'CR',
        tax_id: 'cr_tin',
        description: 'Costa Rican tax ID',
        example: '1-234-567890'
    },
    {
        country: 'HR',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'HR12345678912'
    },
    {
        country: 'CY',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'CY12345678Z'
    },
    {
        country: 'CZ',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'CZ1234567890'
    },
    {
        country: 'DK',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'DK12345678'
    },
    {
        country: 'DO',
        tax_id: 'do_rcn',
        description: 'Dominican RCN number',
        example: '123-4567890-1'
    },
    // {
    //     country: "EC",
    //     tax_id: "ec_ruc",
    //     description: "Ecuadorian RUC number",
    //     example: "1234567890001",
    // },
    {
        country: 'EG',
        tax_id: 'eg_tin',
        description: 'Egyptian Tax Identification Number',
        example: '123456789'
    },
    {
        country: 'SV',
        tax_id: 'sv_nit',
        description: 'El Salvadorian NIT number',
        example: '1234-567890-123-4'
    },
    {
        country: 'EE',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'EE123456789'
    },
    {
        country: 'EU',
        tax_id: 'eu_oss_vat',
        description: 'European One Stop Shop VAT number for non-Union scheme',
        example: 'EU123456789'
    },
    {
        country: 'FI',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'FI12345678'
    },
    {
        country: 'FR',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'FRAB123456789'
    },
    // {
    //     country: "GE",
    //     tax_id: "ge_vat",
    //     description: "Georgian VAT",
    //     example: "123456789",
    // },
    {
        country: 'DE',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'DE123456789'
    },
    {
        country: 'GR',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'EL123456789'
    },
    {
        country: 'HK',
        tax_id: 'hk_br',
        description: 'Hong Kong BR number',
        example: '12345678'
    },
    {
        country: 'HU',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'HU12345678'
    },
    // {
    //     country: "HU",
    //     tax_id: "hu_tin",                                // Stripe does not currently support Hungarian TIN number validation
    //     description: "Hungary tax number (adószám)",
    //     example: "12345678-1-23",
    // },
    {
        country: 'IS',
        tax_id: 'is_vat',
        description: 'Icelandic VAT',
        example: '123456'
    },
    {
        country: 'IN',
        tax_id: 'in_gst',
        description: 'Indian GST number',
        example: '12ABCDE3456FGZH'
    },
    {
        country: 'ID',
        tax_id: 'id_npwp',
        description: 'Indonesian NPWP number',
        example: '12.345.678.9-012.345'
    },
    {
        country: 'IE',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'IE1234567AB'
    },
    {
        country: 'IL',
        tax_id: 'il_vat',
        description: 'Israel VAT',
        example: '000012345'
    },
    {
        country: 'IT',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'IT12345678912'
    },
    {
        country: 'JP',
        tax_id: 'jp_cn',
        description: 'Japanese Corporate Number (Hōjin Bangō)',
        example: '1234567891234'
    },
    {
        country: 'JP',
        tax_id: 'jp_rn',
        description: "Japanese Registered Foreign Businesses' Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)",
        example: '12345'
    },
    {
        country: 'JP',
        tax_id: 'jp_trn',
        description: 'Japanese Tax Registration Number (Tōroku Bangō)',
        example: 'T1234567891234'
    },
    {
        country: 'KE',
        tax_id: 'ke_pin',
        description: 'Kenya Revenue Authority Personal Identification Number',
        example: 'P000111111A'
    },
    {
        country: 'LV',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'LV12345678912'
    },
    {
        country: 'LI',
        tax_id: 'li_uid',
        description: 'Liechtensteinian UID number',
        example: 'CHE123456789'
    },
    {
        country: 'LT',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'LT123456789123'
    },
    {
        country: 'LU',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'LU12345678'
    },
    {
        country: 'MY',
        tax_id: 'my_frp',
        description: 'Malaysian FRP number',
        example: '12345678'
    },
    {
        country: 'MY',
        tax_id: 'my_itn',
        description: 'Malaysian ITN',
        example: 'C 1234567890'
    },
    {
        country: 'MY',
        tax_id: 'my_sst',
        description: 'Malaysian SST number',
        example: 'A12-3456-78912345'
    },
    {
        country: 'MT',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'MT12345678'
    },
    {
        country: 'MX',
        tax_id: 'mx_rfc',
        description: 'Mexican RFC number',
        example: 'ABC010203AB9'
    },
    {
        country: 'NL',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'NL123456789B12'
    },
    {
        country: 'NZ',
        tax_id: 'nz_gst',
        description: 'New Zealand GST number',
        example: '123456789'
    },
    {
        country: 'NO',
        tax_id: 'no_vat',
        description: 'Norwegian VAT number',
        example: '123456789'
        // example: "123456789MVA", // original example by Stripe docs, but we currently have it disabled because Stripe does not currently support Norwegian VAT number validation
    },
    {
        country: 'PE',
        tax_id: 'pe_ruc',
        description: 'Peruvian RUC number',
        example: '12345678901'
    },
    {
        country: 'PH',
        tax_id: 'ph_tin',
        description: 'Philippines Tax Identification Number',
        example: '123456789012'
    },
    {
        country: 'PL',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'PL1234567890'
    },
    {
        country: 'PT',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'PT123456789'
    },
    {
        country: 'RO',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'RO1234567891'
    },
    // {
    //     country: "RO",
    //     tax_id: "ro_tin",                            // Stripe does not currently support Romanian TIN number validation
    //     description: "Romanian tax ID number",
    //     example: "1234567890123",
    // },
    // {
    //     country: "RU",
    //     tax_id: "ru_inn",
    //     description: "Russian INN",
    //     example: "1234567891",
    // },
    // {
    //     country: "RU",
    //     tax_id: "ru_kpp",
    //     description: "Russian KPP",
    //     example: "123456789",
    // },
    {
        country: 'SA',
        tax_id: 'sa_vat',
        description: 'Saudi Arabia VAT',
        example: '123456789012345'
    },
    // {
    //     country: "RS",
    //     tax_id: "rs_pib",
    //     description: "Serbian PIB number",
    //     example: "123456789",
    // },
    {
        country: 'SG',
        tax_id: 'sg_gst',
        description: 'Singaporean GST',
        example: 'M12345678X'
    },
    {
        country: 'SG',
        tax_id: 'sg_uen',
        description: 'Singaporean UEN',
        example: '123456789F'
    },
    {
        country: 'SK',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'SK1234567891'
    },
    {
        country: 'SI',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'SI12345678'
    },
    // {
    //     country: "SI",
    //     tax_id: "si_tin",                                        // Stripe does not currently support Slovenian TIN number validation
    //     description: "Slovenia tax number (davčna številka)",
    //     example: "12345678",
    // },
    {
        country: 'ZA',
        tax_id: 'za_vat',
        description: 'South African VAT number',
        example: '4123456789'
    },
    {
        country: 'KR',
        tax_id: 'kr_brn',
        description: 'Korean BRN',
        example: '123-45-67890'
    },
    // {
    //     country: "ES",
    //     tax_id: "es_cif",                                                        // Stripe does not currently support Spanish CIF number validation
    //     description: "Spanish NIF number (previously Spanish CIF number)",
    //     example: "A12345678",
    // },
    {
        country: 'ES',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'ESA1234567Z'
    },
    {
        country: 'SE',
        tax_id: 'eu_vat',
        description: 'European VAT number',
        example: 'SE123456789123'
    },
    {
        country: 'CH',
        tax_id: 'ch_vat',
        description: 'Switzerland VAT number',
        example: 'CHE-123.456.789 MWST'
    },
    {
        country: 'TW',
        tax_id: 'tw_vat',
        description: 'Taiwanese VAT',
        example: '12345678'
    },
    {
        country: 'TH',
        tax_id: 'th_vat',
        description: 'Thai VAT',
        example: '1234567891234'
    },
    {
        country: 'TR',
        tax_id: 'tr_tin',
        description: 'Turkish Tax Identification Number',
        example: '0123456789'
    },
    // {
    //     country: "UA",
    //     tax_id: "ua_vat",
    //     description: "Ukrainian VAT",
    //     example: "123456789",
    // },
    {
        country: 'AE',
        tax_id: 'ae_trn',
        description: 'United Arab Emirates TRN',
        example: '123456789012345'
    },
    {
        country: 'XI',
        tax_id: 'eu_vat',
        description: 'Northern Ireland VAT number',
        example: 'XI123456789'
    },
    {
        country: 'GB',
        tax_id: 'gb_vat',
        description: 'United Kingdom VAT number',
        example: 'GB123456789'
    },
    {
        country: 'US',
        tax_id: 'us_ein',
        description: 'United States EIN',
        example: '12-3456789'
    },
    {
        country: 'UY',
        tax_id: 'uy_ruc',
        description: 'Uruguayan RUC number',
        example: '123456789012'
    },
    // {
    //     country: "VE",
    //     tax_id: "ve_rif",
    //     description: "Venezuelan RIF number",
    //     example: "A-12345678-9",
    // },
    {
        country: 'VN',
        tax_id: 'vn_tin',
        description: 'Vietnamese tax ID number',
        example: '1234567890'
    },
    {
        country: 'UZ',
        tax_id: 'uz_tin',
        description: 'Uzbekistan TIN Number',
        example: '123456789'
    },
    {
        country: 'UZ',
        tax_id: 'uz_vat',
        description: 'Uzbekistan VAT Number',
        example: '123456789012'
    }
];
