<section class="table-flex-box" style="margin: 24px 0" *ngIf="!initialLoading">
    <div>
        <div class="datastream-container" *ngIf="!loadingData">
            @if (!fetchingData) {
                <p class="pause-button" (click)="pauseOrPlayDataStream()">
                    <u>{{ this.isPaused ? "Play datastream" : "Pause datastream" }}</u>
                </p>
                <p class="counter">
                    @if (isPaused) {
                        <span>Datastream paused</span>
                    } @else {
                        <span>Updating data in: {{ nextFetchCountdown }} seconds</span>
                    }
                </p>
            } @else {
                <div style="height: 100%; display: flex; align-items: center; justify-content: center">
                    <loading-indicator [size]="'small'" [color]="'secondary'" />
                </div>
            }
        </div>
        <app-anton-health-table-settings [allTableHeaders]="allTableHeaderOptions" (submitEmitter)="catchTableSettings()" />
    </div>
</section>
<section style="margin: 24px 0">
    <div class="container" style="max-width: 100%">
        <div *ngIf="initialLoading" class="loading-height">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>

        <app-aw-table
            [tableHeaders]="tableHeaderOptions"
            [sidescrollable]="true"
            (handlePageChange)="handlePage($event)"
            (sortBy)="catchSortChanged($event)"
            [initalSort]="this.params.sortBy"
            [loadingData]="loadingData"
            [pageSizeOptions]="[20, 100, 500]"
            [hasExpandableOrClickableRows]="true"
            [totalItems]="antonHealthData.length"
            *ngIf="!initialLoading"
            [pageNumber]="pageNumber"
        >
            <ng-container table-body *ngIf="!loadingData">
                <div *ngFor="let data of antonHealthData | paginate: pageSize : pageNumber; let i = index" class="row-container">
                    <!--Row collapsed-->
                    <row *ngIf="!expandedList.includes(data.serial)">
                        <cell [ngStyle]="{ width: allTableHeaderOptions[0]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[0].sortKey)">
                            <div style="width: 100%">
                                <span [className]="'dot ' + (data.status === 'active' ? 'green' : 'red')">
                                    <div class="hover">
                                        <p>
                                            <strong>{{ "operator.anton_health.connection_status" | translate }}</strong>
                                        </p>
                                        <p>
                                            {{ data.status === "active" ? ("operator.anton_health.green" | translate) : ("operator.anton_health.red" | translate) }}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[1]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[1].sortKey)">
                            <div style="width: 100%">
                                <span title="{{ data.last_check_in === 0 ? ('operator.anton_health.never' | translate) : dayjs.unix(data.last_check_in).fromNow(true) }}">{{
                                    data.last_check_in === 0 ? ("operator.anton_health.never" | translate) : dayjs.unix(data.last_check_in).fromNow(true)
                                }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[2]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[2].sortKey)">
                            <div style="width: 100%">
                                <span title="{{ data.session_start !== 0 ? getLocaleDate(data.session_start) : ('operator.anton_health.never' | translate) }}">
                                    {{ data.session_start !== 0 ? getLocaleDate(data.session_start) : ("operator.anton_health.never" | translate) }}
                                </span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[3]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[3].sortKey)">
                            <span [title]="data.location_name">{{ data.location_name }}</span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[4]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[4].sortKey)">
                            <span [title]="data.custom_location_id ?? '-'">{{ data.custom_location_id ?? "-" }}</span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[5]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[5].sortKey)">
                            <span [title]="data.customer_uid !== this.user.uid ? data.customer_name : ('transactions.my_account' | translate)">
                                {{ data.customer_uid !== this.user.uid ? data.customer_name : ("transactions.my_account" | translate) }}
                            </span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[6]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[6].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                            <div style="width: 100%; display: flex; justify-content: center">
                                <span [className]="'dot ' + (data.charging_state ? 'green' : 'red')"></span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[7]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[7].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                            <div style="width: 100%">
                                <span [title]="(data.tablet_battery_level * 100).toFixed(0) + '%'">{{ (data.tablet_battery_level * 100).toFixed(0) + "%" }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[8]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[8].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                            <div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                <span [title]="(data.terminal_battery_level * 100).toFixed(0) + '%'">{{ (data.terminal_battery_level * 100).toFixed(0) + "%" }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[9]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[9].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                            <div style="width: 100%; display: flex; justify-content: center">
                                <span [className]="'dot ' + (data.terminal_connection_status ? 'green' : 'red')"></span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[10]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[10].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                            <span *ngIf="data.mqtt_status && data.communication.includes('MQTT')" [className]="'dot ' + (data.mqtt_status === 'connected' ? 'green' : 'red')"></span>&nbsp;&nbsp;&nbsp;
                            <span *ngIf="data.ble_status && data.communication.includes('BLE')" [className]="'dot ' + (data.ble_status === 'on' ? 'green' : 'red')"> </span>&nbsp;&nbsp;&nbsp;
                            <span>{{ data.communication }}</span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[11]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[11].sortKey)">
                            <span
                                >{{ "operator.anton_health." + data.network | translate }}
                                <div class="hover" *ngIf="data.wifi_ssid">
                                    <p>
                                        <strong>{{ "operator.anton_health.wifi_ssid" | translate }}</strong>
                                    </p>
                                    <p>{{ data.wifi_ssid }}</p>
                                </div>
                            </span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[12]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[12].sortKey)">
                            <span [ngClass]="{ highlight: data.serial === serialToHighlight }">{{ data.serial }}</span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[13]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[13].sortKey)">
                            <div style="width: 100%">
                                <span>{{ data.app_version }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[14]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[14].sortKey)">
                            <div style="width: 100%">
                                <span>{{ data.os_version }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[15]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[15].sortKey)">
                            <div style="width: 100%; display: flex; justify-content: center">
                                <span [className]="'dot ' + (data.kiosk_status === 'A_ENABLED' ? 'green' : 'red')" *ngIf="data.kiosk_status"></span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[16]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[16].sortKey)">
                            <div style="width: 100%; display: flex; justify-content: center">
                                <!-- We hide reboot capabilities !data.knox_license_status else we show and the status is depending on what the value then is. This is to avoid users getting a red dot when terminals haven't been online yet which not is a direct issue on this column -->
                                <span *ngIf="data.knox_license_status" [className]="'dot ' + (data.knox_license_status === 'ACTIVATED' ? 'green' : 'red')"></span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[17]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[17].sortKey)">
                            <div style="width: 100%">
                                <span>{{ data.incident_count }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[18]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[18].sortKey)">
                            <div style="width: 100%">
                                <span>{{
                                    data.incidents.length > 0
                                        ? data.incidents[0].resolved_timestamp
                                            ? dateTimeService.getDateAndTime(data.incidents[0].resolved_timestamp)
                                            : ("operator.anton_health.not_resolved" | translate)
                                        : ("operator.anton_health.no_incidents" | translate)
                                }}</span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[19]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[19].sortKey)">
                            <span>{{ data.incidents.length > 0 ? (data.incidents[0].duration / 60).toFixed(0) + " min" : "-" }}</span>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[20]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[20].sortKey)">
                            <div style="width: 100%; cursor: pointer" *ngIf="data.incidents.length > 0" (click)="openModal(editIncidentModal, data.incidents[0], data.serial)">
                                <span>
                                    <u>
                                        {{ data.incidents.length > 0 && data.incidents[0].note_count > 0 ? "(" + (data.incidents[0].note_count ?? 0) + ") " : "" }}
                                        {{ "operator.anton_health.add_note" | translate }}
                                    </u>
                                </span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[21]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[21].sortKey)">
                            <div style="width: 100%; display: flex; justify-content: center">
                                <span *ngIf="data.incidents.length > 0" [className]="'dot ' + (data.incidents[0].solved ? 'green' : 'red')"></span>
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[22]?.width }" [ngClass]="{ 'not-updated-data': !data.uid }">
                            <div style="width: 100%" [ngStyle]="{ cursor: data.uid ? 'pointer' : '' }" (click)="reboot(data)" *ngIf="!rebooting.includes(data.serial)">
                                <span>
                                    <u>
                                        {{ "operator.anton_health.reboot" | translate }}
                                    </u>
                                </span>
                            </div>
                            <div style="width: 67%" *ngIf="rebooting.includes(data.serial)">
                                <loading-indicator [size]="'small'" [color]="'secondary'" />
                            </div>
                        </cell>
                        <cell [ngStyle]="{ width: allTableHeaderOptions[23]?.width }" [style]="data.incidents.length > 0 ? 'cursor: pointer' : ''">
                            <div style="width: 100%" (click)="data.incidents.length > 1 ? expandRow(data.serial) : null" [className]="data.incidents.length > 1 ? '' : 'disabled'">
                                <img class="arrow" src="../../../assets/icons/arrow-down-blue.svg" alt="sort-arrow" />
                            </div>
                        </cell>
                    </row>

                    <!--Row expanded-->
                    <div *ngIf="expandedList.includes(data.serial) && data.incidents.length > 1" class="expanded-row">
                        <row *ngFor="let incident of data.incidents; let i = index">
                            <cell [ngStyle]="{ width: allTableHeaderOptions[0]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[0].sortKey)">
                                <div style="width: 100%">
                                    <span *ngIf="i == 0" [className]="'dot ' + (data.status === 'active' ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[1]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[1].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? (data.last_check_in == 0 ? ("operator.anton_health.never" | translate) : dayjs.unix(data.last_check_in).fromNow(true)) : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[2]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[2].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? (data.session_start == 0 ? ("operator.anton_health.never" | translate) : dayjs.unix(data.session_start).fromNow(true)) : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[3]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[3].sortKey)">
                                <span>{{ i == 0 ? data.location_name : "" }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[4]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[4].sortKey)">
                                <span>{{ i == 0 ? (data.custom_location_id ?? "-") : "" }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[5]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[5].sortKey)">
                                <span>{{ i == 0 ? (data.customer_uid !== this.user.uid ? data.customer_name : ("transactions.my_account" | translate)) : "" }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[6]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[6].sortKey)">
                                <div style="width: 100%; display: flex; justify-content: center">
                                    <span *ngIf="i == 0" [className]="'dot ' + (data.charging_state ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[7]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[7].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? (data.tablet_battery_level * 100).toFixed(0) + "%" : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[8]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[8].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? (data.terminal_battery_level * 100).toFixed(0) + "%" : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[9]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[9].sortKey)">
                                <div style="width: 100%; display: flex; justify-content: center">
                                    <span *ngIf="i == 0" [className]="'dot ' + (data.terminal_connection_status ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[10]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[10].sortKey)" [ngClass]="{ 'not-updated-data': data.status !== 'active' }">
                                <span *ngIf="data.mqtt_status && i == 0" [className]="'dot ' + (data.mqtt_status === 'connected' ? 'green' : 'red')"></span>&nbsp;&nbsp;
                                <span *ngIf="data.ble_status && i == 0" [className]="'dot ' + (data.ble_status === 'on' ? 'green' : 'red')"> </span>
                                <span *ngIf="i == 0">{{ data.communication }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[11]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[11].sortKey)">
                                <span *ngIf="i == 0">{{ "operator.anton_health." + data.network | translate }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[12]?.width, 'text-align': 'left' }" *ngIf="tableHasKey(allTableHeaderOptions[12].sortKey)">
                                <span [ngClass]="{ highlight: data.serial === serialToHighlight }">{{ i == 0 ? data.serial : "" }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[13]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[13].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? data.app_version : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[14]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[14].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? data.os_version : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[15]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[15].sortKey)">
                                <div style="width: 100%; display: flex; justify-content: center">
                                    <span *ngIf="i == 0 && data.kiosk_status" [className]="'dot ' + (data.kiosk_status === 'ENABLED' ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[16]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[16].sortKey)">
                                <div style="width: 100%; display: flex; justify-content: center">
                                    <span *ngIf="i == 0" [className]="'dot ' + (data.knox_license_status === 'ACTIVATED' ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[17]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[17].sortKey)">
                                <div style="width: 100%">
                                    <span>{{ i == 0 ? data.incident_count : "" }}</span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[18]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[18].sortKey)">
                                <div style="width: 100%">
                                    <span>
                                        {{
                                            data.incidents.length > 0
                                                ? incident.resolved_timestamp
                                                    ? dateTimeService.getDateAndTime(incident.resolved_timestamp, false, false)
                                                    : ("operator.anton_health.not_resolved" | translate)
                                                : ("operator.anton_health.no_incidents" | translate)
                                        }}
                                    </span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[19]?.width }" *ngIf="tableHasKey(allTableHeaderOptions[19].sortKey)">
                                <span>{{ data.incidents.length > 0 ? (incident.duration / 60).toFixed(0) + " min" : "-" }}</span>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[20]?.width, 'text-align': 'right' }" *ngIf="tableHasKey(allTableHeaderOptions[20].sortKey)">
                                <div style="width: 100%; cursor: pointer" (click)="openModal(editIncidentModal, incident, data.serial)">
                                    <span>
                                        <u>
                                            {{ incident.note_count > 0 ? "(" + (incident.note_count ?? 0) + ") " : "" }}
                                            {{ "operator.anton_health.add_note" | translate }}</u
                                        >
                                    </span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[21]?.width, 'text-align': 'center' }" *ngIf="tableHasKey(allTableHeaderOptions[21].sortKey)">
                                <div style="width: 100%; display: flex; justify-content: center">
                                    <span [className]="'dot ' + (incident.solved ? 'green' : 'red')"></span>
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[22]?.width }" [ngClass]="{ 'not-updated-data': !data.uid }">
                                <div style="width: 100%" [ngStyle]="{ cursor: data.uid ? 'pointer' : '' }" (click)="reboot(data)" *ngIf="!rebooting.includes(data.serial) && i == 0">
                                    <span>
                                        <u>
                                            {{ "operator.anton_health.reboot" | translate }}
                                        </u>
                                    </span>
                                </div>
                                <div style="width: 67%" *ngIf="rebooting.includes(data.serial) && i == 0">
                                    <loading-indicator [size]="'small'" [color]="'secondary'" />
                                </div>
                            </cell>
                            <cell [ngStyle]="{ width: allTableHeaderOptions[23]?.width, cursor: 'pointer' }">
                                <div style="width: 100%" (click)="expandRow(data.serial)">
                                    <img *ngIf="data.incidents.length > 1 && i == 0" class="arrow" src="../../../assets/icons/arrow-up-blue.svg" alt="sort-arrow" />
                                </div>
                            </cell>
                        </row>
                    </div>
                </div>
            </ng-container>
        </app-aw-table>
    </div>
</section>

<ng-template #editIncidentModal>
    <app-incident-comment-modal (emitNewIncident)="catchIncidentChanged($event)" [incidentToEdit]="incidentToBeEdited" [serial]="serialToBeEdited"></app-incident-comment-modal>
</ng-template>
