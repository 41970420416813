<app-page-layout [pageTitle]="'navbar.billing' | translate" [tabOptions]="['billing.active', 'billing.inactive']" (tabChanged)="catchTabChanged($event)">
    <ng-container buttons>
        <div>
            <app-aw-filter-button *ngIf="this.view === 'billing.active'" [filterOptions]="filterOptionsActive" (filterChanged)="catchFilterChanged($event)" />
            <app-aw-filter-button
                *ngIf="this.view === 'billing.inactive'"
                popupAnchor="left"
                [filterOptions]="filterOptionsInactive"
                (filterChanged)="catchFilterChanged($event)"
            />
        </div>
        <button
            *ngIf="view === 'billing.inactive'"
            [class.btn-disabled]="selectedDevices.length < 1"
            class="btn btn-primary btn-tiny"
            [style]="isMobile ? '' : 'margin-left: 15px'"
            (click)="selectedDevices.length > 0 ? openBillingSubscriptionModal(billingSubscriptionModal) : noDeviceSelectedToast()"
        >
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
            {{ "billing.subscribe" | translate }}
        </button>
    </ng-container>

    <ng-container body>
        <!-- <div class="billing-card-wrapper" *ngIf="initLoading">
            <div>
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
        </div> -->

        @defer (when !initLoading) {
            <div class="billing-card-wrapper" *ngIf="details.onboarding_state.capabilities !== 'active' && !noShare">
                <h5>
                    {{ "billing.before_setup" | translate }} <a role="button" class="primary-color" [routerLink]="['/overview']">{{ "billing.overview" | translate }}</a
                    >.
                </h5>
            </div>

            <!--            <div class="fetching-data-overlay" *ngIf="!initLoading"></div>-->

            <div *ngIf="details.onboarding_state.capabilities === 'active' || noShare">
                <!--Table displaying devices with subscription (active)-->
                <app-aw-table
                    [tableHeaders]="tableHeaderOptions"
                    [emptytext]="'billing.no_active_devices' | translate"
                    [pageNumber]="pageNumberActive"
                    [pageSize]="pageSizeActive"
                    [totalItems]="totalItemsActive"
                    (handlePageChange)="handlePageActive($event)"
                    (sortBy)="catchSortChanged($event)"
                    [loadingData]="loadingActiveBilling"
                    [sidescrollable]="isMobile"
                    [initalSort]="this.paramsForActive.sortBy"
                    *ngIf="view === 'billing.active'"
                >
                    <ng-container table-body>
                        <row *ngFor="let data of activeBillingDevices | paginate: pageSizeActive : pageNumberActive; let i = index">
                            <cell [style.width]="defaultColumnWidthActive[0] + '%'">
                                <span>{{ data.serial_number }}</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[1] + '%'">
                                <span *ngIf="isOperator">{{ data.subcustomer_uid ? data.customer_name : ("billing.my_account" | translate) }}</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[2] + '%'">
                                <span>{{ data.in_use ? data.location_name : null }}</span>
                                <div style="display: flex; align-items: center">
                                    <app-custom-tool-tip
                                        *ngIf="!data.in_use"
                                        [spaceBetween]="true"
                                        [label]="'billing.not_in_use' | translate"
                                        [gap]="0.25"
                                        [fullWidth]="false"
                                        [description]="'billing.device_on_active_subscription' | translate"
                                    />
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[3] + '%'">
                                <span>{{ data.in_use ? (data.device_name === "TERMINAL" ? "Anton terminal " : data.device_name) : null }}</span>
                                <div style="display: flex; align-items: center">
                                    <app-custom-tool-tip
                                        *ngIf="!data.in_use"
                                        [spaceBetween]="true"
                                        [label]="'billing.not_in_use' | translate"
                                        [gap]="0.25"
                                        [fullWidth]="false"
                                        [description]="'billing.device_on_active_subscription' | translate"
                                    />
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[4] + '%'">
                                <app-aw-hover-icon
                                    [hoverText]="
                                        data.renewable
                                            ? ('billing.renew_date' | translate) + ': ' + helperService.createdLabelForLists(data.period_end)
                                            : ('billing.cancels' | translate) + ': ' + helperService.createdLabelForLists(data.period_end)
                                    "
                                    [label]="(data.renewable ? 'location.active' : 'billing.cancels') | translate"
                                    [hoverIcon]="'../../../assets/icons/clock-white.svg'"
                                    [icon]="'../../../assets/icons/' + (data.renewable ? 'clock-green.svg' : 'clock-orange.svg')"
                                    [startColor]="data.renewable ? '#67C2AC' : '#FC9124'"
                                    [endColor]="data.renewable ? '#29E1B4' : '#FFB66B'"
                                    [iconWidth]="13"
                                />
                            </cell>
                            <cell *ngIf="hasDiscounts" style="width: 15%">
                                <app-aw-hover-icon
                                    [hoverText]="data.hoverText"
                                    [label]="data.label"
                                    [hoverIcon]="'../../../assets/icons/' + (data.renewable ? 'clock-white.svg' : 'cross-white.svg')"
                                    [icon]="'../../../assets/icons/' + data.icon"
                                    [startColor]="data.startColor"
                                    [endColor]="data.endColor"
                                    [iconWidth]="13"
                                    *ngIf="(globalDiscount && globalDiscount != 0) || data.discount"
                                />
                                <span *ngIf="!((globalDiscount && globalDiscount != 0) || data.discount)">-</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[5] + '%'" style="text-align: right">
                                <div style="width: 100%">
                                    <span>{{ helperService.createdLabelForLists(data.period_start) }}</span>
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthActive[6] + '%'">
                                <div style="display: flex; justify-content: end; width: 100%">
                                    <app-aw-dot-popup-button
                                        [menuOptions]="getMenuOptions(data)"
                                        [isLoading]="dotButtonLoadingIndex === i"
                                        (clickedOption)="catchDotMenuClicked($event, data, i)"
                                        (dropdownOpened)="onDropdownOpened($event)"
                                        (componentInitialized)="dropdownComponents.push($event)"
                                    />
                                </div>
                            </cell>
                        </row>
                    </ng-container>
                </app-aw-table>

                <!--Table displaying devices without subscription (inactive)-->
                <app-aw-table
                    [tableHeaders]="tableHeaderOptionsInactive"
                    [emptytext]="'billing.no_inactive_devices' | translate"
                    (sortBy)="catchSortChanged($event)"
                    (handlePageChange)="handlePageInactive($event)"
                    [pageNumber]="pageNumberInactive"
                    [pageSize]="pageSizeInactive"
                    [totalItems]="totalItemsInactive"
                    [loadingData]="loadingInactiveBilling"
                    [sidescrollable]="isMobile"
                    [initalSort]="this.paramsForInactive.sortBy"
                    [showCheckBoxPosition]="isMobile ? 'start' : 'end'"
                    *ngIf="view === 'billing.inactive'"
                >
                    <ng-container header-checkbox>
                        <div [ngStyle]="{ display: 'flex', 'justify-content': isMobile ? 'start' : 'end', 'align-items': 'center', height: '8px', position: 'relative' }">
                            <app-aw-checkbox
                                style="position: absolute"
                                [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                [checkbox]="areAllDevicesSelectedCheck()"
                                (checkStateEmitter)="selectAllDevices()"
                            />
                        </div>
                    </ng-container>

                    <ng-container table-body>
                        <row *ngFor="let data of inactiveBillingDevices | paginate: pageSizeInactive : pageNumberInactive; let i = index">
                            <cell [style.width]="defaultColumnWidthInactive[6] + '%'" *ngIf="isMobile">
                                <div style="display: flex; justify-content: start; width: 100%">
                                    <app-aw-checkbox
                                        [checkbox]="data.is_checked"
                                        [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                        [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                        (checkStateEmitter)="toggleDeviceSelection(data, $event)"
                                    />
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[0] + '%'">
                                <span>{{ data.serial_number }}</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[1] + '%'">
                                <span *ngIf="isOperator">{{ data.subcustomer_uid ? data.customer_name : ("billing.my_account" | translate) }}</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[2] + '%'">
                                <span>{{ data.location_name }}</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[3] + '%'">
                                <span>{{ data.device_name === "TERMINAL" ? "Anton terminal " : data.device_name }}</span>
                            </cell>
                            <cell *ngIf="hasDiscounts" style="width: 15%">
                                <app-aw-hover-icon
                                    [hoverText]="data.hoverText"
                                    [label]="data.label"
                                    [hoverIcon]="'../../../assets/icons/clock-white.svg'"
                                    [icon]="'../../../assets/icons/' + data.icon"
                                    [startColor]="data.startColor"
                                    [endColor]="data.endColor"
                                    [iconWidth]="13"
                                    *ngIf="(globalDiscount && globalDiscount != 0) || data.discount"
                                />
                                <span *ngIf="!((globalDiscount && globalDiscount != 0) || data.discount)">-</span>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[4] + '%'">
                                <div *ngIf="data.pending_card_payment" class="no-overflow">
                                    <img style="aspect-ratio: 1" src="../../../assets/icons/clock-orange.svg" />
                                    {{ "billing.pending_payment" | translate }}
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[5] + '%'" style="text-align: right">
                                <div style="width: 100%">
                                    <span>{{ helperService.createdLabelForLists(data.created) }}</span>
                                </div>
                            </cell>
                            <cell [style.width]="defaultColumnWidthInactive[6] + '%'" *ngIf="!isMobile">
                                <div style="display: flex; justify-content: end; width: 100%">
                                    <app-aw-checkbox
                                        [disabled]="data.pending_card_payment ?? false"
                                        [checkbox]="isDeviceSelected(data)"
                                        [initialCheckedValue]="data.is_checked"
                                        [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                        [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                        (checkStateEmitter)="toggleDeviceSelection(data, $event)"
                                    />
                                </div>
                            </cell>
                        </row>
                    </ng-container>
                </app-aw-table>
            </div>
        } @placeholder {
            <div class="billing-card-wrapper">
                <div>
                    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
                </div>
            </div>
        }
    </ng-container>
</app-page-layout>

<ng-template #billingSubscriptionModal let-modal>
    <app-billing-subscription-modal
        [selectedDevices]="selectedDevices"
        [globalDiscount]="globalDiscount"
        (subscriptionCreated)="onSubscriptionCreated()"
    ></app-billing-subscription-modal>
</ng-template>
