<ng-lottie [options]="{ path: '../../assets/animations/AW_waves.json' }" class="lottie-waves"></ng-lottie>
<section class="page">
    <div class="container">
        <img src="../../../assets/logo/aw_logo.svg" alt="Airwallet logo" class="logo" />
        <div class="form-container" *ngIf="formrdy && !teamMemberExists">
            <p class="info-p">{{ "team_members.your_info" | translate }}</p>
            <form [formGroup]="teamMemberForm" autocomplete="off" autofill="off">
                <div class="input-group-wrapper-feedback">
                    <label>{{ "misc.email" | translate }}</label>
                    <input class="form-control" type="text" formControlName="email" name="email" disabled="true" />
                    <div class="feedback-container">
                        @if (formSubmitted && email.invalid) {
                            <div class="error-msg" *ngIf="email.errors.required">
                                {{ "misc.email_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- Crazy hack to avoid autofill in chrome -->
                <input
                    class="form-control"
                    type="password"
                    formControlName="password"
                    name="password"
                    tabindex="-1"
                    style="border: none; height: 1px; width: 1px; cursor: default; float: right; padding: 0"
                />

                <div class="input-group-wrapper-feedback">
                    <label>{{ "team_members.first_name" | translate }}</label>
                    <input class="form-control" type="text" formControlName="first_name" name="first_name" tabindex="0" />
                    <div class="feedback-container">
                        @if (formSubmitted && first_name.invalid) {
                            <div class="error-msg" *ngIf="first_name.errors.required">
                                {{ "account.first_name_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <div class="input-group-wrapper-feedback">
                    <label>{{ "team_members.last_name" | translate }}</label>
                    <input class="form-control" type="text" formControlName="last_name" name="last_name" />
                    <div class="feedback-container">
                        @if (formSubmitted && last_name.invalid) {
                            <div class="error-msg" *ngIf="last_name.errors.required">
                                {{ "account.last_name_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <div class="input-group-wrapper-feedback">
                    <label>{{ "location.phone_number" | translate }}</label>
                    <div style="display: flex">
                        <select formControlName="phone_number_call_code" class="form-select call-code-select" (focus)="removeOptions()" (mouseenter)="removeOptions()">
                            <option [ngValue]="null" disabled hidden="true"></option>
                            <optgroup label="Most used countries">
                                <option [ngValue]="fav.label" *ngFor="let fav of phoneNumberPicker.fav" [className]="fav.list !== 'fav' ? 'removeOption' : ''">
                                    {{ fav.label }}
                                </option>
                            </optgroup>
                            <optgroup label="All countries">
                                <option [ngValue]="all.label" *ngFor="let all of phoneNumberPicker.all" [className]="all.list !== 'all' ? 'removeOption' : ''">
                                    {{ all.label }}
                                </option>
                            </optgroup>
                        </select>

                        <input name="phone_number" class="form-control" type="text" formControlName="phone_number" />
                    </div>

                    <div class="feedback-container">
                        @if (formSubmitted && phone_number_call_code.invalid && !phone_number.invalid) {
                            <div class="error-msg" *ngIf="phone_number_call_code.errors.required">
                                {{ "location.phone_number_call_code_required" | translate }}
                            </div>
                        }

                        @if (formSubmitted && phone_number.invalid) {
                            <div class="error-msg" *ngIf="phone_number.errors.required">
                                {{ "location.phone_number_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.pattern">
                                {{ "location.phone_number_should_be_numbers_only" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.maxLength">
                                {{ "location.phone_number_max_length" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <div class="input-group-wrapper-feedback">
                    <label>{{ "misc.password" | translate }}</label>
                    <input class="form-control" type="password" formControlName="password" name="password" autocomplete="off" />
                    <div class="feedback-container">
                        @if (formSubmitted && password.invalid) {
                            <div class="error-msg" *ngIf="password.errors.required">
                                {{ "misc.password_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.maxlength">
                                {{ "misc.password_too_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.minlength">
                                {{ "misc.password_too_short" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.weakPassword">
                                {{ "misc.password_is_weak" | translate }}
                            </div>
                        }
                    </div>
                </div>
                <div class="input-group-wrapper-feedback">
                    <label>{{ "sign_up.repeat_password" | translate }}</label>
                    <input class="form-control" type="password" formControlName="repeat_password" name="repeat_password" autocomplete="off" />
                    <div class="feedback-container">
                        @if (formSubmitted && repeat_password.invalid) {
                            <div class="error-msg" *ngIf="repeat_password.errors.pwNoMatch">
                                {{ "sign_up.password_not_matching" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <div class="divider"></div>

                <div class="input-group-wrapper-feedback mt-4">
                    <div class="checkbox">
                        <input type="checkbox" id="terms" formControlName="terms" name="terms" />
                        <label for="terms"
                            >{{ "sign_up.accept" | translate }}
                            <a class="primary-color" href="{{ termsOfUse }}" target="_blank" tabindex="-1">
                                {{ "sign_up.terms_n_conds" | translate }}
                            </a>
                        </label>
                    </div>
                    <div class="feedback-container">
                        <div class="error-msg" *ngIf="formSubmitted && terms.errors">
                            {{ "sign_up.terms_need_accept" | translate }}
                        </div>
                    </div>
                </div>

                <div class="input-group-wrapper terms">
                    <div class="checkbox">
                        <input type="checkbox" id="gdpr" formControlName="gdpr" name="gdpr" />
                        <label for="gdpr"
                            >{{ "sign_up.accept" | translate }}
                            <a class="primary-color" href="{{ gdprPolicy }}" target="_blank" tabindex="-1">
                                {{ "misc.gdpr" | translate }}
                            </a>
                        </label>
                    </div>
                    <div class="feedback-container">
                        <div class="error-msg" *ngIf="formSubmitted && gdpr.errors">
                            {{ "sign_up.gdpr_needs_accept" | translate }}
                        </div>
                    </div>
                </div>

                <button style="width: 100%; margin-top: 38px" class="btn btn-primary" (click)="createCustomer()">
                    {{ !loading ? ("team_members.create_account" | translate) : "" }}
                    <loading-indicator *ngIf="loading" [size]="'large'" [color]="'white'"></loading-indicator>
                </button>
            </form>
            <p class="support-call">
                {{ "misc.need_help" | translate }}
                <br />
                <a href="{{ supportPhone.telLink }}" tabindex="-1">{{ supportPhone.phoneNumber }}</a>
            </p>
        </div>
    </div>
</section>
