<div class="container mb-2" [hidden]="destroy">
    <loading-indicator [size]="'large'" [color]="'secondary'" *ngIf="showLoadingIndicator"
        class="m-3"></loading-indicator>
    <div *ngIf="!showLoadingIndicator && !destroy">
        <div class="page-title-wrapper">
            <div class="title-area">
                <div>
                    <div>
                        <p style="text-align: left; font-weight: 600; font-size:xx-large;">S/N: {{device &&
                            device.guid}} <span *ngIf="device && device.relay_number">({{'device.relay_number' |
                                translate}}:
                                {{device.relay_number}})</span></p>
                    </div>
                </div>
                <div class="title-area-inner">
                    <div *ngIf="subCustomerAllowed" class="d-flex">
                        <app-animated-button>
                            <div class="animated-button-btns-wrapper">
                                <button class="btn btn-secondary btn-tiny"
                                    *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))"
                                    (click)="reconfig()">{{'device.recofigure' |
                                    translate}}</button>
                                <button *ngIf="showRemoteStart" class="btn btn-secondary btn-tiny"
                                    (click)="openModal(remoteStart)">{{'device.remote_start' |
                                    translate}}</button>
                                <button class="btn btn-secondary btn-tiny" (click)="setMaintenance()"
                                    [disabled]="settingMaintenance">
                                    {{settingMaintenance ? '' : ('device.under_maintenance' | translate)}}
                                    <div *ngIf="settingMaintenance" class="aw-dropdown-options-btn-loading">
                                        <loading-indicator [size]="'small'" [color]="'white'"></loading-indicator>
                                    </div>
                                </button>
                                <button class="btn btn-secondary btn-tiny"
                                    *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))"
                                    (click)="openModal(deleteDeviceModal)">{{'device.delete' | translate}}</button>
                            </div>
                        </app-animated-button>
                        <button class="btn btn-primary btn-tiny" style="width: 150px; margin-left: 8px;"
                            (click)="updateDevice()"
                            *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))"
                            [disabled]="updatingDevice">{{updatingDevice ? '' : 'device.save_changes' |
                            translate}}
                            <loading-indicator *ngIf="updatingDevice" [size]="'small'"
                                [color]="'white'"></loading-indicator>
                        </button>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="device-edit-container">
                    <!--- left culumn-->
                    <div class="div1">
                        <form [formGroup]="updateDeviceForm" style="margin-bottom: 1rem">
                            <div class="column-content">

                                <div class="grey-content-wrapper">


                                    <!-- Name Displayed in app -->
                                    <h5>{{'device.details' | translate}}</h5>
                                    <div class="horizonatal-line"></div>
                                    <div class="input-group-wrapper my-3">
                                        <h6 class="mb-1">{{'device.name_displayed' | translate}}</h6>
                                        <input autocomplete="new-name" name="name" class="form-control" type="text"
                                            formControlName="name">
                                        <div class="input-description-device">{{'device.name_machine' | translate}}
                                        </div>
                                        <div *ngIf="formSubmitted && name.invalid">
                                            <div class="error-msg" *ngIf="name.errors.required">
                                                {{'misc.name_required' | translate}}
                                            </div>
                                            <div class="error-msg" *ngIf="name.errors.maxlength">
                                                {{'misc.name_max' | translate}} {{name.errors.maxlength.requiredLength}}
                                                {{'misc.characters_long' | translate}}
                                            </div>
                                            <div class="error-msg" *ngIf="name.errors.uniqueName">
                                                {{'device_setup.name_unique' | translate}}
                                                <strong>{{name.errors.uniqueName.value}}</strong>
                                                {{'device_setup.already_exists' |
                                                translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PRICE -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.increment_unit !== eIncrementUnit.Money && device.config !== 'PROGRAM_PICKER'">
                                        <h6 class="mb-1">{{'device.price' | translate}}</h6>
                                        <div class="input-group">
                                            <input autocomplete="new-price" name="price" class="form-control"
                                                type="number" min="0.00" step="0.01" formControlName="price">
                                            <div class="input-group-append">
                                                <div *ngIf="device.price_unit === '/increment'">
                                                    <span
                                                        class="input-group-text">{{helperService.getPriceUnit(device.product_type,
                                                        '', device.currency, device.price_unit,
                                                        device.pulse_increment)}}</span>
                                                </div>
                                                <span *ngIf="device.price_unit !== '/increment'"
                                                    class="input-group-text">{{helperService.getPriceUnit(device.product_type,
                                                    '', device.currency, device.price_unit)}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="formSubmitted && price.invalid">
                                            <div class="error-msg" *ngIf="price.errors.required">
                                                {{'device.price_required' | translate}}
                                            </div>
                                            <div class="error-msg" *ngIf="price.errors.pattern">
                                                {{'device.price_to_positive' | translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- START UP TIME, productType === Solarium -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.product_type === eProductType.Solarium">
                                        <h6 class="mb-1">{{'device.start_time' | translate}}</h6>
                                        <select class="form-select" formControlName="startup_time">
                                            <option selected disabled value="null">{{'misc.select_opt' | translate}}
                                            </option>
                                            <option [ngValue]="0">{{'device.start_immediately' | translate}}</option>
                                            <option [ngValue]="90000">{{'device.one_min_thirty_sec' | translate}}
                                            </option>
                                        </select>
                                        <div class="input-description-device">{{'device.time_payment_start' |
                                            translate}}
                                        </div>
                                        <div *ngIf="formSubmitted && startup_time.invalid">
                                            <div class="error-msg" *ngIf="startup_time.errors.required">
                                                {{'device.time_required' | translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PULSE INCREMENT, On all, except Solariums, and FixedPrice devices -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.pulse_increment && device.product_type !== eProductType.Solarium && device.config !== 'PROGRAM_PICKER'">
                                        <h6 class="mb-1">{{device.increment_unit === eIncrementUnit.Money ?
                                            ('device_setup.cost' | translate) : ('device_setup.time' | translate) }}
                                            {{'device.increment' | translate}}</h6>
                                        <div *ngIf="device.increment_unit === eIncrementUnit.Time">
                                            <div class="input-group">
                                                <select class="form-select" formControlName="pulse_increment">
                                                    <option selected disabled value="null">{{'misc.select_opt' |
                                                        translate}}
                                                    </option>
                                                    <option value="{{increment}}"
                                                        *ngFor="let increment of PULSE_INCREMENT_ARR_FOR_TIME">
                                                        {{increment}}</option>
                                                </select>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">{{'device.min' | translate}}</span>
                                                </div>
                                            </div>
                                            <div class="input-description-device">{{'device.user_steps_time' |
                                                translate}}
                                            </div>
                                        </div>
                                        <div *ngIf="device.increment_unit === eIncrementUnit.Money">
                                            <div class="input-group">
                                                <input autocomplete="new-pulse_increment" min="0.01" max="1000"
                                                    step="0.01" name="pulse_increment" class="form-control"
                                                    type="number" formControlName="pulse_increment">
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">{{getCurrencyInUppercase(device.currency)}}</span>
                                                </div>
                                            </div>
                                            <div class="input-description-device">{{'device.user_steps_amount' |
                                                translate}}
                                            </div>
                                        </div>
                                        <div *ngIf="formSubmitted && pulse_increment.invalid">
                                            <div
                                                *ngIf="device.increment_unit === eIncrementUnit.Time || device.relay_number">
                                                <div *ngIf="pulse_increment.errors.required" class="error-msg">
                                                    {{'device.time_increment' | translate}}
                                                </div>
                                                <div *ngIf="pulse_increment.errors.min" class="error-msg">
                                                    {{'device.time_more_one' | translate}}
                                                </div>
                                                <div *ngIf="pulse_increment.errors.max" class="error-msg">
                                                    {{'device.time_more_thrity' | translate}}
                                                </div>
                                            </div>
                                            <div *ngIf="device.increment_unit === eIncrementUnit.Money">
                                                <div *ngIf="pulse_increment.errors.required" class="error-msg">
                                                    {{'device.cost_increment' | translate}}
                                                </div>
                                                <div *ngIf="pulse_increment.errors.min" class="error-msg">
                                                    {{'device.cost_increment_more' | translate}}
                                                    {{getCurrencyInUppercase(device.currency)}}
                                                </div>
                                                <div *ngIf="pulse_increment.errors.max" class="error-msg">
                                                    {{'device.cost_increment_less' | translate}}
                                                    {{getCurrencyInUppercase(device.currency)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PULSE DURATION, On all, except Solariums, Full duration, and FixedPrice devices -->
                                    <!-- is hidden if pulse_duration is higher than 60.000, which is the case for fixed user start time (15 mins >1.5.0 protocol and around 4 mins === 1.5.0 protocol) -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.pulse_duration && isShortPulseDuration">
                                        <h6 class="mb-1">{{'device.pulse_length' | translate}}</h6>
                                        <div class="input-group">
                                            <input autocomplete="new-pulse_duration" name="pulse_duration"
                                                class="form-control" type="number" formControlName="pulse_duration">
                                            <div class="input-group-append">
                                                <span class="input-group-text">{{'device.milli_sec' | translate}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="formSubmitted && pulse_duration.invalid">
                                            <div class="error-msg" *ngIf="pulse_duration.errors.required">
                                                {{'device.pulse_lenght_req' | translate}}
                                            </div>
                                            <div class="error-msg" *ngIf="pulse_duration.errors.pattern">
                                                {{'device.pulse_lenght_between' | translate}}
                                            </div>
                                            <div *ngIf="pulse_duration.errors.pulseDurationOnLegacy" class="error-msg">
                                                {{'device_setup.pulse_length_divisible' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.pulse_duration && !isShortPulseDuration">
                                        <h6 class="mb-1">{{'device.pulse_length' | translate}}</h6>
                                        <div class="input-group">
                                            <input autocomplete="new-pulse_duration" name="pulse_duration"
                                                class="form-control" type="number" formControlName="pulse_duration">
                                            <div class="input-group-append">
                                                <span class="input-group-text">{{'device.min' | translate}}.</span>
                                            </div>
                                        </div>
                                        <div *ngIf="formSubmitted && pulse_duration.invalid">
                                            <div class="error-msg" *ngIf="pulse_duration.errors.required">
                                                {{'device.pulse_lenght_req' | translate}}
                                            </div>
                                            <div class="error-msg" *ngIf="pulse_duration.errors.pattern">
                                                {{'device.between_one_and' | translate}}
                                                {{
                                                helperService.protocolVersionValidator(device.protocol_version).legacy?'4':'180'}}
                                                {{'device.min'
                                                | translate}}.
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PULSE NUMBER  -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="device.pulse_number && device.config !== 'PROGRAM_PICKER'">
                                        <h6 class="mb-1">{{'device.pulse_reqetition' | translate}} </h6>
                                        <input autocomplete="new-pulse_number" name="pulse_number" step="1" min="1"
                                            max="100" class="form-control" type="number" formControlName="pulse_number">
                                        <div class="input-description-device">{{'device.number_pulses' | translate}}
                                        </div>
                                        <div *ngIf="formSubmitted && pulse_number.invalid">
                                            <div class="error-msg" *ngIf="pulse_number.errors.required">
                                                {{'device.pulse_rep_req' | translate}}
                                            </div>
                                            <div *ngIf="pulse_number.errors.min" class="error-msg">
                                                {{'device.pulse_rep_more' | translate}}
                                            </div>
                                            <div *ngIf="pulse_number.errors.max" class="error-msg">
                                                {{'device.pulse_rep_less' | translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- BUSY SIGNAL (ac detect mode) -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="isR1 && device.product_type !== eProductType.Solarium && 
                                        (device.ac_detect_mode === 0 ||device.ac_detect_mode === 1 ||device.ac_detect_mode === 2||device.ac_detect_mode === 3)">
                                        <h6 class="mb-1">{{'device.busy_signal' | translate}}</h6>
                                        <select class="form-select" (change)="onAcDetectModeChange()"
                                            formControlName="ac_detect_mode">
                                            <option selected disabled [ngValue]="null">{{'misc.select_opt' | translate}}
                                            </option>
                                            <option [ngValue]="1">{{'device.volt_high' |
                                                translate}}
                                            </option>
                                            <option [ngValue]="3">{{'device.volt_low' | translate}}</option>
                                            <option [ngValue]="2">{{'device.end_signal' | translate}}</option>
                                            <option [ngValue]="0">{{'device.none_signal' | translate}}</option>
                                        </select>
                                        <div *ngIf="formSubmitted && ac_detect_mode.invalid">
                                            <div class="error-msg" *ngIf="ac_detect_mode.errors.required">
                                                {{'device.busy_signal_req' | translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- OCCUPIED FOR  -->
                                    <div class="input-group-wrapper my-3"
                                        *ngIf="ac_detect_mode.value === 0 && !hideFixedDuration && device.config !== 'PROGRAM_PICKER'">
                                        <h6 class="mb-1">{{'device_setup.fixed_duration' | translate}}</h6>
                                        <div class="input-group">
                                            <input name="fixed_duration" step="1" min="0" max="180" class="form-control"
                                                [readonly]="ac_detect_mode.value ? true : false" type="number"
                                                formControlName="fixed_duration">
                                            <div class="input-group-append">
                                                <span class="input-group-text">{{'device_setup.min' | translate}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="formSubmitted && fixed_duration.invalid">
                                            <div class="error-msg" *ngIf="fixed_duration.errors.required">
                                                {{'device_setup.fixed_duration_req' | translate}}
                                            </div>
                                            <div *ngIf="fixed_duration.errors.min" class="error-msg">
                                                {{'device_setup.fixed_duration_more' | translate}}
                                                {{fixed_duration.errors.min.min}}
                                            </div>
                                            <div *ngIf="fixed_duration.errors.max" class="error-msg">
                                                {{'device_setup.fixed_duration_less' | translate}}
                                                {{fixed_duration.errors.max.max}}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Program picker -->
                                <div class="my-3 grey-content-wrapper" *ngIf="device.config === 'PROGRAM_PICKER'">
                                    <h5 style="margin-bottom: 5px">{{'device.machine_programs' | translate}}</h5>


                                    <div class="d-flex machine-spec-button mb-3"
                                        *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))">
                                        <p class="me-3"
                                            (click)="openModal(createProgram); addEmptyProgram(programsForm.get('programs').controls.length === 0 ? true : false)">
                                            <u>{{programsForm.get('programs').controls.length === 0 ? ('device.create' |
                                                translate) : ('device.edit' |
                                                translate)}}</u>
                                        </p>
                                    </div>
                                    <div class="horizonatal-line mb-2"></div>



                                    <div class="d-column"
                                        *ngIf="device.machine_programs !== undefined; else noPrograms">
                                        <div class="program-list"
                                            *ngFor="let program of device.machine_programs | keyvalue:sortPrograms">
                                            <label class="program-name">
                                                {{program.value.name}}
                                            </label>
                                            <label class="program-price">
                                                {{helperService.getPriceUnit(device.product_type,
                                                '', device.currency, device.price_unit)}}
                                                {{helperService.localizeNumber(program.value.price/100)}}
                                            </label>
                                        </div>
                                    </div>
                                    <ng-template #noPrograms>
                                        <div>
                                            <table class="program-list">
                                                <tr>
                                                    <td class="error-msg">{{'device.no_programs' | translate}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </ng-template>
                                </div>

                            </div>
                        </form>
                    </div>

                    <!-- mid column -->
                    <div class="div2">
                        <div class="column-content">

                            <!-- Machine status -->
                            <div class="grey-content-wrapper mb-3">
                                <h5>{{'device.machine_status' | translate}}</h5>
                                <div class="horizonatal-line"></div>


                                <div *ngIf="device.subscribed === undefined ? true : device.subscribed ? true : false"
                                    class="status-content my-2 d-flex justify-content-between">
                                    <div style="flex-direction: column; display: flex;">
                                        <label style="font-weight: 600;">{{getLastUsedLabel(device.last_used,
                                            device.created_on).label}} </label>
                                        <label> {{getLastUsedLabel(device.last_used, device.created_on).value}}</label>
                                    </div>
                                    <div style="margin: 0 5px 0 0;">
                                        <img style="margin-bottom: 2.5px"
                                            [src]="currentlyOccupied ? '../../../assets/icons/occupied.svg' : '../../../assets/icons/check-circle.svg'">
                                    </div>
                                </div>

                                <div class="status-content my-2 d-flex justify-content-between">
                                    <label>
                                        {{device.maintenance ? ('device.under_maintenance' | translate) :
                                        device.subscribed === undefined ?
                                        ('location.subscribed' | translate) : device.subscribed ? ('location.subscribed'
                                        | translate) : ('location.not_subscribed' | translate)}}
                                    </label>
                                    <div style="margin: 0 5px 0 0;">
                                        <img style="margin-bottom: 2.5px"
                                            [src]="device.maintenance ? '../../../assets/icons/maintenance-icon-temp.svg' : device.subscribed === undefined ? '../../../assets/icons/check-circle.svg' : device.subscribed ? '../../../assets/icons/check-circle.svg' : '../../../assets/icons/cross-scale.svg'">
                                    </div>
                                </div>

                                <div class="status-content my-2 custom-tool-tip-wrapper d-flex justify-content-between"
                                    (mouseenter)="insuranceInfoHover=true" (mouseleave)="insuranceInfoHover=false">

                                    <div style="flex-direction: column; display: flex;">
                                        <label>{{'billing.insurance' | translate}}</label>
                                    </div>
                                    <div class="custom-tool-tip placement-down" *ngIf="insuranceInfoHover">
                                        <div class="mb-2">
                                            <span><img style="margin-bottom: 2.5px"
                                                    src="../../../assets/icons/modal-info-icon.svg"
                                                    alt="{{'billing.insurance' | translate}}"></span>
                                            <span class="tooltip-title">{{'billing.insurance' |
                                                translate}}</span>
                                        </div>
                                        <div>
                                            <span style=" font-weight: 600;">
                                                {{'billing.insurance_description_long_pt1' |
                                                translate}} </span>{{'billing.insurance_description_long_pt2' |
                                            translate}}
                                        </div>
                                        <ul>
                                            <li>{{'billing.insurance_description_long_pt3' | translate}}</li>
                                            <li>{{'billing.insurance_description_long_pt4' | translate}}</li>
                                            <li>{{'billing.insurance_description_long_pt5' | translate}}</li>
                                            <li>{{'billing.insurance_description_long_pt6' | translate}}</li>
                                        </ul>
                                        <div class="mt-2">
                                            {{'billing.insurance_description_long_pt7' | translate}}
                                        </div>
                                        <div class="mt-2">
                                            {{'billing.insurance_description_long_pt8' | translate}}
                                        </div>
                                    </div>
                                    <div style="margin: 0 5px 0 0;">
                                        <img style="margin-bottom: 2.5px"
                                            [src]="device.insured ? '../../../assets/icons/check-circle.svg' : '../../../assets/icons/cross-scale.svg'">
                                    </div>
                                </div>
                            </div>
                            <!--                        Machine Specifications-->

                            <div class="grey-content-wrapper mb-3">
                                <h5 style="margin-bottom: 5px">{{'device.machine_spec' | translate}}</h5>
                                <div class="d-flex machine-spec-button mb-2"
                                    *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))">
                                    <p class="me-3" (click)="openModal(editMachineSpecifications)"><u>{{'device.edit' |
                                            translate}}</u></p>
                                </div>
                                <div class="horizonatal-line"></div>

                                <div class="input-group-wrapper">
                                    <table class="specifications_table">
                                        <tr>
                                            <td class="specifications-item-name">{{'misc.category' | translate}}</td>
                                            <td class="specifications-item-value">
                                                {{device.product_type?getProductType():'-'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="specifications-item-name">{{'misc.brand' | translate}}</td>
                                            <td class="specifications-item-value">{{brand.value?brand.value:'-'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="specifications-item-name">{{'device.model' | translate}}</td>
                                            <td class="specifications-item-value">{{model.value?model.value:'-'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="specifications-item-name">{{'device.serial_num' | translate}}
                                            </td>
                                            <td class="specifications-item-value">
                                                {{serial_number.value ? serial_number.value: '-'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="specifications-item-name">{{'device.production_year' |
                                                translate}}
                                            </td>
                                            <td class="specifications-item-value">
                                                {{production_year.value?production_year.value:'-'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="specifications-item-name">{{'device.comments' | translate}}
                                            </td>
                                            <td class="specifications-item-value">{{note.value?note.value:'-'}}</td>
                                        </tr>
                                        <tr *ngIf="coin_drop_installed.value !== null">
                                            <td class="specifications-item-name">{{'device.coin_drop_installed' |
                                                translate}}
                                            </td>
                                            <td class="specifications-item-value">
                                                {{coin_drop_installed.value?('misc.yes' | translate):('misc.no' |
                                                translate)}}</td>
                                        </tr>
                                        <tr *ngIf="device.open_door !== undefined">
                                            <td class="specifications-item-name">{{'device.installation_type' |
                                                translate}}
                                            </td>
                                            <td class="specifications-item-value">
                                                {{open_door.value?('device.only_when_paid_for' |
                                                translate):('device.with_fixed_power' | translate)}}</td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                            <!-- Machine Services -->
                            <div class="grey-content-wrapper mb-2">
                                <h5 style="margin-bottom: 5px">{{'device.machine_services' | translate}}</h5>

                                <div class="d-flex machine-spec-button mb-2"
                                    *ngIf="authService.hasLimitedAccess('edit_device', (role$ | async))">
                                    <p class="me-3" (click)="openModal(editMachineServices)"><u>{{'device.edit' |
                                            translate}}</u></p>
                                    <p (click)="openModal(resetMachineServices)"><u>{{'support.reset' | translate}}</u>
                                    </p>
                                </div>
                                <div class="horizonatal-line mb-2"></div>

                                <div class="input-group-wrapper">
                                    <table class="specifications_table">
                                        <tr>
                                            <td class="specifications-item-name">{{'device.maintenance_due' |
                                                translate}}</td>
                                            <td class="specifications-item-value" *ngIf="device.machine_specifications">
                                                {{ device.machine_specifications.machine_spec_service &&
                                                device.machine_specifications.machine_spec_service.current_maintenance_count
                                                ?
                                                device.machine_specifications.machine_spec_service.current_maintenance_count
                                                : 0 }}
                                                /
                                                {{ device.machine_specifications.machine_spec_service &&
                                                device.machine_specifications.machine_spec_service.maintenance_limit ?
                                                device.machine_specifications.machine_spec_service.maintenance_limit : 0
                                                }}
                                                {{('device.cycles' | translate)}}
                                            </td>
                                            <td class="specifications-item-value"
                                                *ngIf="!device.machine_specifications">
                                                {{('device.not_configured' | translate)}}
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="device.product_type === ProductType.Washer && device.machine_specifications !== undefined">
                                            <td class="specifications-item-name">{{'device.soap_refill' | translate}}
                                            </td>
                                            <td class="specifications-item-value">
                                                {{ device.machine_specifications.machine_spec_service &&
                                                device.machine_specifications.machine_spec_service.soap_limit ?
                                                (device.machine_specifications.machine_spec_service.current_soap_count ?
                                                device.machine_specifications.machine_spec_service.current_soap_count :
                                                0) + ' / ' +
                                                device.machine_specifications.machine_spec_service.soap_limit + ' '
                                                + ('device.cycles' | translate) : ('device.not_configured' | translate)
                                                }}
                                            </td>
                                            <td class="specifications-item-value"
                                                *ngIf="!device.machine_specifications">
                                                {{('device.not_configured' | translate)}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- right column -->
                    <div class="div3">
                        <div class="column-content">
                            <h5>{{'device.app_level' | translate}}</h5>
                            <div>
                                <img class="app-layout" src='assets/app-layouts/{{layout}}' alt="app layout">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Create Program -->
    <ng-template #createProgram let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{'device.machine_programs' | translate}}</h4>
            </ng-container>
            <ng-container modal-dismiss>
            </ng-container>
            <ng-container modal-body>
                <div class=" d-column d-justifycontent" *ngIf="device.config === 'PROGRAM_PICKER'">
                    <div class="input-labels">

                    </div>
                    <form [formGroup]="programsForm" cdkDropList (cdkDropListDropped)="drop($event)">
                        <div formArrayName="programs" class="programDrag">
                            <div *ngFor="let program of programsForm.get('programs').controls; let index=index;"
                                style="margin-bottom: 1rem" cdkDrag>

                                <div class="input-program" [formGroupName]="index">
                                    <div class="input-program-dragndrop" cdkDragHandle>
                                        <div class="input-program-dots"></div>
                                    </div>
                                    <div class="input-program-inner">

                                        <div
                                            class="inner-price-pulse-box {{ac_detect_mode.value === 0 ? 'inner-fraction' : 'inner-three-fraction'}}">
                                            <div class="inner-name-box">
                                                <label
                                                    [className]="ac_detect_mode.value === 0 ? 'my-1 label-name fixed' : 'my-1 label-name'">
                                                    {{'device.name' | translate}} </label>
                                                <input name="name" type="text" id="{{programsForm.errors && programsForm.errors[program.value.pushkey] && programsForm.errors[program.value.pushkey].name && programsFormSubmitted
                                                ? 'err-border' : ''}}"
                                                    [className]="ac_detect_mode.value === 0 ? 'form-control input-program-basic input-program-name fixed' : 'form-control input-program-basic input-program-name'"
                                                    placeholder="-" formControlName="name">
                                            </div>
                                            <div class="inner-price-box">
                                                <label
                                                    [className]="ac_detect_mode.value === 0 ? 'my-1 label-price fixed' : 'my-1 label-price'">
                                                    {{'device.price' | translate}} </label>
                                                <div id="{{programsForm.errors && programsForm.errors[program.value.pushkey] && programsForm.errors[program.value.pushkey].price && programsFormSubmitted ? 'err-border' : ''}}"
                                                    [className]="ac_detect_mode.value === 0 ? 'input-group input-program-basic input-program-price fixed' : 'input-group input-program-basic input-program-price'">
                                                    <input autocomplete="new-price" name="price" class="form-control"
                                                        type="number" placeholder="-" formControlName="price">
                                                    <div class="input-group-append">
                                                        <span
                                                            class="input-group-text input-currency">{{helperService.getPriceUnit(device.product_type,
                                                            '', device.currency, device.price_unit)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    [className]="ac_detect_mode.value === 0 ? 'my-1 label-pulses fixed' : 'my-1 label-pulses'">
                                                    {{ 'device.pulses' | translate}} </label>
                                                <input type="number" id="{{programsForm.errors && programsForm.errors[program.value.pushkey] && programsForm.errors[program.value.pushkey].pulse_number && programsFormSubmitted
                                            ? 'err-border' : ''}}"
                                                    [className]="ac_detect_mode.value === 0 ? 'form-control input-program-basic input-program-pulses fixed' : 'form-control input-program-basic input-program-pulses'"
                                                    placeholder="-" formControlName="pulse_number">
                                            </div>
                                            <!--                                            OccupiedFor-->
                                            <div *ngIf="ac_detect_mode.value === 0">

                                                <label class="my-1 label-fixed">
                                                    {{'device.fixed_duration' | translate}} </label>
                                                <div class="input-group input-program-basic input-program-fixed"
                                                    id="{{programsForm.errors && programsForm.errors[program.value.pushkey] && programsForm.errors[program.value.pushkey].fixed_duration && programsFormSubmitted ? 'err-border' : ''}}">

                                                    <input type="number" class="form-control"
                                                        formControlName="fixed_duration" placeholder="-">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text input-fixed">{{'device.min' |
                                                            translate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-program-trash-wrapper">
                                            <svg (click)="removeProgram(index)" xmlns="http://www.w3.org/2000/svg"
                                                width="16.074" height="18.371" viewBox="0 0 16.074 18.371">
                                                <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt"
                                                    d="M1.148,16.648A1.722,1.722,0,0,0,2.87,18.371H13.2a1.722,1.722,0,0,0,1.722-1.722V4.593H1.148Zm9.759-9.185a.574.574,0,0,1,1.148,0V15.5a.574.574,0,1,1-1.148,0Zm-3.444,0a.574.574,0,0,1,1.148,0V15.5a.574.574,0,1,1-1.148,0Zm-3.444,0a.574.574,0,0,1,1.148,0V15.5a.574.574,0,1,1-1.148,0ZM15.5,1.148H11.195L10.857.477A.861.861,0,0,0,10.086,0h-4.1a.851.851,0,0,0-.768.477l-.337.671H.574A.574.574,0,0,0,0,1.722V2.87a.574.574,0,0,0,.574.574H15.5a.574.574,0,0,0,.574-.574V1.722A.574.574,0,0,0,15.5,1.148Z"
                                                    transform="translate(0 0)" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="d-column d-justifycontent">
                            <div class="input-labels error-dragndrop-wrapper"
                                *ngIf="programsFormSubmitted && programsForm.invalid">
                                <div
                                    [className]="ac_detect_mode.value === 0 ? 'label-name error-dragndrop fixed' : 'label-name error-dragndrop'">
                                    <div *ngIf="getProgramError() && getProgramError().name">
                                        <label class="error-msg" *ngIf="getProgramError().name.minLength">
                                            {{'misc.name_min'
                                            | translate}}
                                            {{getProgramError().name.minLength}}
                                            {{'misc.characters' | translate}} </label>
                                        <label class="error-msg" *ngIf="getProgramError().name.maxLength">
                                            {{'misc.name_max'
                                            | translate}}
                                            {{getProgramError().name.maxLength}}
                                            {{'misc.characters' | translate}}
                                        </label>
                                    </div>
                                </div>
                                <div
                                    [className]="ac_detect_mode.value === 0 ? 'label-price error-dragndrop fixed' : 'label-price error-dragndrop'">
                                    <div *ngIf="getProgramError() && getProgramError().price">
                                        <label class="error-msg" *ngIf="getProgramError().price.min">
                                            {{'device.price_min' | translate}}
                                            {{getProgramError().price.minNumber}} </label>
                                    </div>
                                </div>
                                <div
                                    [className]="ac_detect_mode.value === 0 ? 'label-pulses error-dragndrop fixed' : 'label-pulses error-dragndrop'">
                                    <div *ngIf="getProgramError() && getProgramError().pulse_number">
                                        <label class="error-msg" *ngIf="getProgramError().pulse_number.min">
                                            {{'device.pulse_number_min' | translate}}
                                            {{getProgramError().pulse_number.minNumber}} </label>
                                        <label class="error-msg" *ngIf="getProgramError().pulse_number.max">
                                            {{'device.pulse_number_max' | translate}}
                                            {{getProgramError().pulse_number.maxNumber}} </label>
                                    </div>
                                </div>
                                <div [className]="ac_detect_mode.value === 0 ? 'label-fixed error-dragndrop fixed' : 'label-fixed error-dragndrop'"
                                    *ngIf="getProgramError() && getProgramError().fixed_duration">
                                    <label class="error-msg" *ngIf="getProgramError().fixed_duration.min">
                                        {{'device.fixed_duration_min' | translate}}
                                        {{getProgramError().fixed_duration.minNumber}} </label>
                                    <label class="error-msg" *ngIf="getProgramError().fixed_duration.max">
                                        {{'device.fixed_duration_max' | translate}}
                                        {{getProgramError().fixed_duration.maxNumber}} </label>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="new-program" (click)="addEmptyProgram(true)">
                        <a><img src="../../../assets/icons/plus-for-create-blue.svg" alt="close dialog"></a>
                        <label>{{'device.create_new_program' | translate}}</label>
                    </div>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid mt-4">
                    <button [class]="'btn btn-secondary'" (click)="submitUpdatePrograms()" [disabled]="showLoading">
                        {{showLoading ? '': ('device.save_changes' | translate) }}
                        <loading-indicator *ngIf="showLoading" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>

    </ng-template>

    <!-- Remote Start -->
    <ng-template #remoteStart let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{'device.remote_start' | translate}}</h4>
            </ng-container>
            <ng-container modal-dismiss>
            </ng-container>
            <ng-container modal-body>
                <div class="my-4" *ngIf="device.config === 'INCREMENT_PICKER'">
                    <label class="my-1">{{'device.amount_to_send' | translate}}</label>
                    <div class="remote-input-wrapper">
                        <div>
                            <button class="btn btn-primary btn-tiny remote-input-btn" (click)="remotestartValues(false)"
                                [disabled]="minimumReached">
                                <div>-</div>
                            </button>
                        </div>
                        <div class="px-1" style="flex-grow: 1;height: 40px;">
                            <p class="remote-input" *ngIf="device.increment_unit === 'money'">{{dataValue}}
                                {{getCurrencyInUppercase(device.currency)}}</p>
                            <p class="remote-input" *ngIf="device.increment_unit === 'time'">{{dataValue}}
                                {{'device.min' | translate}}</p>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-tiny remote-input-btn" (click)="remotestartValues(true)">
                                <div>+</div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="my-3" *ngIf="device.config === 'PROGRAM_PICKER'">
                    <div class="d-row remote-title">
                        <label>{{'device.program_to_be_sent' | translate}}</label>
                    </div>
                    <div id="programs" class="input-group-wrapper d-column program-gap"
                        *ngIf="device.machine_programs !== undefined; else noPrograms">
                        <div (click)="selectedProgram(program)"
                            [className]="selected && program.key === selectedProgramKey ? 'program-remote-list active' : 'program-remote-list'"
                            *ngFor="let program of device.machine_programs | keyvalue:sortPrograms">
                            <label
                                [className]="selected && program.key === selectedProgramKey ? 'program-name active' : 'program-name'">
                                {{program.value.name}}
                            </label>
                        </div>
                    </div>
                    <ng-template #noPrograms>
                        <div class="input-group-wrapper">
                            <table class="program-list">
                                <tr>
                                    <td class="error-msg">{{'device.no_programs' | translate}}</td>
                                </tr>
                            </table>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid gap-2">
                    <button
                        [class]="showLoading ? 'btn btn-secondary' : currentlyOccupied ? 'btn btn-secondary btn-occupied' : 'btn btn-secondary'"
                        (click)="sendRemoteStart()"
                        [disabled]="showLoading || currentlyOccupied || (device.machine_programs && !selected)">
                        {{showLoading ? '': !currentlyOccupied ? device.config === 'PROGRAM_PICKER' ? selected ?
                        ('device.send_signal' | translate) : ('device.select_program' | translate) :
                        ('device.send_signal' | translate) :
                        ('device.machine_occupied'
                        | translate) }}
                        <loading-indicator *ngIf="showLoading" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <!-- DeleteModal -->
    <ng-template #deleteDeviceModal let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{'device.delete' | translate}}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg"
                        alt="close dialog"></a>
            </ng-container>
            <ng-container modal-body>
                <div>
                    <label class="content-label my-2" style="color: #D81E5B ;"><strong>{{'device.attention' |
                            translate}}</strong> {{'device.about_to_delete' | translate}}</label>
                    <label class="content-label my-2" style="font-weight: 600;">{{'device.want_to_replace' |
                        translate}}</label>
                    <input type="text" class="form-control my-2" style="height: 52px;" placeholder="-"
                        [(ngModel)]="replace_serial">
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-flex flex-column align-items-center">
                    <button class="btn btn-secondary mb-3 mt-3" style="width: 100%"
                        (click)="replaceDevice(replace_serial)" [disabled]="showLoadingReplace || loadDeleteDevice">
                        {{showLoadingReplace || loadDeleteDevice ? '' : ('device.replace_device' | translate) }}
                        <loading-indicator *ngIf="showLoadingReplace || loadDeleteDevice" [size]="'large'"
                            [color]="'white'"></loading-indicator>
                    </button>
                    <a tabindex="-1" (click)="deleteDevice()"
                        [disabled]="showLoadingReplace || loadDeleteDevice">{{'device.delete_device_now'
                        | translate}}</a>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <ng-template #editMachineServices let-modal>
        <app-machine-services-edit-modal [device]="device"></app-machine-services-edit-modal>
    </ng-template>

    <ng-template #resetMachineServices let-modal>
        <app-machine-services-reset-modal [location_id]="device.location_id" [device_id]="device.id"
            [customer_id]="uid"></app-machine-services-reset-modal>
    </ng-template>

    <ng-template #editMachineSpecifications let-modal>
        <app-machine-specification-modal [deviceType]="typeOfDevices" [device]="device">
        </app-machine-specification-modal>
    </ng-template>

</div>