import Stripe from 'stripe';
import { Coupon } from './coupon';

export type Titles = 'top_spender' | 'test_user' | 'high_refunds' | 'blocked' | 'new_user' | 'no_title';
export type StatusTypes = 'refund' | 'comment' | 'payment_failed' | 'wallet_payment_failed' | 'payment_method_successful' | 'user_creation';
export interface ShallowActivityDetails {
    status_type: StatusTypes;
    author?: string;
    comment?: string;
    amount?: number;
}

export interface RecentActivityDetails {
    id: string;
    status_type: StatusTypes;
    author?: string;
    amount?: number;
    message?: string;
    payment_id?: string;
    description?: string;
    next_steps?: string;
    currency?: string;
    timestamp: number;
    last4?: string;
    paymentType?: Stripe.PaymentMethod.Card.Wallet.Type | string;
    decline_code?: string;
}

export interface AllowedUpdateFields {
    user_type?: null; // old should be removed
    platform?: 'app' | 'terminal';
    refunds?: Record<string, number>;
    refunds_sum?: number;
    blocked?: boolean;
    user_role?: 'normal' | 'test';
    transactions?: UserTransaction[];
    user_title?: 'top_spender' | 'high_refunds' | 'new_user' | 'no_title';
    recent_activity_list?: Record<string, RecentActivityDetails>;
    available_coupons?: Coupon[];
    applied_coupons?: Coupon[];
}

export interface User {
    id: string;
    name: string;
    phone_number: string;
    phone_number_call_code: string;
    user_type: null; // old should be removed
    platform: 'app' | 'terminal';
    total_spend: Record<string, number>;
    total_spend_sum: number;
    payments: number;
    refunds: Record<string, number>;
    refunds_sum: number;
    created: number;
    currency: string;
    blocked: boolean;
    stripe_cus_id: string;
    last_activity: number;
    owner_uid: string; // the customer who owns taht user object
    payment_method_fingerprint: string;
    user_role: 'normal' | 'test';
    payment_method?: UserPaymentMethod; // A copy of the payment method in users list
    location_id: string;
    pi?: string;
    last4?: string | null; // the same as in pm, to be able to orderByChild on it
}

export function isUser(obj: unknown): obj is User {
    if (typeof obj !== 'object' || obj === null) return false;

    // List of required properties to check for
    const requiredProps = ['id', 'name', 'phone_number', 'phone_number_call_code', 'created', 'currency', 'blocked', 'stripe_cus_id', 'last_activity', 'user_role'];

    // Check for the presence of each required property
    for (const prop of requiredProps) {
        if (!(prop in obj)) return false;
    }

    return true;
}

export interface DetailedUser extends User {
    transactions: UserTransaction[];
    user_title: 'top_spender' | 'test_user' | 'high_refunds' | 'blocked' | 'new_user' | 'no_title';
    subcustomer_name?: string;
    recent_activity_list: RecentActivityDetails[];
    available_coupons: Coupon[];
    applied_coupons: Coupon[];
}

export interface UserTransaction {
    payment_id: string;
    timestamp: number;
    amount: number;
    currency: string;
    location_name: string;
    device_name: string;
    refund: boolean;
    user_id: string;
    uid: string;
}

export type WalletTypes = 'apple_pay' | 'google_pay' | 'samsung_pay' | 'link';

export type paymentTypes =
    | 'no_payment_type'
    | 'visa'
    | 'credit'
    | 'giropay'
    | 'mastercard'
    | 'blik'
    | 'ideal'
    | 'bancontact'
    | 'credit'
    | 'maestro'
    | 'american_express' // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same
    | 'americanexpress' // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same
    | 'amex'; // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same

export interface UserPaymentMethod {
    wallet?: WalletTypes;
    exp_date?: string;
    last4?: string | null;
    in_use: boolean;
    type: paymentTypes;
    fingerprint?: string;
}

export interface Card {
    exp_date: string;
    last4: string;
    in_use: boolean;
    type: string;
}

export interface Credit {
    credit_user_id: string;
    in_use: boolean;
    payment_code: string;
    type: 'credit';
}
export interface ApplePay {
    in_use: boolean;
    type: 'applepay';
}

export interface GiroPay {
    in_use: boolean;
    type: 'giropay';
}

export enum UserSortParams {
    name = 'name',
    phone_no = 'phone_no',
    platform = 'platform',
    payment_method = 'payment_method',
    total_spend_sum = 'total_spend_sum',
    payments = 'payments',
    refunds_sum = 'refunds_sum',
    created = 'created'
}

export enum PaginateNavAction {
    first = 'first',
    next = 'next',
    previous = 'previous',
    last = 'last'
}

export interface UserStoreOptions {
    order_by: UserSortParams;
    direction: 'asc' | 'dsc';
    page_size: number;
    customer: 'all' | string;
    location: 'all' | string;
}

export type UserStoreData = {
    user_store: UserStore;
    user_count: number;
    customer: string;
    location: string;
};

export interface UserData {
    users: User[];
    user_count: number;
    customer: string;
    location: string;
}

export interface UserStore {
    [hashedOptionsKey: string]: {
        // the key is a hash of the filters (sort and other options set), e.g.: order_by, direction, page_size
        [key: number]: User[]; // key is the page number in that filter cache record, and val is the users to display for that page
    };
}
