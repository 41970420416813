<!-- ng container contains the create and edit form, below the payment code modal content is inside a component tag -->
<ng-container *ngIf="!showPaymentCodeModal">
    <app-custom-modal>
        <ng-container modal-title>
            <h4>{{ creditUser ? ("location.update_credit_user" | translate) : ("location.create_credit_user" | translate) }}</h4>
        </ng-container>
        <ng-container modal-dismiss>
            <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
        </ng-container>
        <ng-container modal-body>
            <form tabindex="0" [formGroup]="creditUserForm">
                <!-- id_from_administration -->
                <div class="input-group-wrapper-feedback">
                    <label for="id_from_administration">{{ "misc.user_id" | translate }}</label>
                    <input autocomplete="new-id_from_administration" name="id_from_administration" class="form-control" type="text" formControlName="id_from_administration" />
                    <div class="feedback-container">
                        @if (formSubmitted && id_from_administration.invalid) {
                            <div class="error-msg" *ngIf="id_from_administration.errors.required">
                                {{ "misc.user_id_required" | translate }}
                            </div>
                        }
                    </div>
                </div>
                <!-- name -->
                <div class="input-group-wrapper-feedback">
                    <label for="name">{{ "location.full_name" | translate }}</label>
                    <input autocomplete="new-name" id="name" name="name" class="form-control" type="text" formControlName="name" />
                    <div class="feedback-container">
                        @if (formSubmitted && name.invalid) {
                            <div class="error-msg" *ngIf="name.errors.required">
                                {{ "location.full_name_required" | translate }}
                            </div>
                        }
                    </div>
                </div>
                <!-- phone_number_call_code and phone_number -->
                <div class="input-group-wrapper-feedback">
                    <label for="phone_number">{{ "location.phone_number" | translate }}</label>
                    <div style="display: flex">
                        <select
                            class="form-select call-code-select"
                            id="phone_number"
                            (focus)="removeOptions()"
                            (mouseenter)="removeOptions()"
                            formControlName="phone_number_call_code"
                        >
                            <option [ngValue]="null" disabled hidden="true"></option>
                            <optgroup label="Most used countries">
                                <option [ngValue]="fav.label" *ngFor="let fav of phoneNumberPicker.fav" [className]="fav.list !== 'fav' ? 'removeOption' : ''">
                                    {{ fav.label }}
                                </option>
                            </optgroup>
                            <optgroup label="All countries">
                                <option [ngValue]="all.label" *ngFor="let all of phoneNumberPicker.all" [className]="all.list !== 'all' ? 'removeOption' : ''">
                                    {{ all.label }}
                                </option>
                            </optgroup>
                        </select>

                        <input autocomplete="new-phone_number" name="phone_number" class="form-control" type="text" formControlName="phone_number" />
                    </div>

                    <div class="feedback-container">
                        @if (formSubmitted && phone_number_call_code.invalid && !phone_number.invalid) {
                            <div class="error-msg" *ngIf="phone_number_call_code.errors.required">
                                {{ "location.phone_number_call_code_required" | translate }}
                            </div>
                        }

                        @if (formSubmitted && phone_number.invalid) {
                            <div class="error-msg" *ngIf="phone_number.errors.required">
                                {{ "location.phone_number_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.pattern">
                                {{ "location.phone_number_should_be_numbers_only" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.maxLength">
                                {{ "location.phone_number_max_length" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- sms notification -->
                <div class="input-group-wrapper" *ngIf="!creditUser">
                    <div class="checkbox">
                        <input [checked]="smsNotification" type="checkbox" id="customCheck1" name="sms_notification" (click)="toggleSmsNotification()" />
                        <label for="customCheck1">{{ "location.sms_notification" | translate }}</label>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container modal-footer>
            <div class="d-grid my-4" *ngIf="!creditUser">
                <button class="btn btn-secondary" [disabled]="formProcessingDisable" (click)="submitUserForm()">
                    {{ formProcessing ? "" : creditUser ? ("location.update_user" | translate) : ("location.create_user" | translate) }}
                    <loading-indicator *ngIf="formProcessing" [size]="'large'" [color]="'white'"></loading-indicator>
                </button>
            </div>
            <div class="d-flex justify-content-end" *ngIf="creditUser">
                <app-animated-button>
                    <div class="animated-button-btns-wrapper">
                        <button class="btn btn-secondary btn-tiny" [disabled]="formProcessingDisable" (click)="intializeNewPaymentCodeFlow()">
                            {{ "location.new_payment_code" | translate }}
                        </button>
                        <button class="btn btn-secondary btn-tiny" [disabled]="formProcessingDisable" (click)="deleteUser(creditUser.id)">
                            {{ formProcessingDeleteUser ? "" : ("support.delete_user" | translate) }}
                            <loading-indicator *ngIf="formProcessingDeleteUser" [size]="'large'" [color]="'white'"></loading-indicator>
                        </button>
                    </div>
                </app-animated-button>

                <button class="btn btn-primary btn-tiny save-btn ms-2" [disabled]="formProcessingDisable" (click)="submitUserForm()">
                    {{ formProcessing ? "" : ("device.save_changes" | translate) }}
                    <loading-indicator *ngIf="formProcessing" [size]="'large'" [color]="'white'"></loading-indicator>
                </button>
            </div>
            <p *ngIf="!creditUser" class="support-call">
                {{ "misc.need_help" | translate }}
                <br />
                <a href="{{ supportPhone.telLink }}">{{ supportPhone.phoneNumber }}</a>
            </p>
        </ng-container>
    </app-custom-modal>
</ng-container>

<app-credit-payment-code-modal
    *ngIf="showPaymentCodeModal"
    [creditUser]="creditUser"
    [renewCode]="renewCode"
    [smsNotification]="smsNotification"
    [modalService]="modalService"
    [isEdit]="isEdit"
    [locationId]="locationId"
    (paymentCodeEmitter)="paymentCodeAction($event)"
>
</app-credit-payment-code-modal>
