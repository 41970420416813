import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../../services/auth/auth.service";
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper/helper.service';
import { LoggedInDashboardUser, DashboardUser } from 'shared_models/dashboard-user';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import * as Claims from 'shared_models/claims'
import { SearchService } from 'src/app/services/search/search-service';
import { SearchResult } from 'shared_models/search';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SearchPreviewComponent } from './search-preview/search-preview.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        LoadingComponent,
        SearchPreviewComponent,
        AsyncPipe,
        TranslateModule,
    ],
})

export class NavbarComponent implements OnDestroy, OnInit, AfterViewInit {
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    isSignedIn$: Observable<boolean> = this.authService.isSignedIn;
    isAdmin$: Observable<boolean> = this.authService.isAdmin;
    isDeveloper$: Observable<boolean> = this.authService.isDeveloper;
    isOperator$: Observable<boolean> = this.authService.isOperator;
    isBillingAllowed$: Observable<boolean> = this.authService.isBillingAllowed;
    isCouponAllowed$: Observable<boolean> = this.authService.isCouponAllowed;
    isMobile: boolean;
    mobileSearchOpen = false;
    operatorName$: Observable<string> = this.authService.getOperatorName;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    controlActive = false;
    controlledUid: string;
    loadingControlledUid: boolean;
    user: DashboardUser = this.helperService.getUser();
    loggedInUser: LoggedInDashboardUser = JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser
    burgerOpen = false;
    currentRoute: string;
    searchActive = false;
    searchQuery = '';
    showSearchSuggestions = false;
    suggestionShortCode: string;
    searchResult: SearchResult;
    searchResultAmount: number;
    resultsShown: boolean;
    mouseEvent: MouseEvent
    versionSub: Subscription;
    displayUpdateBar: boolean;
    minorUpdate: boolean;
    majorUpdate: boolean;
    secondsToSignOut = 15;
    loadingResults: boolean = false;
    searchForm: FormGroup;


    constructor(
        protected authService: AuthService,
        private router: Router,
        private toast: ToastrService,
        private helperService: HelperService,
        private translate: TranslateService,
        private searchService: SearchService,
        private renderer: Renderer2,
        private fb: FormBuilder,
        private breakpointObserver: BreakpointObserver

    ) {
        this.breakpointObserver.observe([
            "(max-width: 991px)"
        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            input: new UntypedFormControl()
        })
        this.handleControlActive();
        this.setSubscriptions();
        this.setRenderListener()
        this.setVersionSub();
    }

    ngAfterViewInit(): void {
        const observer = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.querySelector('#push-content-with-margin') ?
                    document
                        .querySelector('#push-content-with-margin')
                        .setAttribute('style', `margin-top: ${(entry.contentRect.height + 30).toString()}px;`) // the plus 30 is for respect space between navbar and page content
                    : null;

                document.querySelector('#search-result-query-selector') ?
                    document
                        .querySelector('#search-result-query-selector')
                        .setAttribute('style', `top: ${(entry.contentRect.height).toString()}px;`)
                    : null;

            });
        });

        observer.observe(document.querySelector("#top-nav-bar-wrapper"));
    }

    ngOnDestroy(): void {
        this.versionSub ? this.versionSub.unsubscribe() : null;
    }

    setRenderListener() {
        this.renderer.listen(document, "click", (event) => {
            const outerDiv = document.getElementById("outer-search-result");
            const searchDiv = document.getElementById(this.isMobile ? "search-mobile-input-wrapper" : "search-input-wrapper");
            if (outerDiv === null || searchDiv === null) {
                return;
            }
            if (!outerDiv.contains(event.target as Node) && !searchDiv.contains(event.target as Node)) {
                this.resultsShown = false;
            }
        });
    }

    getNameString() {
        return `${this.loggedInUser.email.charAt(0).toUpperCase()}${this.loggedInUser.email.slice(1)} - Role: ${this.loggedInUser.role.charAt(0).toUpperCase()}${this.loggedInUser.role.slice(1)}`
    }

    handleSearchAction(event: any) {
        if (this.input.value && !this.input.value.endsWith(':')) {
            this.suggestionShortCode = ''; // clears it so it is ready for a search
        }

        setTimeout(() => { // this is to ensure that click events in the preview is handled before the cases in this function is executed
            if (event.type === 'focusout') {
                this.resultsShown = false;
                this.showSearchSuggestions = false;
            } else if (event.type === 'focus' || event.type === 'keyup') {
                if (!this.input.value) {
                    this.resultsShown = false;
                    this.showSearchSuggestions = true;
                } else if (this.input.value === this.searchQuery) {
                    this.resultsShown = true;
                    this.showSearchSuggestions = false;
                } else {
                    this.resultsShown = false;
                    this.showSearchSuggestions = false;
                }
            }

        }, 150)
    }

    setSubscriptions() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.helperService.setActiveMenuHighlighter(event);

            if (this.isMobile) {
                if (this.currentRoute && this.currentRoute !== event.url) {
                    this.openCloseHamburger('nav');
                }

                this.currentRoute = event.url;
            }
        });
    }

    openCloseHamburger(clickedOrNav: 'click' | 'nav') {
        const open = () => {
            document.getElementById('navbarToggler').classList.add('show');
            this.burgerOpen = true;
        }

        const close = () => {
            document.getElementById('navbarToggler').classList.remove('show');
            this.burgerOpen = false;
        }

        if (clickedOrNav === 'nav') {
            close();
            return;
        }

        if (clickedOrNav === 'click') {
            this.burgerOpen ? close() : open();
            return;
        }
    }

    handleControlActive() {
        if (localStorage.getItem("controlled_user") !== null) {
            this.controlActive = true;
            this.controlledUid = this.user.uid;
        } else {
            this.controlActive = false;
        }
    }

    async setControlledUid() {
        this.loadingControlledUid = true;

        if (this.controlActive) {
            this.exitControlledState(); //exiting controlUid
        } else {
            this.enterControlledState(); // Entering controlUid
        }
    }

    async exitControlledState() {
        this.controlledUid = '';
        localStorage.removeItem('controlled_user')
        localStorage.setItem('loggedInUser', localStorage.getItem('tempLoggedInUser'));
        localStorage.removeItem('tempLoggedInUser');
        await this.router.navigateByUrl('/overview', { skipLocationChange: false }).then(async (result) => {
            this.router.navigate([`/overview`]).then((res) => {
                setTimeout(() => {
                    this.loadingControlledUid = false;
                }, 500);
            });
        });
        this.controlActive = false;
        window.location.reload()
    }

    async enterControlledState() {
        if (!this.controlledUid) {
            this.toast.info(this.translate.instant("navbar.customer_not_found"), this.translate.instant('misc.info'));
            this.loadingControlledUid = false;
            this.controlActive = false;
            return;
        }
        console.log("here")
        this.controlledUid = this.controlledUid.replace(/\s/g, '');
        await this.authService.setControlledUid(this.controlledUid).then(async () => {
            this.user = this.helperService.getUser();
            if (this.helperService.compareUserToLocalStorage('controlled_user')) {
                await this.router.navigateByUrl('/overview', { skipLocationChange: false }).then(async (result) => {
                    this.router.navigate([`/overview`]).then((res) => {
                        setTimeout(() => {
                            this.loadingControlledUid = false;
                        }, 500);
                    });
                    this.controlActive = true;
                    window.location.reload()
                });
            } else {
                this.toast.info(this.translate.instant("navbar.customer_not_found"), this.translate.instant('misc.info'));
                this.loadingControlledUid = false;
                this.controlActive = false;
            }
        }).catch(() => {
            this.toast.info(this.translate.instant("navbar.customer_not_found"), this.translate.instant('misc.info'));
            this.loadingControlledUid = false;
            this.controlActive = false;
        })
    }

    async startSearch() {
        this.loadingResults = true
        this.searchQuery = this.input.value
        this.searchService.ReadSearchResults(this.searchQuery)
        this.searchService.getSearchResult.subscribe((result: SearchResult | null) => {
            if (result) {
                this.getResultAmount(result);
                this.searchResult = this.searchService.getPreviewResults(result, 3);
                this.resultsShown = true;
                this.loadingResults = false;
            }
        })
    }

    handleMobileSearch() {
        this.mobileSearchOpen = this.mobileSearchOpen ? false : true;
        if (!this.mobileSearchOpen) {
            this.resultsShown = false;
        }
    }

    handleItemClick(event: any) {
        if (event) {
            this.resultsShown = false;
            this.searchInput.nativeElement.blur();
        }
    }

    stopDropdownPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    handleSuggestion(shortCode: string) {
        this.suggestionShortCode = shortCode;
        this.input.setValue(shortCode);

        if (this.searchInput) {
            // Focus the search input element
            this.searchInput.nativeElement.focus();
        }
    }

    getResultAmount(result: SearchResult) {
        let count = 0;
        for (const category in result) {
            count = count + result[category].length
        }
        this.searchResultAmount = count;
    }

    refreshWindow() { // is used in HTML
        window.location.href = window.location.href + `${window.location.href.includes('?') ? '&' : '?'}updated=true`;
    }

    setVersionSub() {
        if (!environment.production) {
            return;
        }
        // Subscribe on the version number of the dashboard
        // If it sees that the version it is subscribed to does not match the one in
        // the environment file, it will do a toast message with a reload button in.
        this.versionSub = this.helperService.getCurrentDashboardVersion().snapshotChanges().subscribe(version => {
            const fetchedVersion = version.payload.val()
            const projectVersion: string = environment.VERSION;

            if (fetchedVersion !== projectVersion) {
                const fetchedVersionSplitArr = fetchedVersion.split('.')
                const projectVersionSplitArr = projectVersion.split('.')

                if (
                    fetchedVersionSplitArr[0] > projectVersionSplitArr[0]
                    || fetchedVersionSplitArr[1] > projectVersionSplitArr[1]
                ) {
                    // major update detected
                    this.majorUpdate = true
                    this.minorUpdate = false

                    if (!this.helperService.unguardedUrl(window.location.pathname)) {
                        // only if user is signed in

                        this.displayUpdateBar = true
                        setTimeout(() => {
                            this.authService.signOut()
                            this.displayUpdateBar = false
                        }, this.secondsToSignOut * 1000);

                    } else {
                        if (window.location.search !== '?updated=true') {
                            this.refreshWindow()
                        }
                        this.displayUpdateBar = false
                    }


                } else if (fetchedVersionSplitArr[2] > projectVersionSplitArr[2]) {
                    // minor update detected
                    this.minorUpdate = true
                    this.majorUpdate = false
                    this.displayUpdateBar = true

                }

            } else {
                this.displayUpdateBar = false
                this.minorUpdate = false
                this.majorUpdate = false
            }
        })
    }

    get input() { return this.searchForm.get('input') }
}


