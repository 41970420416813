import { Component, Input } from '@angular/core';
import { LoadingComponent } from '../../loading/loading.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from '../../../services/excel/export-excel.service';
import { HelperService } from '../../../services/helper/helper.service';
import { FilterSortParams } from '../../../../../shared_models/search-params/FilterSortParams';
import { HttpErrorResponse } from '@angular/common/http';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { LoggedInDashboardUser } from '../../../../../shared_models/dashboard-user';

@Component({
    selector: 'app-aw-export-button',
    standalone: true,
    imports: [LoadingComponent, TranslateModule],
    templateUrl: './aw-export-button.component.html',
    styleUrl: './aw-export-button.component.scss'
})
export class AwExportButtonComponent {
    exporting = false;
    fingerprint: string;
    @Input() flag: string;
    @Input() params: FilterSortParams;
    @Input() exportPath: string;
    @Input() disabled = false;
    @Input() limitBeforeMail: number;

    //TODO: Only needed for transaction export - Should be removes once refactored
    activeToast_export: ActiveToast<unknown>;
    @Input() customerName: string;
    @Input() itemCount: number;

    constructor(
        private exportExcelService: ExportExcelService,
        private helperService: HelperService,
        private translate: TranslateService,
        private toast: ToastrService
    ) {}

    async startExport() {
        this.exporting = true;
        if (this.itemCount > this.limitBeforeMail) {
            this.sendViaMailToast();
            this.exporting = false;
        } else {
            await this.export();
        }
    }

    async export(mail?: string) {
        if (mail) {
            this.exporting = false;
        }
        //TODO: Only needed for transaction export - Should be removes once refactored
        if (!this.exportPath) {
            this.transactionsExport(mail).then(() => {
                this.exporting = false;
            });
            return;
        }

        this.exportExcelService.exportToExcel(this.exportPath, JSON.stringify(this.params)).then((res: any) => {
            this.exporting = false;
            if (!res) {
                return;
            }
        });
    }

    async sendViaMailToast() {
        const mail: string = (JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser).email;
        const clickHandler = async (event: any) => {
            // If the clicked element doesn't have the right selector, bail
            if (!event.target.matches('.sendViaMailTarget')) return;
            event.preventDefault();

            this.toast.remove(this.activeToast_export.toastId);
            new Promise(resolve => setTimeout(resolve, 500)).then(() => {
                this.helperService.defaultHtmlToast('', `${this.translate.instant('transactions.export_sent_by_mail')}`, 'Info');
            });
            // Perform desired actions below
            this.export(mail);

            // Remove the click event listener after execution
            document.removeEventListener('click', clickHandler);
        };
        document.removeEventListener('click', clickHandler);
        this.activeToast_export = this.toast.info(
            `
            <div class="toast-container-text">
                <div>
                    <span>${this.translate.instant('transactions.exporting_')} ${this.helperService.formatNumberWithThousandSeparators(this.itemCount)} ${this.translate.instant('transactions.exporting_2')} </span>
                    </br></br>
                </div>
                <div class="toast-container-text-button">
                    <div class="sendViaMailTarget btn btn-primary btn-tiny">
                        <p class="sendViaMailTarget">${this.translate.instant('misc.email')}: ${mail}</p>
                    </div>
                </div>
            </div>
            `,
            this.translate.instant('transactions.export'),
            {
                disableTimeOut: true,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false
            }
        );
        document.addEventListener('click', clickHandler, false);
    }

    //TODO: Only needed for transaction export - Should be removed once refactored
    async transactionsExport(toMail?: string) {
        if (toMail) {
            this.params.exportToMail = toMail;
            this.exportExcelService.exportTransactionsOnMail(this.params);
        } else {
            await this.exportExcelService
                .exportTransactionsToExcel(this.params)
                .then((res: any) => {
                    if (res.code === 202) {
                        // this.helperService.defaultHtmlToast('', `${this.translate.instant('transactions.export_sent_by_mail')}`, 'Info');
                    } else if (res.code === 200) {
                        this.helperService.defaultHtmlToast(this.translate.instant('transactions.export_done'), `${this.itemCount} ${this.translate.instant('transactions.successfully_download')}`, 'Success');
                    }
                })
                .catch((httpResponseError: HttpErrorResponse) => {
                    console.log('error', httpResponseError);
                    this.helperService.defaultHtmlToast(this.translate.instant('Error'), this.translate.instant('transactions.error_exporting'), 'Error');
                });
        }
    }

}


