import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Basic, DeviceTypes } from 'shared_models/device';
import { MachineSpecifications } from 'shared_models/machine-specifications';
import { ProductType } from 'shared_models/product-type';
import { DashboardUser } from 'shared_models/dashboard-user';
import { DeviceService } from 'src/app/services/device/device.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { LoadingComponent } from '../../loading/loading.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { NgFor, NgIf } from '@angular/common';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-machine-specification-modal',
    templateUrl: './machine-specification-modal.component.html',
    styleUrls: ['./machine-specification-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgFor, NgIf, CustomToolTipComponent, LoadingComponent, TranslateModule]
})
export class MachineSpecificationModalComponent implements OnInit {

    @Input() device: Basic
    @Input() deviceType: DeviceTypes;
    shallowDeviceForm: UntypedFormGroup;
    formSubmitted: boolean;
    sendingData: boolean;
    recordOfTypes: string[] = Object.keys(ProductType).filter((key) => ProductType[key] !== ProductType.Charger);
    brandList: string[] = this.helperService.brandEnumToArray();
    user: DashboardUser;

    constructor(
        private route: ActivatedRoute,
        public modalService: NgbModal,
        private helperService: HelperService,
        private deviceService: DeviceService,
        private translate: TranslateService,
        private toast: ToastrService,
        private fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.setupShallowDeviceForm();
        this.setValuesToForm();
    }


    setupShallowDeviceForm() {
        this.shallowDeviceForm = new FormGroup({
            product_type: new UntypedFormControl(this.device.product_type.at(0) + this.device.product_type.slice(1).toLowerCase()),
            coin_drop_installed: new UntypedFormControl(null),
            open_door: new UntypedFormControl(null),
            machine_specifications: this.fb.group({
                brand: [null, [Validators.required]],
                model: [null, [Validators.required, Validators.maxLength(25)]],
                serial_number: [null, [Validators.maxLength(50)]],
                production_year: [null, [ // Current year (+1) and 40 years back, as a list in a dropdown. Format is: YYYY
                    Validators.required,
                    Validators.min(parseInt(moment().format('YYYY')) - 40),
                    Validators.max(parseInt(moment().format('YYYY')) + 1),
                    Validators.maxLength(4),
                    Validators.minLength(4),
                    Validators.pattern(/^([1-9][0-9][0-9][0-9])$/)
                ]],
                note: [null, [Validators.maxLength(250)]],
            })
        })
    }

    capitializeOnlyFirstLetter(string: string): string {
        string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    setValuesToForm() {
        this.shallowDeviceForm.patchValue({
            product_type: this.device.product_type.at(0) + this.device.product_type.slice(1).toLowerCase(),
            coin_drop_installed: this.device.coin_drop_installed,
            open_door: this.device.open_door !== undefined ? this.device.open_door : null,
            machine_specifications: {
                brand: this.device.machine_specifications ? this.device.machine_specifications.brand : null,
                model: this.device.machine_specifications ? this.device.machine_specifications.model : null,
                serial_number: this.device.machine_specifications ? this.device.machine_specifications.serial_number ? this.device.machine_specifications.serial_number : null : null,
                production_year: this.device.machine_specifications ? this.device.machine_specifications.production_year : null,
                note: this.device.machine_specifications ? this.device.machine_specifications.note ? this.device.machine_specifications.note : null : null,
            }
        })

    }

    async saveSpecifications() {
        this.sendingData = true;
        this.formSubmitted = true;
        if (this.shallowDeviceForm.valid) {
            const updatedProperties = this.prepDeviceObject();

            updatedProperties.coin_feedback_enabled =
                updatedProperties.coin_drop_installed && (this.device.ac_detect_mode || this.device.ac_detect_mode === 0) ?
                    this.device.ac_detect_mode === 1 || this.device.ac_detect_mode === 3 ? true : false
                    : null;

            const onbehalf: string = this.route.snapshot.paramMap.get('sub_customer_id');
            updatedProperties.name = this.device.name;
            await this.deviceService.updateDevice(this.device.location_id, updatedProperties, onbehalf ? onbehalf : null).then((response) => {
                this.toast.success(this.translate.instant("device.changes_saved"), this.translate.instant("misc.success"));
                this.sendingData = false;
                this.formSubmitted = false;
                this.modalService.dismissAll()
            }).catch((err) => {
                this.toast.error(this.translate.instant('device.failed_to_save_settings'), this.translate.instant("misc.error"));
                this.sendingData = false;
                this.formSubmitted = false;
            })
        } else {
            this.sendingData = false;
        }
    }

    prepDeviceObject() {
        const updatedDevice: Basic = {
            id: this.device.id,
            ...this.shallowDeviceForm.value,
            ...{ product_type: this.product_type.value.toUpperCase() }
        }

        const machine_spec_default: MachineSpecifications['machine_spec_service'] = {
            maintenance_limit: 1000,
            current_maintenance_count: 0
        }

        updatedDevice.machine_specifications.machine_spec_service =
            this.device.machine_specifications ?
                this.device.machine_specifications.machine_spec_service ?
                    this.device.machine_specifications.machine_spec_service : machine_spec_default : machine_spec_default;
        return updatedDevice
    }

    getControl(name: string): AbstractControl<any, any> {
        return this.shallowDeviceForm.get(`machine_specifications.${name}`)
    }

    get product_type() { return this.shallowDeviceForm.get('product_type') }
    get brand() { return this.shallowDeviceForm.get('brand'); }
    get model() { return this.shallowDeviceForm.get('model'); }
    get serial_number() { return this.shallowDeviceForm.get('serial_number'); }
    get production_year() { return this.shallowDeviceForm.get('production_year'); }
    get note() { return this.shallowDeviceForm.get('note'); }
    get coin_drop_installed() { return this.shallowDeviceForm.get('coin_drop_installed') }
    get open_door() { return this.shallowDeviceForm.get('open_door') }
}
