/**
 * Welsh translation for bootstrap-datepicker
 * S. Morris <s.morris@bangor.ac.uk>
 */
export default {
  cy: {
    days: ["Sul", "Llun", "Mawrth", "Mercher", "Iau", "Gwener", "Sadwrn"],
    daysShort: ["Sul", "Llu", "Maw", "Mer", "Iau", "Gwe", "Sad"],
    daysMin: ["Su", "Ll", "Ma", "Me", "Ia", "Gwe", "Sa"],
    months: ["Ionawr", "Chewfror", "Mawrth", "Ebrill", "Mai", "Mehefin", "Gorfennaf", "Awst", "Medi", "Hydref", "Tachwedd", "Rhagfyr"],
    monthsShort: ["Ion", "Chw", "Maw", "Ebr", "Mai", "Meh", "Gor", "Aws", "Med", "Hyd", "Tach", "Rha"],
    today: "Heddiw"
  }
};
