import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../helper/http.service';
import { QueryResult, SearchParams, SearchResponse } from 'shared_models/transactions';
import { RefundParams } from 'shared_models/order';
import { FilterSortParams } from 'shared_models/search-params/FilterSortParams';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    constructor(private httpService: HttpService) {}

    async getTransactions(data: FilterSortParams, onBehalf: string): Promise<QueryResult> {
        return this.httpService.dynamicHttp(`api_transactions/get?params=${JSON.stringify(data)}`, RequestTypes.GET, { onBehalf });
    }

    async refund(req: RefundParams): Promise<SearchResponse> {
        return this.httpService.dynamicHttp(`api_transactions/refund`, RequestTypes.PUT, { body: req });
    }

    async getSubcustomerNames(): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_operator/names_for_filter`, RequestTypes.GET);
    }

    async getLocationNames(uid: string): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_location/names_for_filter`, RequestTypes.GET, { onBehalf: uid });
    }
    async getDeviceNames(uid: string, locationId: string): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_device/names_for_filter`, RequestTypes.GET, { onBehalf: uid, params: { locationId } });
    }
}


