import { Component, OnDestroy, OnInit } from '@angular/core';
import { AntonHealthServiceService } from '../../../services/anton-health/anton-health-service.service';
import { TableHeaderOptions } from '../../../../../shared_models/aw-components/tableHeaderOptions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';
import { FilterSortParams, Sort } from '../../../../../shared_models/search-params/FilterSortParams';
import { DetailedAntonHealth, DetailedIncident } from '../../../../../shared_models/anton-health';
import dayjs from 'dayjs';
import { DashboardUser } from '../../../../../shared_models/dashboard-user';
import { HelperService } from '../../../services/helper/helper.service';
import * as _ from 'lodash';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeService } from '../../../services/date-time/date-time.service';
import { ActivatedRoute } from '@angular/router';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { IncidentCommentModalComponent } from './incident-comment-modal/incident-comment-modal.component';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor, NgStyle, NgClass } from '@angular/common';
import { TerminalService } from 'src/app/services/terminal/terminal.service';
import { ToastrService } from "ngx-toastr"
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import {LocalStorageService} from "@services/local-storage/local-storage.service";
import {
    AntonHealthTableSettingsComponent
} from "@components/operator/anton-health/anton-health-table-settings/anton-health-table-settings.component";

dayjs.extend(relativeTime);

@Component({
    selector: 'app-anton-health',
    templateUrl: './anton-health.component.html',
    styleUrls: ['./anton-health.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, AwTableComponent, NgFor, NgStyle, NgClass, IncidentCommentModalComponent, TranslateModule, PaginatePipe, AntonHealthTableSettingsComponent]
})
export class AntonHealthComponent implements OnInit, OnDestroy {
    constructor(
        private antonHealthService: AntonHealthServiceService,
        private translate: TranslateService,
        private helperService: HelperService,
        private modalService: NgbModal,
        protected dateTimeService: DateTimeService,
        private route: ActivatedRoute,
        private terminalService: TerminalService,
        private toast: ToastrService,
        protected authService: AuthService,
        private localStorageService: LocalStorageService
    ) { }

    user: DashboardUser;
    rebooting: string[] = []

    antonHealthData: DetailedAntonHealth[] = [];
    expandedList: string[] = [];

    allTableHeaderOptions: TableHeaderOptions[] = [
        {
            // 0
            sortKey: 'status',
            title: this.translate.instant('search.customers.status'),
            width: '0px',
            minWidth: 80,
        },
        {
            // 1
            sortKey: 'last_check_in',
            title: this.translate.instant('operator.anton_health.last_check_in'),
            width: '0px',
            alignment: 'center',
            sortDirection: 'desc'
        },
        {
            // 2
            sortKey: 'session_start',
            title: this.translate.instant('operator.anton_health.session_start'),
            width: '0px',
            alignment: 'right'
        },
        {
            // 3
            sortKey: 'location_name',
            title: this.translate.instant('misc.location'),
            width: '0px',
            minWidth: 150
        },
        {
            // 4
            sortKey: 'custom_location_id',
            title: this.translate.instant('search.suggestions.custom_location_id'),
            width: '0px',
        },
        {
            // 5
            sortKey: 'customer_name',
            title: this.translate.instant('misc.customer'),
            width: '0px',
            minWidth: 150
        },
        {
            // 6
            sortKey: 'charging_state',
            title: this.translate.instant('operator.anton_health.charging'),
            width: '0px',
            minWidth: 80,
            sortDirection: 'desc'
        },
        {
            // 7
            sortKey: 'tablet_battery_level',
            title: this.translate.instant('operator.anton_health.tablet_battery'),
            width: '0px',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            // 8
            sortKey: 'terminal_battery_level',
            title: this.translate.instant('operator.anton_health.terminal_battery'),
            width: '0px',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            // 9
            sortKey: 'terminal_connection_status',
            title: this.translate.instant('operator.anton_health.terminal_connected'),
            width: '0px',
            alignment: 'center',
            sortDirection: 'desc'
        },
        {
            // 10
            sortKey: 'communication',
            title: this.translate.instant('operator.anton_health.communication'),
            width: '0px',
            minWidth: 130,
            alignment: 'left',
            sortDirection: 'desc'
        },
        {
            // 11
            sortKey: 'network',
            title: this.translate.instant('operator.anton_health.network'),
            width: '0px',
            minWidth: 100
        },
        {
            // 12
            sortKey: 'serial',
            title: this.translate.instant('device.serial_num'),
            width: '0px',
            minWidth: 140
        },
        {
            // 13
            sortKey: 'app_version',
            title: this.translate.instant('operator.anton_health.app_version'),
            width: '0px',
            alignment: 'center'
        },
        {
            // 14
            sortKey: 'os_version',
            title: this.translate.instant('operator.anton_health.os_version'),
            width: '0px',
            alignment: 'center'
        },
        {
            // 15
            sortKey: 'kiosk_status',
            title: this.translate.instant('operator.anton_health.locked'),
            width: '0px',
            alignment: 'center'
        },
        {
            // 16
            sortKey: 'knox_license_status',
            title: this.translate.instant('operator.anton_health.reboot_capabilities'),
            width: '0px',
            alignment: 'center',
        },
        {
            // 17
            sortKey: 'incident_count',
            title: this.translate.instant('operator.anton_health.incidents'),
            width: '0px',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            // 18
            sortKey: 'incidents_created',
            title: this.translate.instant('operator.anton_health.last_incidents'),
            width: '0px',
            minWidth: 180,
            alignment: 'right'
        },
        {
            // 19
            sortKey: 'incidents_duration',
            title: this.translate.instant('operator.anton_health.incident_duration'),
            width: '0px',
            sortDirection: 'desc'
        },
        {
            // 20
            sortKey: 'incidents_note_count',
            title: this.translate.instant('operator.anton_health.notes'),
            width: '0px',
            minWidth: 110,
            alignment: 'right'
        },
        {
            // 21
            sortKey: 'solved',
            title: this.translate.instant('operator.anton_health.solved'),
            minWidth: 70,
            width: '0px',
            alignment: 'center'
        },
        {
            // 22 - Empty column for the reboot button
            sortKey: '',
            title: '',
            width: '80px',
            minWidth: 80,
            alignment: 'right'
        },
        {
            // 23 - Empty column for the fold out button
            sortKey: '',
            title: '',
            width: '20px',
            minWidth: 20,
            alignment: 'right'
        }
    ];

    tableHeaderOptions: TableHeaderOptions[] = [];

    // Necessary inputs for FE pagination
    pageSize = 20;
    pageNumber = 0;

    params: FilterSortParams = { filter: {}, sortBy: { key: 'status', order: 'asc' }, pageNumber: this.pageNumber, pageSize: this.pageSize };
    loadingData = true;
    initialLoading = true;
    incidentToBeEdited: DetailedIncident;

    serialToBeEdited: string;
    // Interval for fetching data
    intervalId: NodeJS.Timeout;
    fetchIntervalInSeconds = 60;
    lastFetchedUnix = Date.now();
    nextFetchCountdown = Math.ceil(((this.lastFetchedUnix + (this.fetchIntervalInSeconds * 1000)) - Date.now()) / 1000);
    isPaused = false;
    fetchingData = false;

    // For entering the page from search and jump to an anton with serial
    serialToHighlight: string | undefined = undefined;

    async ngOnInit() {
        this.initialLoading = true
        this.setFilteredTableHeaders();
        this.adjustColumnWidth();
        this.user = this.helperService.getUser();
        this.serialToHighlight = this.route.snapshot.queryParams.highlight;
        await this.getAntonHealthData();
        this.jumpToAnton()
        dayjs().format('YYYY-MM-DD HH:mm:ss');
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    setFilteredTableHeaders() {
        const savedSettings = this.localStorageService.getAntonHealthTableSettings();
        if (!savedSettings) {
            this.tableHeaderOptions = this.allTableHeaderOptions;
            return
        }
        // Filter table headers based on saved settings
        const savedKeys = savedSettings.columns.map(column => column.show ? column.key : false);
        this.tableHeaderOptions = this.allTableHeaderOptions.filter(header => savedKeys.includes(header.sortKey));
        this.adjustColumnWidth(); // Has to be called after the tableHeaderOptions are set
    }

    catchTableSettings() {
        this.setFilteredTableHeaders();
    }

    tableHasKey(key: string): boolean {
        return this.tableHeaderOptions.some(header => header.sortKey === key)
    }

    getLocaleDate(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, false);
    }

    jumpToAnton() {
        if (this.serialToHighlight) {
            const index = this.antonHealthData.findIndex(anton => anton.serial === this.serialToHighlight);
            if (index !== -1) {
                this.pageNumber = Math.floor(index / this.pageSize);
            }
        }
    }

    async getAntonHealthData() {
        this.loadingData = true;
        this.intervalId = setInterval(async () => {
            this.nextFetchCountdown = Math.ceil(((this.lastFetchedUnix + (this.fetchIntervalInSeconds * 1000)) - Date.now()) / 1000);
            if (this.nextFetchCountdown <= 0) {
                if (!this.isPaused && !this.fetchingData) {
                    this.fetchingData = true;
                    this.antonHealthData = await this.antonHealthService.getAntonHealthData();
                    this.catchSortChanged(this.params.sortBy);
                    this.lastFetchedUnix = Date.now();
                    this.nextFetchCountdown = Math.ceil(((this.lastFetchedUnix + (this.fetchIntervalInSeconds * 1000)) - Date.now()) / 1000);
                    this.fetchingData = false;
                }
                if(this.isPaused) {
                    this.lastFetchedUnix = Date.now();
                }
            }
        }, 1000); // 1000 milliseconds = 1 second
        this.antonHealthData = await this.antonHealthService.getAntonHealthData();
        this.catchSortChanged(this.params.sortBy);
        this.loadingData = false;
        this.initialLoading = false;
    }

    pauseOrPlayDataStream() {
        this.isPaused = !this.isPaused;
    }

    expandRow(serial: string) {
        if (this.expandedList.includes(serial)) {
            this.expandedList = this.expandedList.filter(s => s !== serial);
        } else {
            this.expandedList.push(serial);
        }
    }

    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    /**
     * Catch the event from the table component and sort the data accordingly
     * If sorting on status, sort by status first and then by location name
     * If values are empty, always sort them last in the list
     * @param event
     */
    catchSortChanged(event: Sort) {
        this.params.sortBy = event;
        this.antonHealthData = _.orderBy(
            this.antonHealthData,
            [
                item => {
                    if (event.key === 'status') {
                        return typeof item.status === 'string' ? item.status.toLowerCase() : item.status;
                    } else {
                        const keys = event.key.split('.');
                        let value = item;
                        for (const key of keys) {
                            if (value instanceof Array) {
                                if (value.length === 0) {
                                    return event.order == 'asc' ? Infinity : -Infinity;
                                }
                                value = value[0][key];
                            } else {
                                value = value[key];
                            }
                        }
                        return typeof value === 'string' ? (value as string).toLowerCase() : value;
                    }
                },
                item => {
                    if (event.key === 'status') {
                        return typeof item.location_name === 'string' ? item.location_name.toLowerCase() : item.location_name;
                    }
                }
            ],
            [event.order, event.order]
        );
    }


    /**
     * Adjusts the widths of the table columns to ensure the total width is at least 1140px.
     * The last two columns are excluded from the width adjustment but their widths are included in the total width calculation.
     */
    adjustColumnWidth() {
        const totalMinWidth = 1140;
        let totalWidth = 0;
        const excludedColumns = 2;

        // Calculate the total width of the visible columns including the last two columns
        this.tableHeaderOptions.forEach(header => {
            const headerOption = this.allTableHeaderOptions.find(opt => opt.sortKey === header.sortKey);
            if (headerOption) {
                // Calculate the width of the column based on its title and minimum width
                const calculatedWidth = this.calculateWidth(headerOption.title, headerOption.minWidth);
                headerOption.width = calculatedWidth;
                totalWidth += parseInt(calculatedWidth, 10);
            }
        });

        // If the total width is less than the minimum width, distribute the remaining width
        if (totalWidth < totalMinWidth) {
            const remainingWidth = totalMinWidth - totalWidth;
            console.log('Adjusting column widths', totalWidth, totalMinWidth, remainingWidth);
            // Calculate the additional width to be added to each column, excluding the last two columns
            const additionalWidthPerColumn = remainingWidth / (this.tableHeaderOptions.length - excludedColumns);

            console.log("additionalWidthPerColumn", additionalWidthPerColumn, this.tableHeaderOptions);

            // Adjust the widths of the columns, excluding the last two columns
            this.tableHeaderOptions.slice(0, -excludedColumns).forEach(header => {
                const headerOption = this.allTableHeaderOptions.find(opt => opt.sortKey === header.sortKey);
                if (headerOption) {
                    headerOption.width = `${parseInt(headerOption.width, 10) + additionalWidthPerColumn}px`;
                    console.log("headerOption.width", headerOption.sortKey, headerOption.width);
                }
            });
        }
    }

    calculateWidth(title: string, minWidth?: number): string {
        const approxWidth = title.length * 8 + 10; // 7 is an approximate width of a character, adjust as needed
        if (approxWidth < minWidth) {
            return `${minWidth}px`;
        }
        return `${approxWidth}px`;
    }

    openModal(modal: any, incident: DetailedIncident, serial: string) {
        this.incidentToBeEdited = incident;
        this.serialToBeEdited = serial;
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => { }
        );
    }

    catchIncidentChanged(incident: DetailedIncident) {
        const index: number = this.antonHealthData.findIndex(i => i.serial === this.serialToBeEdited);
        if (index !== -1) {
            const incidentIndex: number = this.antonHealthData[index].incidents.findIndex(i => i.id === incident.id);
            this.antonHealthData[index].incidents[incidentIndex] = incident;
        }
    }

    async reboot(t: DetailedAntonHealth) {
        if (!t.uid) { return }
        this.rebooting.push(t.serial)
        console.log(this.rebooting);

        const isAdmin = await this.authService.getAdminStatus()
        await this.terminalService.restartTerminal(t.customer_uid, t.location_id, t.serial, isAdmin ? t.customer_uid : undefined).then(() => {
            this.toast.success(this.translate.instant("location.success_terminal_restart"), this.translate.instant('misc.success'));
        }).catch(() => {
            this.toast.error(this.translate.instant("location.error_terminal_restart"), this.translate.instant('misc.error'));
        })
        this.rebooting = this.rebooting.filter(serial => serial !== t.serial)
    }

    protected readonly dayjs = dayjs;
}
