<div [className]="disabled ? 'disabled filter-wrapper' : 'filter-wrapper'">

    <!--The filter button-->
    <div class="filter-button" (click)="disabled ? null : toggleFilter()">
        <div class="filter-button-content">
            <div class="filter-button-icon" *ngIf="!!numberOfFiltersChosen">
                <p>{{ numberOfFiltersChosen }}</p>
            </div>
            <p class="filter-button-text">{{ 'misc.filters' | translate }}</p>
        </div>
        <img src='../../../../assets/icons/arrow-down-blue.svg'
             [className]="isExpanded ? 'arrow arrow-clicked' : 'arrow'">
    </div>

    <!--The Popup-->
    <div [className]="popupAnchor === 'left' ? 'filter-popup left' : 'filter-popup right'" *ngIf="isExpanded">

        <!--The header in the popup-->
        <div class="filter-popup-header">
            <p>Filters</p>
            <div class="filter-popup-clear" (click)="clearFiters()">
                <u>Clear all</u>
            </div>
        </div>

        <!-- Content goes here-->
        <div class="filter-popup-accordion" *ngFor="let option of filterOptions, let i = index">
            <div class="filter-popup-accordion-button" (click)="openAccordion(i)">
                <img src='../../../../assets/icons/arrow-down-blue.svg'
                     [className]="openAccordions.includes(i) ? 'arrow arrow-clicked' : 'arrow'">
                <p>{{ option.label | translate }}</p>
            </div>
            <div class="filter-popup-accordion-unfolded" *ngIf="openAccordions.includes(i)">
                <input class="common-input w-padding"
                       *ngIf="option.type === FilterType.TEXT"
                       [(ngModel)]="option.value"
                       (ngModelChange)="onFilterOptionChange(option)"
                       (click)="$event.stopPropagation()"
                       type="text">

                <input class="common-input w-padding"
                       *ngIf="option.type === FilterType.NUMBER"
                       [(ngModel)]="option.value"
                       (ngModelChange)="onFilterOptionChange(option)"
                       (click)="$event.stopPropagation()"
                       type="number">

                <input class="common-input w-padding"
                       *ngIf="option.type === FilterType.DATE"
                       [(ngModel)]="option.value"
                       (ngModelChange)="onFilterOptionChange(option)"
                       (click)="$event.stopPropagation()"
                       type="date">

                <div *ngIf="option.type === FilterType.DATE_RANGE" class="date-range-wrapper">
                    <app-aw-datepicker [(date)]="option.value"
                                       size="small"
                                       (click)="$event.stopPropagation()"
                                       (dateChange)="resetToDateIfHigher(option)"
                                       placeholder="operator.filter_bar.date_from"/>
                    <app-aw-datepicker [(date)]="option.value2"
                                       [useEndOfDay]="true"
                                       size="small"
                                       (click)="$event.stopPropagation()"
                                       (dateChange)="resetFromDateIfLower(option)"
                                       placeholder="operator.filter_bar.date_to"/>
                </div>

                <ng-select class="common-input"
                           *ngIf="option.type === FilterType.SELECT"
                           [placeholder]="option.label | translate"
                           (ngModelChange)="onFilterOptionChange(option)"
                           [(ngModel)]="option.value"
                           (click)="$event.stopPropagation()">
                    <ng-option *ngFor="let path of option.selectOptions"
                               [value]="path.value">{{ path.label }}
                    </ng-option>
                </ng-select>

                <ng-select class="common-input"
                           *ngIf="option.type === FilterType.SELECT_MULTIPLE"
                           [placeholder]="option.label | translate"
                           [multiple]="true"
                           (ngModelChange)="onFilterOptionChange(option)"
                           [(ngModel)]="option.value"
                           (click)="$event.stopPropagation()">
                    <ng-option *ngFor="let path of option.selectOptions"
                               [value]="path.value">{{ path.label }}
                    </ng-option>
                </ng-select>

                <div *ngIf="option.type === FilterType.NUMBER_RANGE" class="common-input-borderless">
                    <ng-select class="common-input"
                               style="margin-bottom: 7px"
                               #numberRange
                               (change)="numberRange.blur()"
                               [searchable]="false"
                               (ngModelChange)="onFilterOptionChange(option)"
                               [(ngModel)]="option.numberRangeKey"
                               [clearable]="false"
                               (click)="$event.stopPropagation()">
                        <ng-option *ngFor="let option of numberRangeKeys"
                                   [value]="option.value">{{ option.label | translate }}
                        </ng-option>
                    </ng-select>
                    <div class="date-range-wrapper">
                        <input class="common-input w-padding"
                               [(ngModel)]="option.value"
                               (ngModelChange)="onFilterOptionChange(option)"
                               [placeholder]="(option.numberRangeKey === 'BETWEEN' ? 'coupon.custom_terms.from' :
                                    option.numberRangeKey === 'EQUAL' ? 'filter-button.equal' :
                                    option.numberRangeKey === 'LESS' ? 'filter-button.less_than' : 'filter-button.greater_than') | translate"
                               (click)="$event.stopPropagation()"
                               type="number">
                        <ng-container *ngIf="option.numberRangeKey === 'BETWEEN'">
                            <p>{{ 'misc.and' | translate }}</p>
                            <input class="common-input w-padding"
                                   [placeholder]="'coupon.custom_terms.to' | translate"
                                   (ngModelChange)="onFilterOptionChange(option)"
                                   [(ngModel)]="option.value2"
                                   (click)="$event.stopPropagation()"
                                   type="number">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-popup-header">
            <button class="submit-button" (click)="submitFiltering()">{{'misc.apply_filters' | translate}}</button>
        </div>
    </div>
</div>
