/**
 * Arabic-Algeria translation for bootstrap-datepicker
 * Rabah Saadi <infosrabah@gmail.com>
 */
export default {
  'ar-DZ': {
    days: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"],
    daysShort: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت", "أحد"],
    daysMin: ["ح", "ن", "ث", "ع", "خ", "ج", "س", "ح"],
    months: ["جانفي","فيفري","مارس","أفريل","ماي","جوان","جويليه","أوت","سبتمبر","أكتوبر","نوفمبر","ديسمبر"],
    monthsShort: ["جانفي","فيفري","مارس","أفريل","ماي","جوان","جويليه","أوت","سبتمبر","أكتوبر","نوفمبر","ديسمبر"],
    today: "هذا اليوم",
    rtl: true,
    monthsTitle: "أشهر",
    clear: "إزالة",
    format: "yyyy/mm/dd",
    weekStart: 0
  }
};
