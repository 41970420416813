/**
 * Marathi translation for bootstrap-datepicker
 * Sushant Pimple <pimplesushant@gmail.com>
 */
export default {
  mr: {
    days: ["रविवार", "सोमवार", "मंगळवार", "बुधवार", "गुरुवार", "शुक्रवार", "शनिवार"],
    daysShort: ["रवि", "सोम", "मंगळ", "बुध", "गुरु", "शुक्र", "शनि"],
    daysMin: ["र", "सो", "मं", "बु", "गु", "शु", "श"],
    months: ["जानेवारी", "फेब्रुवारी", "मार्च", "एप्रिल", "मे", "जून", "जुलै", "ऑगस्ट", "सप्टेंबर", "ऑक्टोबर", "नोव्हेंबर", "डिसेंबर"],
    monthsShort: ["जाने.", "फेब्रु.", "मार्च", "एप्रिल", "मे", "जून", "जुलै", "ऑगस्ट", "सप्टें.", "ऑक्टो.", "नोव्हें.", "डिसें."],
    today: "आज",
    monthsTitle: "महीने",
    clear: "हटवा",
    weekStart: 1,
    format: "dd / mm / yyyy"
  }
};
