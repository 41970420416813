/**
 * Bamanankan (bm) translation for bootstrap-datepicker
 * Fatou Fall <fatou@medicmobile.org>
 */
export default {
  bm: {
    days: ["Kari","Ntɛnɛn","Tarata","Araba","Alamisa","Juma","Sibiri"],
    daysShort: ["Kar","Ntɛ","Tar","Ara","Ala","Jum","Sib"],
    daysMin: ["Ka","Nt","Ta","Ar","Al","Ju","Si"],
    months: ["Zanwuyekalo","Fewuruyekalo","Marisikalo","Awirilikalo","Mɛkalo","Zuwɛnkalo","Zuluyekalo","Utikalo","Sɛtanburukalo","ɔkutɔburukalo","Nowanburukalo","Desanburukalo"],
    monthsShort: ["Zan","Few","Mar","Awi","Mɛ","Zuw","Zul","Uti","Sɛt","ɔku","Now","Des"],
    today: "Bi",
    monthsTitle: "Kalo",
    clear: "Ka jɔsi",
    weekStart: 1,
    format: "dd/mm/yyyy"
  }
};
