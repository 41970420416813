<div class="container mb-5">
    <div>
        <h4 class="mb-3 create-coupon-title">{{ isEdit ? ("coupon.edit_coupon" | translate) : ("coupon.create_coupon" | translate) }}</h4>
        <div class="horizontal-line"></div>
    </div>
    <div class="box" [hidden]="isLoading">
        <div class="form-group">
            <div></div>
        </div>
        <form [formGroup]="couponForm">
            <!--    Coupon name-->
            <div class="input-group-wrapper-feedback coupon-name">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.coupon_name' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.coupon_name_description.name' | translate }}
                                 {{ 'coupon.coupon_name_description.example' | translate }}
                                 {{ 'coupon.coupon_name_description.info' | translate }}"
                ></app-custom-tool-tip>
                <input name="name" class="form-control text-box-fs" type="text" formControlName="name" placeholder="{{ 'coupon.coupon_name_placeholder' | translate }}" (change)="temp()" />
                <div class="feedback-container">
                    @if (couponFormSubmitted && name.invalid) {
                        <div class="error-msg" *ngIf="name.errors?.required">
                            {{ "coupon.error.name_error" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!--    Coupon type-->
            <div class="type-wrapper">
                <app-custom-tool-tip
                    class="fw-bold"
                    forInput="true"
                    label="{{ 'coupon.coupon_type' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.coupon_type_description.select' | translate }}
                                 {{ 'coupon.coupon_type_description.percentage' | translate }}
                                 {{ 'coupon.coupon_type_description.fixed' | translate }}
                                 {{ 'coupon.coupon_type_description.cycles' | translate }}"
                ></app-custom-tool-tip>
                <div class="radio-group minus-margin">
                    <mat-radio-group class="aw-radio-wrapper flex-column" formControlName="discount_type" (change)="couponTypeChange()">
                        <mat-radio-button class="aw-radio" value="percentage" [disabled]="isEdit">
                            {{ "coupon.type.percentage" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio" value="fixed" [disabled]="isEdit">
                            {{ "coupon.type.fixed" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio" value="freeCycles" (click)="type.setValue(durationType.ONCE)" [disabled]="isEdit">
                            {{ "coupon.type.cycles" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <!-- Percentage input -->
            <div class="input-group-wrapper-feedback" *ngIf="discount_type.value === discountType.PERCENTAGE">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.percentage_discount' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.percentage_description.info' | translate }}
                                 {{ 'coupon.percentage_description.choose' | translate }}
                                 {{ 'coupon.percentage_description.example' | translate }}"
                ></app-custom-tool-tip>
                <div class="input-group">
                    <input name="percentage_discount" class="form-control text-box-fs" type="number" formControlName="percentage_discount" placeholder="-" *ngIf="!isEdit" />
                    <span *ngIf="isEdit" class="form-control text-box-fs disabled-form">
                        {{ displayPercentageValue() }}
                    </span>
                    <div class="input-group-append">
                        <span class="input-group-text text-box-fs" [ngClass]="{ 'disabled-form': isEdit }" *ngIf="discount_type.value === discountType.PERCENTAGE">%</span>
                    </div>
                </div>

                <div class="feedback-container">
                    @if (couponFormSubmitted && percentage_discount.invalid) {
                        <div class="error-msg" *ngIf="percentage_discount.errors?.required">
                            {{ "coupon.error.discount_error" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="percentage_discount.errors?.max || percentage_discount.errors?.min">
                            {{ "coupon.percentage_max_min_lenght" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!-- Fixed amount input -->
            <div class="input-group-wrapper-feedback" *ngIf="discount_type.value === discountType.FIXED">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.type.fixed' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.fixed_description.info' | translate }}
                                 {{ 'coupon.fixed_description.example' | translate }}
                                 {{ 'coupon.fixed_description.limit' | translate }}"
                ></app-custom-tool-tip>
                <div class="input-group">
                    <input name="fixed_amount" class="form-control text-box-fs" type="text" inputmode="numeric" formControlName="fixed_amount" placeholder="-" (change)="handleFixedAmount($event)" *ngIf="!isEdit" />
                    <span *ngIf="isEdit" class="form-control text-box-fs disabled-form">
                        {{ fixed_amount.value }}
                    </span>
                    <div class="input-group-append">
                        <span class="input-group-text text-box-fs" [ngClass]="{ 'disabled-form': isEdit }" *ngIf="discount_type.value === discountType.FIXED">{{ dashboardUser.settings.currency.toUpperCase() }}</span>
                    </div>
                </div>
                <div class="feedback-container">
                    @if (couponFormSubmitted && fixed_amount.invalid) {
                        <div class="error-msg" *ngIf="fixed_amount.errors?.required">
                            {{ "coupon.error.discount_error" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="fixed_amount.errors?.invalidNumber">
                            {{ "coupon.error.fixed_amount_only_numbers" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!-- Free cycles input -->
            <div class="input-group-wrapper-feedback" *ngIf="discount_type.value === discountType.CYCLES">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.type.cycles' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.free_cycles_description.info' | translate }}
                                 {{ 'coupon.free_cycles_description.example' | translate }}"
                ></app-custom-tool-tip>
                <div class="input-group">
                    <input name="free_cycles" class="form-control text-box-fs" type="number" formControlName="free_cycles" placeholder="-" *ngIf="!isEdit" />
                    <span *ngIf="isEdit" class="form-control text-box-fs disabled-form"> {{ free_cycles.value }} </span>
                    <div class="input-group-append">
                        <span class="input-group-text text-box-fs" [ngClass]="{ 'disabled-form': isEdit }" *ngIf="discount_type.value === discountType.CYCLES">{{ "coupon.cycles" | translate }}</span>
                    </div>
                </div>

                <div class="feedback-container">
                    @if (couponFormSubmitted && free_cycles.invalid) {
                        <div class="error-msg" *ngIf="free_cycles.errors?.required">
                            {{ "coupon.error.cycles_error" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="free_cycles.errors?.pattern">
                            {{ "coupon.error.cycles_error_pattern" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="free_cycles.errors?.max">
                            {{ "coupon.error.cycles_error_max" | translate }}
                        </div>
                    }
                </div>
            </div>

            <!--    Apply to (locations)-->
            <div class="input-group-wrapper">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.apply_to' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.apply_to_description.select' | translate }}
                                 {{ 'coupon.apply_to_description.edit' | translate }}"
                ></app-custom-tool-tip>
                <div class="form-control location-status no-border text-box-fs" [ngClass]="{ 'disabled-form': isEdit }">
                    <span [ngClass]="{ 'disabled-form': isEdit }"
                        >{{ global.value ? ("coupon.all" | translate) : selectedLocationAmount ? selectedLocationAmount : ("coupon.none" | translate) }}
                        {{
                            global.value
                                ? hasSubCustomers
                                    ? ("coupon.customers_selected" | translate)
                                    : ("coupon.locations_selected" | translate)
                                : selectedLocationAmount > 1
                                  ? ("coupon.locations_selected" | translate)
                                  : ("coupon.location_selected" | translate)
                        }}</span
                    >
                    <u class="pointer text-box-fs" (click)="openLocationModal(couponLocationModal)" [ngClass]="{ 'disabled-form': isEdit }" *ngIf="(isEdit && !global.value) || !isEdit">
                        {{ isEdit ? ("coupon.view" | translate) : ("coupon.edit" | translate) }}
                    </u>
                </div>

                <div class="location-error-msg-container">
                    <div *ngIf="couponFormSubmitted && !selectedLocationAmount && !global.value">
                        <div class="error-msg" *ngIf="!selectedLocationAmount">
                            {{ "coupon.error.location_error" | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <!--    Distribute with code-->
            <div class="distribute mt-4">
                <app-custom-tool-tip
                    class="fw-bold"
                    forInput="true"
                    label="{{ 'coupon.distribute_type' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.distribute_description.select' | translate }}
                                 {{ 'coupon.distribute_description.choose' | translate }}"
                ></app-custom-tool-tip>
                <div class="radio-group">
                    <mat-radio-group class="aw-radio-wrapper flex-column" formControlName="distribute_type" (change)="distributeTypeChange()">
                        <mat-radio-button class="aw-radio distribute-label" [value]="distributeType.ALL" [disabled]="isEdit">
                            {{ "coupon.dis_type.all" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio distribute-label" [value]="distributeType.SELECTED" [disabled]="isEdit">
                            {{ "coupon.dis_type.selected" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio distribute-label" [value]="distributeType.CODE" [disabled]="isEdit">
                            {{ "coupon.dis_type.code" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <!--    Coupon code-->
            <div class="input-group-wrapper" *ngIf="distribute_type.value === distributeType.CODE">
                <app-custom-tool-tip
                    forInput="true"
                    label="{{ 'coupon.coupon_code' | translate }}"
                    [style]="{ 'white-space': 'pre-line' }"
                    description="{{ 'coupon.code_description.own' | translate }}
                                 {{ 'coupon.code_description.advice' | translate }}
                                 {{ 'coupon.code_description.first' | translate }}
                                 {{ 'coupon.code_description.second' | translate }}"
                ></app-custom-tool-tip>
                <input name="coupon_code" class="form-control text-box-fs" type="text" formControlName="coupon_code" placeholder="{{ 'coupon.code_placeholder' | translate }}" (input)="onCodeInput($event)" *ngIf="!isEdit" />
                <span *ngIf="isEdit" class="form-control text-box-fs disabled-form">
                    {{ coupon_code.value }}
                </span>

                <div class="code-error-msg-container">
                    <div *ngIf="couponFormSubmitted && coupon_code.invalid">
                        <div class="error-msg" *ngIf="coupon_code.errors?.required">
                            {{ "coupon.error.code_error" | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <!--    Coupon entries-->
            <div class="input-group-wrapper" *ngIf="distribute_type.value === distributeType.CODE">
                <div>
                    <app-custom-tool-tip class="fw-bold" forInput="true" label="{{ 'coupon.entries' | translate }}" [style]="{ 'white-space': 'pre-line' }" description="{{ 'coupon.entries_description' | translate }}"></app-custom-tool-tip>
                </div>
                <div class="notif-fixer">
                    <app-aw-switch [controlName]="entries" [shouldBeChecked]="trackEntries" (activateClick)="toggleEntries()" [disabled]="isEdit"></app-aw-switch>
                    <span class="notification-subtext text-box-fs">{{ "coupon.unlimited_entries" | translate }}</span>
                </div>
            </div>

            <!--    Coupon entries input field-->
            <div class="input-group-wrapper" *ngIf="entries.value === false">
                <label>{{ "coupon.entries_select_amount" | translate }}</label>
                <div class="input-group">
                    <input name="entries_limit" class="form-control text-box-fs" type="number" formControlName="entries_limit" placeholder="-" (input)="validatePositiveInteger($event)" *ngIf="!isEdit" min="1" />
                    <span *ngIf="isEdit" class="form-control text-box-fs disabled-form">
                        {{ entries_limit.value }}
                    </span>
                    <div class="input-group-append">
                        <span class="input-group-text text-box-fs" [ngClass]="{ 'disabled-form': isEdit }" *ngIf="entries.value === false">{{ "coupon.entries_users" | translate }}</span>
                    </div>
                </div>
                <div class="code-error-msg-container">
                    <div *ngIf="couponFormSubmitted && entries_limit.invalid">
                        <div class="error-msg" *ngIf="entries_limit.errors?.required">
                            {{ "coupon.entries_number_required" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="entries_limit.errors?.min">
                            {{ "coupon.entries_number_greater_than_zero" | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div formGroupName="duration">
                <!--    Apply at-->
                <div class="input-group-wrapper mt-4">
                    <app-custom-tool-tip
                        forInput="true"
                        label="{{ 'coupon.duration.apply_at' | translate }}"
                        [style]="{ 'white-space': 'pre-line' }"
                        description="{{ 'coupon.duration.apply_at_description.select' | translate }}
                                     {{ 'coupon.duration.apply_at_description.additional' | translate }}
                                     {{ 'coupon.duration.apply_at_description.options' | translate }}
                                     {{ 'coupon.duration.apply_at_description.can_also' | translate }}"
                    ></app-custom-tool-tip>
                    <select class="form-select text-box-fs" formControlName="type" (change)="couponDurationChange()">
                        <option [value]="durationType.ONCE" [disabled]="isEdit">{{ "coupon.duration.type.once" | translate }}</option>
                        <option [value]="durationType.FOREVER" [disabled]="discount_type.value === discountType.CYCLES || isEdit">
                            {{ "coupon.duration.type.forever" | translate }}
                        </option>
                        <option [value]="durationType.EVERY" [disabled]="isEdit" [attr.disabled]="isEdit && couponToEdit?.duration.type !== durationType.EVERY ? 'true' : null">
                            {{ "coupon.duration.type.every" | translate }}
                        </option>
                        <option [value]="durationType.HAPPYHOUR" [disabled]="discount_type.value === discountType.CYCLES || isEdit">
                            {{ "coupon.duration.type.happy_hour" | translate }}
                        </option>
                        <!-- <option [value]="durationType.REFILL" [attr.disabled]="isEdit && couponToEdit?.duration.type !== durationType.REFILL ? 'true' : null">{{'coupon.duration.type.refill' | translate}}</option> -->
                        <option [value]="durationType.PERIOD" [disabled]="discount_type.value === discountType.CYCLES || isEdit" [attr.disabled]="isEdit && couponToEdit?.duration.type !== durationType.PERIOD ? 'true' : null">
                            {{ "coupon.duration.type.period" | translate }}
                        </option>
                    </select>
                </div>

                <!--    Duration : every-->
                <div class="input-group-wrapper" *ngIf="duration.value.type === durationType.EVERY">
                    <select class="form-select text-box-fs" formControlName="triggerType" *ngIf="!isEdit" (change)="couponTypeChange()">
                        <option selected [value]="TriggerType.STARTS">{{ "coupon.duration.every_type.starts" | translate }}</option>
                        <option [value]="TriggerType.DAYS">{{ "coupon.duration.every_type.days" | translate }}</option>
                        <option [value]="TriggerType.WEEKS">{{ "coupon.duration.every_type.weeks" | translate }}</option>
                        <option [value]="TriggerType.MONTHS">{{ "coupon.duration.every_type.months" | translate }}</option>
                        <option [value]="TriggerType.SPENT">{{ "coupon.duration.every_type.spent" | translate }}</option>
                    </select>
                    <span *ngIf="isEdit" class="form-control text-box-fs disabled-form"> {{ target.value }} {{ triggerType.value }} </span>
                </div>
                <div class="input-group-wrapper" *ngIf="duration.value.type === durationType.EVERY && !isEdit">
                    <div class="input-group">
                        <input name="target" class="form-control text-box-fs" type="text" pattern="[0-9.]*" inputmode="numeric" formControlName="target" (change)="handleTargetAmount($event)" placeholder="-" />
                        <div class="input-group-append">
                            <span class="input-group-text text-box-fs" *ngIf="triggerType.value === TriggerType.STARTS">{{ "coupon.duration.every_append.starts" | translate }}</span>
                            <span class="input-group-text text-box-fs" *ngIf="triggerType.value === TriggerType.DAYS">{{ "coupon.duration.every_append.days" | translate }}</span>
                            <span class="input-group-text text-box-fs" *ngIf="triggerType.value === TriggerType.WEEKS">{{ "coupon.duration.every_append.weeks" | translate }}</span>
                            <span class="input-group-text text-box-fs" *ngIf="triggerType.value === TriggerType.MONTHS">{{ "coupon.duration.every_append.months" | translate }}</span>
                            <span class="input-group-text text-box-fs" *ngIf="triggerType.value === TriggerType.SPENT">{{ this.dashboardUser.settings.currency.toUpperCase() }}</span>
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper">
                    <div *ngIf="couponFormSubmitted && target.errors?.invalid">
                        <div class="error-msg" *ngIf="target.errors?.required">
                            {{ "coupon.error.specification_error" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="target.errors?.invalidNumber">
                            {{ "coupon.invalid_number" | translate }}
                        </div>
                    </div>
                </div>
                <div class="upfront" *ngIf="duration.value.type === durationType.EVERY">
                    <div>
                        <mat-checkbox (click)="toggleGivenUpfront()" [disabled]="isEdit" [checked]="given_up_front.value === true">
                            {{ "coupon.duration.given_upfront" | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <!--    Duration : happyHour-->
                <div class="input-group-wrapper" *ngIf="duration.value.type === durationType.HAPPYHOUR">
                    <app-aw-calendar-layout [week]="layoutWeek" [disableLayout]="isLoading" (edit)="openHappyHourModal(couponHappyHourModal)"></app-aw-calendar-layout>
                </div>

                <!--    Duration refill-->
                <div class="input-group-wrapper" *ngIf="duration.value.type === durationType.REFILL">
                    <label class="text-box-fs">{{ "coupon.duration.min_refill" | translate }}</label>
                    <div class="input-group">
                        <input name="refill_target" class="form-control text-box-fs" formControlName="refill_target" type="text" pattern="[0-9.]*" inputmode="numeric" placeholder="-" (change)="handleRefillTargetAmount($event)" />
                        <div class="input-group-append">
                            <span class="input-group-text text-box-fs" *ngIf="duration.value.type === durationType.REFILL">{{ dashboardUser.settings.currency.toUpperCase() }}</span>
                        </div>
                    </div>

                    <div class="refill-error-msg-container">
                        <div *ngIf="couponFormSubmitted && refill_target.invalid">
                            <div class="error-msg" *ngIf="refill_target.errors?.required">
                                {{ "coupon.error.specification_error" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <!--    Duration : period-->
                <div class="input-group-wrapper duration-period" [hidden]="duration.value.type !== durationType.PERIOD">
                    <!--    Date from-->
                    <div class="from">
                        <input id="fromEdit" name="from" class="form-control text-box-fs cursor-na" readonly placeholder="{{ 'coupon.date_from' | translate }}: {{ helperService.getLocalizedDate(from.value) }}" type="text" *ngIf="isEdit" />
                        <input
                            id="from"
                            name="from"
                            class="form-select form-control data-select pointer text-box-fs"
                            readonly
                            placeholder="{{ 'coupon.date_from' | translate }}"
                            type="text"
                            (changeDate)="datePickerChanged('from')"
                            [hidden]="isEdit"
                        />
                    </div>
                    <!--    Date to-->
                    <div class="to">
                        <input id="toEdit" name="from" class="form-control text-box-fs cursor-na" readonly placeholder="{{ 'coupon.date_to' | translate }}: {{ helperService.getLocalizedDate(to.value) }}" type="text" *ngIf="isEdit" />
                        <input
                            id="to"
                            name="to"
                            class="form-select form-control data-select pointer text-box-fs"
                            readonly
                            placeholder="{{ 'coupon.date_to' | translate }}"
                            type="text"
                            (changeDate)="datePickerChanged('to')"
                            [hidden]="isEdit"
                        />
                    </div>

                    <div class="period-error-msg-container">
                        <div *ngIf="couponFormSubmitted && (from.invalid || to.invalid)">
                            <div class="error-msg" *ngIf="from.errors?.required && to.errors?.required">
                                {{ "coupon.error.period_error" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--    Notify users-->
            <div class="mt-4" [ngClass]="{ 'disable-notify': distribute_type.value !== distributeType.ALL || isEdit }">
                <div>
                    <app-custom-tool-tip
                        class="fw-bold"
                        forInput="true"
                        label="{{ 'coupon.notify_users' | translate }}"
                        [style]="{ 'white-space': 'pre-line' }"
                        description="{{ 'coupon.notify_description' | translate }}"
                    ></app-custom-tool-tip>
                </div>
                <div class="notif-fixer">
                    <app-aw-switch [controlName]="notify_users" [shouldBeChecked]="allowNotifyUsers" (activateClick)="toggleNotifyUsers()" [disableSwitch]="distribute_type.value !== distributeType.ALL || isEdit"></app-aw-switch>
                    <span class="notification-subtext text-box-fs">{{ "coupon.notify_subtext" | translate }}</span>
                </div>
            </div>

            <!--    Notification title-->
            <div class="input-group-wrapper" *ngIf="notify_users.value === true">
                <label>{{ "coupon.notify_headline" | translate }}</label>
                <textarea
                    #titleTextarea
                    name="notification_title"
                    class="form-control notification-textarea text-box-fs"
                    type="text"
                    formControlName="notification_title"
                    placeholder="{{ 'coupon.headline_placeholder' | translate }}"
                    rows="1"
                    maxlength="30"
                    *ngIf="!isEdit"
                    (input)="countCharacters(titleTextarea, 'counter1')"
                ></textarea>
                <textarea name="notification_title" class="form-control notification-textarea text-box-fs cursor-na disabled-form" type="text" formControlName="notification_title" rows="1" maxlength="30" readonly *ngIf="isEdit">{{
                    notification_title.value
                }}</textarea>
                <div class="character-count text-box-fs" [hidden]="isEdit" id="counter1">0/30</div>
            </div>

            <!--    Notification description-->
            <div class="input-group-wrapper" *ngIf="notify_users.value === true">
                <label>{{ "coupon.notify_text" | translate }}</label>
                <textarea
                    #descriptionTextarea
                    name="notification_description"
                    class="form-control notification-textarea text-box-fs"
                    type="text"
                    formControlName="notification_description"
                    placeholder="{{ 'coupon.text_placeholder' | translate }}"
                    rows="4"
                    maxlength="120"
                    *ngIf="!isEdit"
                    (input)="countCharacters(descriptionTextarea, 'counter2')"
                ></textarea>
                <textarea
                    name="notification_description"
                    class="form-control notification-textarea text-box-fs cursor-na disabled-form"
                    type="text"
                    formControlName="notification_description"
                    rows="4"
                    maxlength="120"
                    readonly
                    *ngIf="isEdit"
                    >{{ notification_description.value }}</textarea
                >
                <div class="character-count text-box-fs" [hidden]="isEdit" id="counter2">0/120</div>

                <div class="notification-error-msg-container">
                    <div *ngIf="couponFormSubmitted && (notification_title.invalid || notification_description.invalid)">
                        <div class="error-msg">
                            {{ "coupon.error.notification_error" | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="attention-box" *ngIf="entries_limit.value === 1 && fixed_amount.value && duration.value.type === 'once'">
          <div class="attention-box-title-box">
              <img src="../../../assets/icons/info-warning-circle.svg" alt="Warning">
              <p class="attention-box-title-box-title">
                {{ 'misc.attention' | translate }}
              </p>
          </div>
          <p><strong>{{ 'coupon.attention-bold' | translate }}</strong></p>
          <p>{{ 'coupon.attention-text' | translate }}</p>
        </div>

        <div class="create-button d-flex flex-column">
            <button [class]="'btn btn-primary mt-4'" (click)="isEdit ? updateCoupon() : createCoupon()">
                {{ saveLoading ? "" : isEdit ? ("coupon.save_changes" | translate) : ("coupon.create_coupon" | translate) }}
                <loading-indicator *ngIf="saveLoading" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
        <div class="outer-delete">
            <u class="text-box-fs pointer delete-link" *ngIf="isEdit" (click)="openDeleteCouponModal(deleteCouponModal)">
                {{ isLoading ? "" : ("coupon.delete_coupon" | translate) }}
            </u>
        </div>

    </div>


    <div *ngIf="isLoading" class="loading-height">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>

    <!--    Coupon location selector modal -->
    <ng-template #couponLocationModal let modal>
        <app-custom-modal>
            <ng-container modal-title>
                <span class="location-modal-title">{{ "coupon.apply_to" | translate }}</span>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="closeModal()">
                    <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" />
                </a>
            </ng-container>
            <ng-container modal-body>
                <label class="fw-bold mb-2">{{ isEdit ? ("coupon.selected_customer_and_location" | translate) : hasSubCustomers ? ("coupon.select_customer_and_location" | translate) : ("coupon.select_location" | translate) }}</label>
                <div class="locations-for-coupon" *ngIf="getCustomersLength()">
                    <div class="" id="scroll-bar" [ngClass]="{ 'scroll-wrapper': getCustomersLength() }">
                        <div *ngFor="let customerItem of customers | keyvalue" class="location-item">
                            <h3
                                *ngIf="customerItem.value.locations && hasSubCustomers"
                                (click)="customerItem.value.isExpanded = !customerItem.value.isExpanded"
                                [ngClass]="{ 'expanded-customer': customerItem.value.isExpanded }"
                                class="pointer"
                            >
                                <mat-checkbox class="customer-checkbox" [disabled]="isEdit" [checked]="areAllLocationsSelected(customerItem)" (change)="selectAllLocationsForCustomer(customerItem)" (click)="$event.stopPropagation()">
                                    {{ customerItem.value.name }}
                                </mat-checkbox>
                                <div class="img-div">
                                    <img class="right-aligned" [ngClass]="{ 'arrow-left': !customerItem.value.isExpanded, 'arrow-down': customerItem.value.isExpanded }" src="../../../assets/icons/arrow-down-blue.svg" alt="arrow left" />
                                </div>
                            </h3>
                            <div *ngIf="customerItem.value.isExpanded">
                                <div class="location-object" *ngFor="let location of customerItem.value.locations">
                                    <mat-checkbox class="location-object-checkbox" *ngIf="customerItem.value.locations" [(ngModel)]="location.selected" [disabled]="isEdit">
                                        {{ location.name }}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2" *ngIf="!isEdit">
                    <span class="label-link pe-3 text-box-fs" (click)="selectAllLocations()">{{ "coupon.select_all" | translate }}</span>
                    <span class="label-link text-box-fs" (click)="deselectAllLocations()">{{ "coupon.deselect_all" | translate }}</span>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-flex flex-column align-items-center" *ngIf="!isEdit">
                    <button class="btn btn-secondary mt-3 location-save-btn" (click)="saveSelectedLocations()" style="width: 100%">
                        {{ isLoading ? "" : ("coupon.choose_selection" | translate) }}
                        <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <!-- Coupon happy hour event selector -->
    <ng-template #couponHappyHourModal let modal>
        <app-custom-modal>
            <ng-container modal-title>
                <p class="happy-hour-title">
                    {{ "coupon.timeslot_title" | translate }}
                </p>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="closeModal()">
                    <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" />
                </a>
            </ng-container>
            <ng-container modal-body>
                <div id="calendar"></div>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-flex flex-row align-items-center justify-content-between" *ngIf="isMobile">
                    <button class="btn btn-danger btn-tiny mb-3 mt-3" [disabled]="!selectedEventId" (click)="removeEvent()" style="width: 48%">
                        {{ isLoading ? "" : ("coupon.remove_timeslot" | translate) }}
                        <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                    </button>
                    <button class="btn btn-secondary btn-tiny mb-3 mt-3" (click)="saveSelectedHappyHour()" style="width: 48%">
                        {{ isLoading ? "" : ("coupon.save_timeslots" | translate) }}
                        <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                    </button>
                </div>
                <div class="d-flex flex-column align-items-center happy-hour-save-btn" *ngIf="!isMobile">
                    <button class="btn btn-secondary mt-3" (click)="saveSelectedHappyHour()" style="width: 45%">
                        {{ isLoading ? "" : ("coupon.save_timeslots" | translate) }}
                        <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <!--    Delete coupon modal-->
    <ng-template #deleteCouponModal let modal>
        <app-custom-modal>
            <ng-container modal-title>
                <p class="delete-coupon-title">
                    {{ "coupon.delete_coupon" | translate }}
                </p>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="closeModal()">
                    <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" />
                </a>
            </ng-container>
            <ng-container modal-body>
                <p class="delete-coupon-text">
                    {{ "coupon.are_you_sure" | translate }}
                </p>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-flex flex-column align-items-center delete-coupon-button">
                    <button class="btn btn-danger mb-3 mt-3" (click)="deleteCoupon()" style="width: 100%" tabindex="-1">
                        {{ isDeleteLoading ? "" : ("coupon.delete_now" | translate) }}
                        <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isDeleteLoading"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>
</div>
