import { Injectable } from '@angular/core';
import { Coupon, CreateCouponParams, CustomerDisplay } from '../../../../../shared_models/coupon';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, RequestTypes } from '../../helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class CouponService {
    private couponGlobalToggled$ = new BehaviorSubject<any>({});
    private notifyUsersToggled$ = new BehaviorSubject<any>({});
    private givenUpfrontToggled$ = new BehaviorSubject<any>({});
    private selectedCouponSubject: BehaviorSubject<Coupon | null> = new BehaviorSubject<Coupon | null>(null);
    processedEvents: { day: string; events: any[] }[];

    constructor(private httpService: HttpService) {}

    selectedCoupon$: Observable<Coupon | null> = this.selectedCouponSubject.asObservable();

    setSelectedCoupon(coupon: Coupon | null): void {
        this.selectedCouponSubject.next(coupon);
    }

    setCouponGlobalToggled(toggled: boolean) {
        this.couponGlobalToggled$.next(toggled);
    }

    setNotifyUsersToggled(toggled: boolean) {
        this.notifyUsersToggled$.next(toggled);
    }

    setGivenUpfrontToggled(toggled: boolean) {
        this.givenUpfrontToggled$.next(toggled);
    }

    async getCustomersForDisplay() {
        return this.httpService.dynamicHttp<{customersForCoupon: Record<string, CustomerDisplay>, locationAmount: number, coupon: Coupon}>('api_account/get_coupon_locations', RequestTypes.GET);
    }

    async getSpecificCouponData(couponKey: string): Promise<{ coupon: Coupon; customersForCoupon: Record<string, CustomerDisplay>; locationAmount: number }> {
        return this.httpService.dynamicHttp(`api_account/get_coupon_locations/specific?couponKey=${couponKey}`, RequestTypes.GET);
    }

    async getCoupons(): Promise<Coupon[]> {
        return this.httpService.dynamicHttp('api_account/coupon', RequestTypes.GET);
    }

    async createCoupons(couponParams: CreateCouponParams): Promise<Coupon> {
        return this.httpService.dynamicHttp('api_account/coupon', RequestTypes.POST, { body: couponParams });
    }

    async archiveCoupons(couponId: string, global: boolean) {
        return this.httpService.dynamicHttp('api_account/coupon/archive', RequestTypes.PUT, { body: { couponId, global } });
    }

    editCoupons(couponEditParams: Coupon) {
        return this.httpService.dynamicHttp('api_account/coupon/edit', RequestTypes.PUT, { body: { couponEditParams } });
    }
}
