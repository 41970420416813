<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ (externalAccount ? "account.update_bank_account" : "account.add_new_bank_account") | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
    </ng-container>
    <ng-container modal-body>
        <form tabindex="0" [formGroup]="externalAccountForm" [hidden]="showConfirmConvert">
            <div class="modal-padding-wrapper">
                <!-- name -->
                <div class="input-group-wrapper-feedback">
                    <app-custom-tool-tip forInput="true" label="{{ 'account.name' | translate }}" description="{{ 'account.name_description' | translate }}"></app-custom-tool-tip>
                    <input autocomplete="new-name" name="name" class="form-control" type="text" formControlName="name" />

                    <div class="feedback-container">
                        @if (externalAccountFormSubmitted && name.invalid) {
                            <div class="error-msg" *ngIf="name.errors.required">
                                {{ "misc.name_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="name.errors.maxlength">
                                {{ "misc.name_max" | translate }} {{ name.errors.maxlength.requiredLength }}
                                {{ "misc.characters_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="name.errors.minlength">
                                {{ "misc.name_min" | translate }} {{ name.errors.minlength.requiredLength }}
                                {{ "misc.characters_long" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- currency -->
                <div class="input-group-wrapper">
                    <app-custom-tool-tip forInput="true" label="{{ 'account.currency' | translate }}" description="{{ 'account.currency_description' | translate }}"></app-custom-tool-tip>

                    <select class="form-select" formControlName="currency" (change)="onCurrencySelect()">
                        <option selected disabled value="null">{{ "account.choose_currency" | translate }}</option>
                        <option [ngValue]="currency" *ngFor="let currency of currencies">
                            {{ currency.toUpperCase() }}
                        </option>
                    </select>
                </div>

                <!-- country -->
                <div class="input-group-wrapper">
                    <app-custom-tool-tip forInput="true" label="{{ 'account.country' | translate }}" description="{{ 'account.country_description' | translate }}"></app-custom-tool-tip>
                    <select class="form-select" formControlName="country" (change)="onCountrySelect()">
                        <option selected disabled value="null">{{ "account.choose_country" | translate }}</option>
                        <option [ngValue]="countryItem.code" *ngFor="let countryItem of countries">
                            {{ countryItem.name }}
                        </option>
                    </select>
                </div>

                <!--  ROUTING NUMBER (FOR NON IBAN)  -->
                <div
                    class="input-group-wrapper-feedback"
                    *ngIf="
                        bankAccountRequirements && country.value !== 'UZ' && (bankAccountRequirements.routing_type === 'routing' || bankAccountRequirements.routing_type === 'sort_code' || bankAccountRequirements.routing_type === 'swift')
                    "
                >
                    <app-custom-tool-tip
                        forInput="true"
                        label="{{ getTranslateBankAccount(bankAccountRequirements && bankAccountRequirements.routing_type ? bankAccountRequirements.routing_type : '') }}"
                        description="{{ 'account.routing_number_description' | translate }}"
                    ></app-custom-tool-tip>
                    <input autocomplete="new-routing_number" name="routing_number" class="form-control" type="text" formControlName="routing_number" />
                    <div class="feedback-container">
                        @if (externalAccountFormSubmitted && routing_number.invalid) {
                            <div class="error-msg" *ngIf="account_number.errors.required">
                                {{ "misc.routing_number_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- Bank For Uzbekistan -->

                @if (country.value === "UZ") {
                    <!-- UZ requires two selects fields, one for bank name, and then a branch for that bank-->
                    <div class="input-group-wrapper-feedback">
                        <app-custom-tool-tip forInput="true" label="{{ 'account.bank' | translate }}" description="{{ 'account.bank_description' | translate }}"></app-custom-tool-tip>
                        <ng-select [placeholder]="'account.choose_bank' | translate" (clear)="bankCleared()" (change)="onBankSelect()" formControlName="bank_name">
                            @for (bank of bankData; track bank.name) {
                                <ng-option [value]="bank.name">{{ bank.name }} ({{ bank.code }})</ng-option>
                            }
                        </ng-select>
                        <div class="feedback-container">
                            @if (externalAccountFormSubmitted && bank_name.invalid) {
                                <div class="error-msg" *ngIf="bank_name.errors.required">
                                    {{ "account.bank_required" | translate }}
                                </div>
                            }
                        </div>
                    </div>

                    <div class="input-group-wrapper-feedback">
                        <app-custom-tool-tip forInput="true" label="{{ 'account.branch' | translate }}" description="{{ 'account.branch_description' | translate }}"></app-custom-tool-tip>
                        <ng-select [placeholder]="branch_code.disabled ? ('account.choose_bank_first' | translate) : ('account.choose_branch' | translate)" formControlName="branch_code">
                            <ng-option *ngFor="let branch of branches[bank_name.value]" [value]="branch.code">{{ branch.name }} ({{ branch.code }})</ng-option>
                        </ng-select>
                        <div class="feedback-container">
                            @if (externalAccountFormSubmitted && branch_code.invalid) {
                                <div class="error-msg" *ngIf="branch_code.errors.required">
                                    {{ "account.branch_required" | translate }}
                                </div>
                            }
                        </div>
                    </div>
                }

                <!--  BANK CODE NUMBER (FOR COLOMBIA)  -->
                <div class="input-group-wrapper-feedback" *ngIf="bankAccountRequirements && bankAccountRequirements.routing_type === 'bank_code' && country.value === 'CO'">
                    <app-custom-tool-tip forInput="true" label="{{ getTranslateBankAccount(bankAccountRequirements.routing_type) }}" description="{{ 'account.routing_number_description' | translate }}"></app-custom-tool-tip>
                    <ng-select [placeholder]="'account.choose_bank' | translate" formControlName="routing_number">
                        <ng-option *ngFor="let bank of bankData" [value]="bank.code">{{ bank.name }}</ng-option>
                    </ng-select>
                    <div class="feedback-container">
                        @if (externalAccountFormSubmitted && routing_number.invalid) {
                            <div class="error-msg" *ngIf="account_number.errors.required">
                                {{ "misc.routing_number_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- IBAN (ACCOUNT NUMBER) -->
                <div class="input-group-wrapper-feedback">
                    <app-custom-tool-tip
                        forInput="true"
                        label="{{ getTranslateBankAccount(bankAccountRequirements && bankAccountRequirements.account_type ? bankAccountRequirements.account_type : '') }}"
                        description="{{ getTranslateBankAccount(bankAccountRequirements && bankAccountRequirements.account_type ? bankAccountRequirements.account_type : ' ', true) }}"
                    ></app-custom-tool-tip>
                    <input autocomplete="new-account_number" name="account_number" class="form-control" type="text" formControlName="account_number" />
                    <div class="input-description" *ngIf="externalAccount && externalAccount.bank_name">
                        {{ externalAccount.bank_name }}
                    </div>
                    <div class="feedback-container">
                        @if (externalAccountFormSubmitted && account_number.invalid) {
                            <div class="error-msg" *ngIf="account_number.errors.required">
                                {{ "misc.account_number_required" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- ACCOUNT TYPE -->
                <div class="input-group-wrapper" *ngIf="country.value === 'CO'">
                    <app-custom-tool-tip forInput="true" label="{{ 'account.account_type' | translate }}" description="{{ 'account.account_type_description' | translate }}"></app-custom-tool-tip>
                    <select class="form-select" formControlName="account_type">
                        <option selected disabled value="null">{{ "account.choose_account_type" | translate }}</option>
                        <option value="checking">
                            {{ "account.checking" | translate }}
                        </option>
                        <option value="savings">
                            {{ "account.savings" | translate }}
                        </option>
                    </select>
                </div>

                <div class="input-group-wrapper m-0" *ngIf="!isInitialAccount">
                    <!-- hide it if it is the initial account also it will be set to true in the form init -->
                    <div class="checkbox" (click)="is_default.disabled ? disabledStateInfo() : null">
                        <input type="checkbox" id="customCheck99" name="defaultAccount" formControlName="is_default" />
                        <label for="customCheck99">{{ "account.set_default" | translate }}</label>
                    </div>
                    <!-- <div class="error-msg" *ngIf="formSubmitted && !defaultAccountChecked">
                        {{'account.defaultAccount_to_accept' | translate}}
                    </div> -->
                </div>
            </div>
        </form>
        <div *ngIf="showConfirmConvert">
            {{ "account.account_manual_reminder" | translate }}
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-grid gap-2 top-margin">
            <button class="btn btn-secondary" [disabled]="formDisabled" (click)="submitForm(willConvertFromAutoToManual)" *ngIf="!showConfirmConvert">
                {{ formProcessing ? "" : ((externalAccount ? "account.update_bank" : "account.add_bank") | translate) }}
                <loading-indicator *ngIf="formProcessing" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
            <button class="btn btn-secondary" [disabled]="formDisabled" (click)="submitForm(false)" *ngIf="showConfirmConvert">
                {{ formProcessing ? "" : ("sign-in.ok" | translate) }}
                <loading-indicator *ngIf="formProcessing" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
        <div class="d-flex flex-column align-items-center mt-3" *ngIf="isEdit">
            <a tabindex="-1" (click)="delete()" [disabled]="formDisabled">{{ "account.delete_bank" | translate }} </a>
        </div>
    </ng-container>
</app-custom-modal>
