import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SupportedLanguages } from '../../../../constants/supportedLanguages'
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgFor } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

interface Language {
    language_code: string,
    name: string,
}

@Component({
    selector: 'app-language-picker-modal',
    templateUrl: './language-picker-modal.component.html',
    styleUrls: ['./language-picker-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgFor, FormsModule, TranslateModule]
})
export class LanguagePickerModalComponent {

    supportedLanguages: Language[] = SupportedLanguages;
    languageToUse: Language = this.supportedLanguages.filter((language) => language.language_code === this.translate.currentLang)[0];

    constructor(
        public translate: TranslateService,
        private localStorageService: LocalStorageService,
        private modal: NgbModal,
        private toast: ToastrService
    ) { }

    languageSelect(event: any) {
        this.languageToUse = this.supportedLanguages.filter((language) => language.name === event.target.value)[0];
    }

    useLanguage() {
        this.translate.use(this.languageToUse.language_code);
        this.localStorageService.setLanguage(this.languageToUse);
        this.modal.dismissAll('success');
        this.toast.success(this.translate.instant('account.language_saved'), this.translate.instant("misc.success"))
    }
}