import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {LoadingComponent} from "../../../loading/loading.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {HelperService} from "../../../../services/helper/helper.service";
import {DiscountCouponService} from "../../../../services/discount-coupon/discount-coupon.service";
import {ActivatedRoute} from "@angular/router";
import {TableHeaderOptions} from "@shared_models/aw-components/tableHeaderOptions";
import {AntonHealthTableSettings} from "@services/local-storage/local-storage.models";
import {LocalStorageService} from "@services/local-storage/local-storage.service";
import {AwCheckboxComponent} from "@components/misc/aw-checkbox/aw-checkbox.component";
import {of} from "rxjs";
import { AccountService } from '@services/account/account.service';
import { PreferencesService } from '@services/account/preferences.service';

@Component({
    selector: 'app-anton-health-table-settings',
    standalone: true,
    imports: [
        LoadingComponent,
        NgIf,
        ReactiveFormsModule,
        TranslateModule,
        AwCheckboxComponent,
        NgForOf,
        NgClass
    ],
    templateUrl: './anton-health-table-settings.component.html',
    styleUrl: './anton-health-table-settings.component.scss'
})
export class AntonHealthTableSettingsComponent implements OnInit {
    @Input() popupAnchor: 'right' | 'left' = 'right'
    @Input() allTableHeaders: TableHeaderOptions[];
    @Input() loadingDevices: boolean;
    @Output() submitEmitter = new EventEmitter<void>();
    settingTableHeaders: boolean = false;
    tableHeaderSettings: AntonHealthTableSettings;

    nonEditableColumnKeys: string[] = ['', 'status', 'incidents_note_count', 'serial'];

    isExpanded: boolean = false;

    constructor(
        protected translate: TranslateService,
        private localStorageService: LocalStorageService,
        private elementRef: ElementRef,
        private preferencesService: PreferencesService
    ) {
    }

    ngOnInit() {
        this.tableHeaderSettings = {
            columns: this.allTableHeaders.map(header => {
                return {
                    key: header.sortKey,
                    title: header.title,
                    show: true
                }
            })
        }

        const existingPrefs = this.localStorageService.getAntonHealthTableSettings();

        if(existingPrefs) {
            this.tableHeaderSettings.columns.forEach((tableHeader) => {
                if (tableHeader.key) {
                    tableHeader.show = existingPrefs.columns.find((singleTableHeader) => singleTableHeader.key === tableHeader.key).show
                }
            })
        }

    }

    checkAll(): void {
        const selectAll = !this.areAllSelectedCheck();

        if (selectAll) {
            this.tableHeaderSettings.columns.forEach(column => {
                column.show = true
            })
        } else {
            this.tableHeaderSettings.columns.forEach(column => {
                if(!this.nonEditableColumnKeys.includes(column.key)) {
                    column.show = false
                }
            })
        }
    }

    areAllSelectedCheck(): boolean {
        for (const device of this.tableHeaderSettings.columns) {
            if (!device.show) {
                return false;
            }
        }
        return true;
    }

    togglePopdown(): void {
        this.isExpanded = !this.isExpanded;
    }

    @HostListener('document:mousedown', ['$event'])
    clickOut(event: MouseEvent): void {
        if (this.isExpanded && !this.elementRef.nativeElement.contains(event.target)) {
            this.togglePopdown()
        }
    }

    async submit(): Promise<void> {
        this.settingTableHeaders = true
        this.localStorageService.setAntonHealthTableSettings(this.tableHeaderSettings);
        await this.preferencesService.setPreferences(this.tableHeaderSettings);
        this.submitEmitter.emit()
        this.settingTableHeaders = false
    }

    protected readonly of = of;
}
