import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DashboardUser } from 'shared_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { User, UserPaymentMethod } from 'shared_models/user-management';
import { UsersService } from 'src/app/services/users/users.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Location } from 'shared_models/location';
import { FilterOption, FilterType } from '../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort } from '../../../../shared_models/search-params/FilterSortParams';
import { environment } from '../../../environments/environment';
import { PaginateFetchCacheService } from '../../services/paginate-fetch-cache/paginate-fetch-cache.service';
import { TableHeaderOptions } from '../../../../shared_models/aw-components/tableHeaderOptions';
import _ from 'lodash';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { CreateUserModalComponent } from './user-modals/create-user-modal/create-user-modal.component';
import { AwHoverIconComponent } from '../misc/aw-hover-icon/aw-hover-icon.component';
import { AwTableComponent } from '../misc/aw-table/aw-table.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { AwFilterButtonComponent } from '../misc/aw-filter-button/aw-filter-button.component';
import { PageLayoutComponent } from '../misc/aw-page-layout/page-layout.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: true,
    imports: [PageLayoutComponent, AwFilterButtonComponent, NgIf, LoadingComponent, AwTableComponent, NgFor, AwHoverIconComponent, MatPaginator, CreateUserModalComponent, TranslateModule, PaginatePipe]
})
export class UsersComponent implements OnInit {
    user: DashboardUser;
    isMobile = false;
    users: User[] = [];
    allLocations: { id: string; name: string }[] = [];
    locations: { id: string; name: string }[] = [
        {
            id: 'all',
            name: this.translate.instant('transactions.all_locations')
        }
    ];
    customers: { id: string; name: string }[] = [];
    customersObj: Record<string, string> = {};
    alluid: string[] = [];
    subCustomersSub: Subscription;
    rates: Record<string, number>;
    operator$: Observable<boolean> = this.authService.isOperator;
    isOperator$: Observable<boolean>;
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'name',
            title: this.translate.instant('users.name'),
            width: '20%'
        },
        {
            sortKey: 'phone_number',
            title: this.translate.instant('users.phone_no'),
            width: '15%'
        },
        {
            sortKey: 'platform',
            title: this.translate.instant('users.platform'),
            width: '9%'
        },
        {
            sortKey: 'payment_method',
            title: this.translate.instant('users.payment_method'),
            width: '15%'
        },
        {
            sortKey: 'total_spend_sum',
            title: this.translate.instant('users.total_spend'),
            width: '12%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'payments',
            title: this.translate.instant('users.payments'),
            width: '9%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'refunds_sum',
            title: this.translate.instant('users.refunds'),
            width: '10%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('users.created'),
            width: '10%',
            alignment: 'right'
        }
    ];

    filterOptions: FilterOption[] = [
        {
            key: 'owner_uid',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: this.translate.instant('misc.customer'),
            selectOptions: []
        },
        {
            key: 'location_id',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: this.translate.instant('misc.location'),
            selectOptions: []
        }
    ];

    // Loading states
    loadingUsers = true;
    initialLoading = false;

    // Necessary inputs for FE pagination
    pageNumber = 0;
    pageSizeOptions = [15, 50, 250];
    pageSize = this.pageSizeOptions[0];
    usersCount = 0;

    params: FilterSortParams = {
        filter: {},
        sortBy: { key: 'created', order: 'desc' },
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
    };

    constructor(
        public modal: NgbModal,
        private breakpoint: BreakpointObserver,
        public helperService: HelperService,
        private router: Router,
        private usersService: UsersService,
        private translate: TranslateService,
        private locationService: LocationService,
        private authService: AuthService,
        private customerService: CustomerService,
        private pfcService: PaginateFetchCacheService<User>
    ) {
        this.breakpoint.observe(['(max-width: 768px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.initialLoading = true;
        this.user = this.helperService.getUser();
        this.setSubscriptions();
        this.rates = (await this.helperService.getCurrencyConversionRates()).rates;
        await this.getLocations('all'); // fetch all locations for customer
        this.isOperator$ = this.authService.isOperator;
        await this.loadUsers(true);

        const sub = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (!event.url.includes('users/user') && !event.url.includes('users')) {
                    this.usersService.removeUsers();
                    sub.unsubscribe();
                }
            }
        });
        this.assignSelectValuesToFilterButton();
    }

    async loadUsers(initLoading?: boolean): Promise<void> {
        this.loadingUsers = true;
        const paramsAsString: string = JSON.stringify(this.params);
        const endpoint = `${environment.baseUrl}/api_users?params=${paramsAsString}`;
        const cacheExists = this.pfcService.cacheExists(endpoint, this.params);
        this.pfcService
            .getData(endpoint, this.params)
            .then(response => {
                this.users = response.pages[this.pageNumber];
                this.usersCount = response.totalItems;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.initialLoading = false;
                this.loadingUsers = false;

                if (initLoading && cacheExists) {
                    this.updateCachedData(endpoint);
                }
            });
    }

    private updateCachedData(endpoint: string) {
        this.pfcService.updateDataIfDifferentFromCache(endpoint, this.params).then(fetchedData => {
            if (!_.isEqual(fetchedData.pages[this.pageNumber], this.users)) {
                this.users = fetchedData.pages[this.pageNumber];
            }
        });
    }

    getTitleForWallet(payment_method: UserPaymentMethod['wallet']) {
        if (payment_method.includes('_')) {
            const split = payment_method.split('_');
            const first_letter = split[0].charAt(0).toUpperCase();
            const second_letter = split[1].charAt(0).toUpperCase();
            return `${first_letter}${split[0].slice(1)} ${second_letter}${split[1].slice(1)}`;
        } else {
            return payment_method.charAt(0).toUpperCase() + payment_method.slice(1);
        }
    }

    async getLocations(val: string | 'all') {
        this.locations = []; // clear array if already defined with values.
        let uids: string[] = [];

        if (val === 'all') {
            uids = this.alluid.map(uid => (uid !== this.user.uid ? `${uid}_operated_by_${this.user.uid}` : uid));
            uids.push(this.user.uid);
        } else {
            uids.push(val !== this.user.uid ? `${val}_operated_by_${this.user.uid}` : val);
        }

        this.locations = await this.locationService.getAllLocationNames(this.user.uid);
        this.locations.sort((a, b) => (a && b && a.name && b.name ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : null)); // sorting in alphabetical order to easier scan the list in the dropdown for filter search.

        // const locations: Record<string, Location> = await this.locationService.readMultipleLocations(uids);

        // if (locations && Object.keys(locations)) {
        //     // Should only run once, used for users location name
        //     if (this.allLocations.length === 0) {
        //         for (const key in locations) {
        //             this.allLocations.push({ id: key, name: locations[key].name });
        //         }
        //     }

        //     for (const key in locations) {
        //         this.locations.push({ id: key, name: locations[key].name });
        //     }
        //     this.locations.sort((a, b) => (a && b && a.name && b.name ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : null)); // sorting in alphabetical order to easier scan the list in the dropdown for filter search.
        // }
    }

    handleUserCreated() {
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_users`);
        this.loadUsers();
    }

    setSubscriptions() {
        this.subCustomersSub = this.customerService
            .readSubCustomers(this.user.uid)
            .snapshotChanges()
            .subscribe(action => {
                this.subCustomersSub.unsubscribe();
                for (const key in action.payload.val()) {
                    if (action.payload.val()[key]) {
                        this.customers.push({ id: key, name: action.payload.val()[key].name });
                        this.customersObj[key] = action.payload.val()[key].name;
                        this.alluid.push(key);
                    }
                }
                this.customers.sort((a, b) => (a && b && a.name && b.name ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : null)); // sorting in alphabetical order to easier scan the list in the dropdown for filter search.
            });
    }

    getUserPaymentDisplayText(userObj: any): string {
        if (userObj.user_role === 'test') {
            return 'users.user.test';
        } else if (userObj.payment_method && userObj.payment_method.type === 'no_payment_type') {
            return 'misc.none';
        } else if (userObj.payment_method && userObj.payment_method.last4) {
            return this.convertCardNumber(userObj.payment_method);
        } else if (userObj.payment_method && userObj.payment_method.type) {
            return 'users.payment_methods.type.' + userObj.payment_method.type.toLowerCase();
        } else {
            return 'misc.none';
        }
    }

    // region HTML helper functions
    convertCardNumber(pm: UserPaymentMethod) {
        if (pm.type) {
            return `• • • • ${pm.last4}`;
        }
    }

    openModal(modalRef: TemplateRef<NgbModalRef>) {
        this.modal
            .open(modalRef, {})
            .result.then(result => {
                console.log('Closed successfully');
            })
            .catch(error => {
                console.log('Dismiss');
            });
    }

    async openUser(userObj: User) {
        this.router.navigate([`users/${userObj.id}`], { queryParams: { owner_uid: userObj.owner_uid } });
    }

    getLocationName(locationKey: string) {
        return this.allLocations.find(location => location.id === locationKey)?.name;
    }
    //endregion

    // Filter Functions
    assignSelectValuesToFilterButton() {
        console.log('location', this.locations);

        this.filterOptions[0].selectOptions = [{ value: this.user.uid, label: this.translate.instant('transactions.my_account') }, ...this.customers.map(customer => ({ value: customer.id, label: customer.name }))];
        this.filterOptions[1].selectOptions = this.locations.map(location => {
            return { value: location.id, label: location.name };
        });
    }

    //region Table Functions
    async catchFilterChanged(event: any) {
        if (JSON.stringify(this.params.filter) !== JSON.stringify(event)) {
            this.pageNumber = 0;
        }
        this.params.filter = event;
        this.params.pageNumber = this.pageNumber;
        await this.loadUsers();
        console.log('Params: ', this.params);
    }

    async handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
        this.params.pageSize = this.pageSize;
        this.params.pageNumber = this.pageNumber;
        await this.loadUsers();
    }

    async catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.params.sortBy = event;
        this.params.pageNumber = this.pageNumber;
        await this.loadUsers();
    }
    //endregion
}
