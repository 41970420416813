import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { SubCustomer, subCustomerListItem } from 'shared_models/sub-customer';
import { DashboardUser } from 'shared_models/dashboard-user';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { OperatorService } from "../../services/operator/operator.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { ControlledUser } from 'shared_models/controlled-user';
import { AuthService } from "../../services/auth/auth.service";
import * as Claims from 'shared_models/claims'
import { Router, RouterLink } from "@angular/router";
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatPaginator, LoadingComponent, AsyncPipe, TranslateModule, PaginatePipe]
})
export class CustomersComponent implements OnInit, OnDestroy {

    role$: Observable<Claims.Roles>
    isMobile: boolean;
    loading = true;
    user: DashboardUser | ControlledUser;
    subCustomersArr: subCustomerListItem[] = [];
    listHackArr: subCustomerListItem[] = [];
    subCustomerBalanceStats: Record<string, subCustomerListItem> = {};
    listHack: boolean;
    pageSize = 10;
    pageNumber = 0;
    pageSizeOptions = [10, 50, 100];
    sortByName: 'asc' | 'dsc' = 'asc';
    sortByPhone: 'asc' | 'dsc' = 'asc';
    sortByStatus: 'asc' | 'dsc' = 'dsc';
    sortByTotal: 'asc' | 'dsc' = 'dsc';
    sortByOperatorShare: 'asc' | 'dsc' = 'dsc';
    sortByCustomerShare: 'asc' | 'dsc' = 'dsc';
    sortByCreated: 'dsc' | 'asc' = 'asc';
    sortSelected: 'name' | 'phone' | 'status' | 'total' | 'operatorShare' | 'customerShare' | 'created';
    currency: string = this.helperService.getUser().settings.currency;
    filterPeriod = 1;
    filterType: 'day' | 'month' = 'month';
    allDevicesActiveOnSubCustomer: Record<string, boolean>;
    fromDate: number;
    toDate: number;

    private ngUnsubscribe = new Subject<void>()
    constructor(
        public authService: AuthService, // used in html
        private customerService: CustomerService,
        private translate: TranslateService,
        private helperService: HelperService,
        private operatorService: OperatorService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,

    ) {
        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        })
        operatorService.selectedFrom$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            this.fromDate = value;
            this.datesChanged();
        })
        operatorService.selectedTo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            this.toDate = value;
            this.datesChanged();
        })
    }

    async datesChanged(): Promise<void> {
        // setting them to zero to avoid multipled when changing dates
        for (const ele of this.subCustomersArr) {
            ele.total = 0;
            ele.operatorShare = 0;
            ele.customerShare = 0;
        }

        for (const subCust of this.subCustomersArr) {
            await this.balanceStats(subCust.key); // lazy load the balance stats to the array
        }
    }

    async balanceStats(key: string): Promise<void> {
        return new Promise((resolve) => {
            const subSubBalance = this.customerService.readSubCustomerBalance(
                this.user.uid, key,
                String(this.fromDate),
                String(this.toDate)
            ).snapshotChanges().subscribe((subCustomerBalanceSnapAction: SnapshotAction<{ operator_amount: number, total: number }>[]) => {
                subSubBalance.unsubscribe();

                for (const item of subCustomerBalanceSnapAction) {
                    const balance: { total: number, operator_amount: number } =
                        subCustomerBalanceSnapAction.length ?
                            item.payload.val() :
                            { total: 0, operator_amount: 0 };
                    balance.operator_amount = balance.operator_amount ? balance.operator_amount : 0;
                    for (let i = 0; i < this.subCustomersArr.length; i++) {
                        if (this.subCustomersArr[i].key === key) {
                            this.subCustomersArr[i].total += balance.total;
                            this.subCustomersArr[i].operatorShare += balance.operator_amount ? balance.operator_amount : 0;
                            this.subCustomersArr[i].customerShare += balance.total - balance.operator_amount;
                        } else {
                            this.subCustomerBalanceStats[i] = {
                                total: balance.total,
                                operatorShare: balance.operator_amount,
                                customerShare: balance.total - balance.operator_amount,
                                ...this.subCustomerBalanceStats[i]
                            };
                        }
                    }
                }
                return resolve();
            });
        })
    }

    async getSubCustomers(): Promise<Record<string, boolean>> {
        return new Promise((resolve) => {
            const subCustomersSub = this.customerService.readSubCustomers(this.user.uid).snapshotChanges().subscribe(async (subCustomersSnap: SnapshotAction<Record<string, SubCustomer>>) => {
                subCustomersSub.unsubscribe(); // we do not wanna track, else the list can on large customers be reloading constantly.

                if (!subCustomersSnap.payload.exists()) {
                    this.loading = false;
                    return;
                }

                const uids: Record<string, boolean> = {};

                for (const subCust in subCustomersSnap.payload.val()) {
                    const key = subCust;
                    uids[key] = true;
                    this.subCustomerBalanceStats[key] = {
                        total: 0, operatorShare: 0, customerShare: 0,
                        ...{
                            key,
                            phone: subCustomersSnap.payload.val()[key].phone ? subCustomersSnap.payload.val()[key].phone : '',
                            currency: subCustomersSnap.payload.val()[key].currency
                        },
                        account_id: subCustomersSnap.payload.val()[key].account_id,
                        created: subCustomersSnap.payload.val()[key].created,
                        name: subCustomersSnap.payload.val()[key].name,
                        status: subCustomersSnap.payload.val()[key].status
                    };

                }

                return resolve(uids);

            });
        })
    }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.role$ = this.authService.getRole;
        const uids: Record<string, boolean> = await this.getSubCustomers();
        this.subCustomersArr = Object.values(this.subCustomerBalanceStats); // set the array that is used in the HTML table
        this.sortCreated(true); // sorting the array based on created timestamp
        this.loading = false; // displaying the table, data will lazy load below
        this.datesChanged();
        this.allDevicesActiveOnSubCustomer = (await this.customerService.getSubCustomersDevicesState({ uids })).uids; // lazy load the device statuses
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.breakpointObserver.ngOnDestroy();
    }

    status(subAuthUid: string, status: string): { status: string, statusLabel: string, className: string } {
        // status states: invited = 'invited', seen = 'seen', complete = 'complete', restricted_soon = 'restricted_soon', restricted = 'restricted', pending = 'pending'
        return {
            status:
                status === 'complete' && !this.allDevicesActiveOnSubCustomer[subAuthUid]
                    ? 'inactive_devices'
                    : status,
            statusLabel: this.translate.instant(
                `customers.${status === 'complete' && !this.allDevicesActiveOnSubCustomer[subAuthUid]
                    ? 'inactive_devices'
                    : status}`
            ),
            className: status === 'complete' ? !this.allDevicesActiveOnSubCustomer[subAuthUid]
                ? 'inactive_devices' : 'complete' : 'incomplete'
        };
    }

    total(total: number, customerCurrency: string): string {
        return this.helperService.localizeNumberWithCurrency(total ? total / 100 : 0, 2, 2, customerCurrency);
    }

    operatorShare(operatorShare: number, customerCurrency: string): string {
        return this.helperService.localizeNumberWithCurrency(operatorShare ? operatorShare / 100 : 0, 2, 2, customerCurrency);
    }

    customerShare(customerShare: number, customerCurrency: string): string {
        return this.helperService.localizeNumberWithCurrency(customerShare ? customerShare / 100 : 0, 2, 2, customerCurrency);
    }

    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;

        // work-around to an issue that only occours when there is 11 elements in the list, then page 2 (index 1), can not be resolved.
        // The fix takes the last element (index 10) and replaces the entire array with that single element, when the user navigates back
        // to first page, the original array is inserted again.
        if (!this.listHack && e.length === 11) {
            this.listHackArr = this.subCustomersArr.slice();
            this.subCustomersArr = []
            this.subCustomersArr.push(this.listHackArr[this.listHackArr.length - 1])
            this.listHack = true;
        } else if (this.listHack) {
            this.subCustomersArr = [];
            this.subCustomersArr = this.listHackArr;
            this.listHackArr = [];
            this.listHack = false;
        }
    }

    genericSorter(sortOption: string, sortProperty: string, skipSwitchingOrder?: boolean) {
        const order: 'asc' | 'dsc' = skipSwitchingOrder ? this[sortOption] === 'dsc' ? 'asc' : 'dsc' : this[sortOption];

        if (order === 'dsc') {
            // Is this the fix?
            // this[sortOption] = skipSwitchingOrder ?? 'asc';
            // 
            if (!skipSwitchingOrder) this[sortOption] = 'asc';
            this.subCustomersArr = this.subCustomersArr.slice().sort((a, b) => { // using slice method to get a copy of the original array
                if (isNaN(a[sortProperty])) { // sorting strings
                    if (sortProperty === 'status') {
                        // specific rule for status sort, because "inactive_devices" is a psudo state that is not part of the model, but only given on the fly by the function status
                        const bStr: string = this.status(b.key, b.status).status;
                        const aStr: string = this.status(a.key, a.status).status;
                        return bStr.localeCompare(aStr, 'en', { numeric: true, sensitivity: 'base' });
                    }

                    return b[sortProperty].localeCompare(a[sortProperty], 'en', { numeric: true, sensitivity: 'base' });
                }

                // sorting numbers
                return b[sortProperty] - a[sortProperty];
            });
        } else {
            if (!skipSwitchingOrder) this[sortOption] = 'dsc';

            this.subCustomersArr = this.subCustomersArr.slice().sort((a, b) => { // using slice method to get a copy of the original array
                if (isNaN(a[sortProperty])) { // sorting strings
                    if (sortProperty === 'status') {
                        // specific rule for status sort, because "inactive_devices" is a psudo state that is not part of the model, but only given on the fly by the function status
                        const aStr: string = this.status(a.key, a.status).status;
                        const bStr: string = this.status(b.key, b.status).status;
                        return aStr.localeCompare(bStr, 'en', { numeric: true, sensitivity: 'base' });
                    }
                    return a[sortProperty].localeCompare(b[sortProperty], 'en', { numeric: true, sensitivity: 'base' });
                }

                // sorting numbers
                return a[sortProperty] - b[sortProperty];
            });
        }
    }

    sortName(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByName', 'name', skipSwitchingOrder);
        this.sortSelected = 'name';
    }

    sortPhone(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByPhone', 'phone', skipSwitchingOrder);
        this.sortSelected = 'phone';
    }

    sortStatus(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByStatus', 'status', skipSwitchingOrder);
        this.sortSelected = 'status';
    }

    sortTotal(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByTotal', 'total', skipSwitchingOrder);
        this.sortSelected = 'total';
    }

    sortOperatorShare(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByOperatorShare', 'operatorShare', skipSwitchingOrder);
        this.sortSelected = 'operatorShare';
    }

    sortCustomerShare(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByCustomerShare', 'customerShare', skipSwitchingOrder);
        this.sortSelected = 'customerShare';
    }

    sortCreated(skipSwitchingOrder?: boolean) {
        this.genericSorter('sortByCreated', 'created', skipSwitchingOrder);
        this.sortSelected = 'created';
    }
}