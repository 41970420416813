// Azerbaijani
export default {
  az: {
    days: ["Bazar", "Bazar ertəsi", "Çərşənbə axşamı", "Çərşənbə", "Cümə axşamı", "Cümə", "Şənbə"],
    daysShort: ["B.", "B.e", "Ç.a", "Ç.", "C.a", "C.", "Ş."],
    daysMin: ["B.", "B.e", "Ç.a", "Ç.", "C.a", "C.", "Ş."],
    months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "İyun", "İyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"],
    monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "İyun", "İyul", "Avq", "Sen", "Okt", "Noy", "Dek"],
    today: "Bu gün",
    weekStart: 1,
    clear: "Təmizlə",
    monthsTitle: 'Aylar'
  }
};
