import { Sort } from "../../../../shared_models/search-params/FilterSortParams";
import * as _ from 'lodash';
import { User } from "@shared_models/user-management";
import { BillingDevice } from "@shared_models/billing/billing";
import { Coupon } from "@shared_models/coupon";

export function paginateData<T>(data: T[], pageNumber: number, pageSize: number, range: number): Record<number, T[]> {
    // Calculate the total number of pages
    const totalPages: number = Math.ceil(data.length / pageSize);

    // Calculate the page numbers of the pages to fetch
    let pagesToFetch: number[] = Array.from({ length: 2 * range + 1 }, (_, i: number) => pageNumber - range + i).map(page => {
        if (page < 0) return totalPages + page;
        if (page >= totalPages) return page - totalPages;
        return page;
    });

    // Create a Record to store the pages of data
    const pages: Record<number, T[]> = {};

    // Loop through the page numbers of the pages to fetch
    for (const page of pagesToFetch) {
        // Calculate the start and end indices of the items on this page in the fetched data
        const start: number = page * pageSize;
        const end: number = start + pageSize;

        // Add the items on this page to the Record
        pages[page] = data.slice(start, end);
    }

    return pages;
}

export function sortBillingList<T extends BillingDevice>(billingList: T[], sortParams: Sort): T[] {
    if (sortParams.key === 'activated') {
        sortParams.key = 'period_start'
    }
    if (sortParams.key === 'renewable') {
        return _.orderBy(billingList, ['renewable',
            item => {
                if (item.period_end === undefined) {
                    return sortParams.order === 'asc' ? Infinity : -Infinity;
                }
                return Number(item.period_end);
            }
        ], [sortParams.order, sortParams.order]);
    }
    return _.orderBy(billingList, [(item) => {
        const value = _.get(item, sortParams.key);
        return typeof value === 'string' ? value.toLowerCase() : value;
    }], [sortParams.order]);
}

export function sortCouponList<T extends Coupon>(coupons: T[], sortParams: Sort): T[] {
    if (sortParams.key === 'expire') {
        return _.orderBy(coupons, [
            item => {
                if (item.duration && item.duration.to) {
                    return Number(item.duration.to);
                } else {
                    return sortParams.order === 'asc' ? Infinity : -Infinity;
                }
            }
        ], [sortParams.order]);
    }
    else if (sortParams.key === 'entries') {
        console.log('entries');
        return _.orderBy(coupons, [
            // First sorting by count
            item => Number(item.entries.users.count),
            // Then sorting by limit if it exists, otherwise use Infinity
            item => item.entries.users.limit !== undefined ? Number(item.entries.users.limit) : Infinity
        ], [sortParams.order, sortParams.order]);
    }

    return _.orderBy(coupons, [(item) => {
        const value = _.get(item, sortParams.key);
        return typeof value === 'string' ? value.toLowerCase() : value;
    }], [sortParams.order]);
}

export function sortUsers(users: User[], sortParams: Sort): User[] {
    let iteratees: any[] = [];
    let orders: any[] = [];

    if (sortParams.key === 'created' || sortParams.key === 'total_spend_sum' || sortParams.key === 'refunds_sum' || sortParams.key === 'payments') {
        iteratees.push(sortParams.key);
        orders.push(sortParams.order);
    } else if (sortParams.key === 'payment_method') {
        return _.orderBy(users, [
            user => {
                if (!('payment_method' in user)) {
                    return sortParams.order === 'asc' ? '\uffff' : '\u0000';
                }
                let value = user.payment_method || { type: 'no_payment_type', last4: '0000' };
                return value.type ? value.type.toLowerCase() : '';
            },
            user => {
                if (!('payment_method' in user)) return '\uffff';
                let value = user.payment_method || { type: 'no_payment_type', last4: '0000' };
                return value.last4;
            }
        ], [sortParams.order, sortParams.order]);
    } else {
        iteratees.push(user => {
            const value = user[sortParams.key];
            // Ensure missing or undefined values are sorted to the bottom
            if (value === undefined || value === null || value === '' || value === '-') {
                return sortParams.order === 'asc' ? '\uffff' : '\u0000';
            }
            return typeof value === 'string' ? value.toLowerCase() : value;
        });
        orders.push(sortParams.order);
    }

    return _.orderBy(users, iteratees, orders);
}