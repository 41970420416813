/**
 * Lithuanian translation for bootstrap-datepicker
 * Šarūnas Gliebus <ssharunas@yahoo.co.uk>
 */

export default {
  lt: {
    days: ["Sekmadienis", "Pirmadienis", "Antradienis", "Trečiadienis", "Ketvirtadienis", "Penktadienis", "Šeštadienis"],
    daysShort: ["S", "Pr", "A", "T", "K", "Pn", "Š"],
    daysMin: ["Sk", "Pr", "An", "Tr", "Ke", "Pn", "Št"],
    months: ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"],
    monthsShort: ["Sau", "Vas", "Kov", "Bal", "Geg", "Bir", "Lie", "Rugp", "Rugs", "Spa", "Lap", "Gru"],
    today: "Šiandien",
    monthsTitle: "Mėnesiai",
    clear: "Išvalyti",
    weekStart: 1,
    format: "yyyy-mm-dd"
  }
};
