import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from "@angular/router";
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from "../../services/auth/auth.service";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../environments/environment';
import { HelperService } from 'src/app/services/helper/helper.service';
import { DashboardUser } from 'shared_models/dashboard-user';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { MaintenanceService } from 'src/app/maintenance.service';
import { Title } from '@angular/platform-browser';
import { ResetPasswordService } from "../../services/reset-password/reset-password.service";
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf } from '@angular/common';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    standalone: true,
    imports: [LottieComponent, NgIf, FormsModule, ReactiveFormsModule, LoadingComponent, RouterLink, CustomModalComponent, TranslateModule]
})

export class SignInComponent implements OnInit {
    options: AnimationOptions = { // https://www.npmjs.com/package/ngx-lottie
        path: '../../assets/animations/AW_waves.json',
    };
    showLoading = false
    formSubmitted = false
    signInForm: UntypedFormGroup;
    version: string = environment.VERSION
    backAt: string;
    sign_in: string;
    isUnderMaitenance: boolean;
    showLoad = true;
    resetPWLoading: boolean;

    supportPhone: {
        phoneNumber: string,
        telLink: string
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastrService,
        private translate: TranslateService,
        private helperService: HelperService,
        private maintenanceService: MaintenanceService,
        private title: Title,
        private resetPasswordService: ResetPasswordService
    ) { }

    async ngOnInit() {
        this.title.setTitle('Sign in')
        await this.handleSignIn()
    }

    async handleSignIn() {
        await this.waitForMaintenance();
        if (this.maintenanceService.getIsUnderMaintenance()) {
            this.isUnderMaitenance = true;
            this.backAt = this.maintenanceService.getBackAt();
            this.showLoad = false;
        } else {
            this.checkForToast();
            this.checkForIncorrectUser();
            this.showLoad = false;
            this.sign_in = this.translate.instant("sign-in.sign_in");
            const prefillMail: string = this.checkForPrefillMail();
            const user: DashboardUser = this.helperService.getUser(true);
            if (user) this.router.navigate([`/overview`]);
            this.supportPhone = environment.supportPhone
            this.signInForm = this.formBuilder.group({
                email: new UntypedFormControl(prefillMail ? prefillMail : null, [Validators.required]),
                password: new UntypedFormControl(null, [Validators.required])
            })
        }
    }

    checkForIncorrectUser() {
        if (!this.helperService.compareUserToLocalStorage("user")) {
            localStorage.removeItem('user');
        }
    }

    waitForMaintenance(): Promise<void> {
        return new Promise<void>((resolve) => {
            const checkBoolean = () => {
                if (typeof this.maintenanceService.getIsUnderMaintenance() !== 'undefined') {
                    resolve();
                } else {
                    setTimeout(checkBoolean, 100); // Check again after 100ms
                }
            };
            checkBoolean();
        });
    }

    checkForPrefillMail(): string {
        const prefillMail: string = localStorage.getItem('prefillMail');
        if (prefillMail) {
            localStorage.removeItem('prefillMail');
        }
        return prefillMail
    }
    checkForToast(): void {
        const diplayToast: string = this.localStorageService.getPasswordSet();
        if (diplayToast) {
            localStorage.removeItem('prefillMail');
            this.helperService.defaultHtmlToast(this.translate.instant("customers-set-password.password_set"), this.translate.instant("customers-set-password.please_sign_in"), 'Info', { timeOut: 10000 })
        }
    }

    get password() { return this.signInForm.get('password'); }
    get email() { return this.signInForm.get('email'); }

    signIn(signInForm: UntypedFormGroup) {
        this.formSubmitted = true
        if (signInForm.valid) {
            this.showLoading = true
            if (this.toast && this.toast.toasts) {
                for (const toast of this.toast.toasts) {
                    this.toast.remove(toast.toastId);
                }
            }
            const emailLowerCased = signInForm.value["email"].replace(/\s/g, '').toLowerCase()
            this.authService.signIn(emailLowerCased, signInForm.value["password"]).catch(() => {
                this.toast.warning(this.translate.instant('sign-in.err_proccess_log_in'), this.translate.instant('misc.attention'), {
                    timeOut: 999999
                });
                this.showLoading = false
            });
        }
    }

    resetPassword() {
        this.resetPWLoading = true
        const emailElement: HTMLInputElement = (document.getElementById('forgot-password') as HTMLInputElement)
        if (!emailElement.value) {
            this.toast.warning(this.translate.instant('sign-in.type_in_email'), this.translate.instant('misc.attention'))
            document.getElementById("forgot-password").focus();
            this.resetPWLoading = false
        } else if (!emailElement.checkValidity()) {
            this.toast.warning(this.translate.instant('sign-in.invalid_email_typed'), this.translate.instant('misc.attention'))
            document.getElementById("forgot-password").focus();
            this.resetPWLoading = false
        } else {
            this.resetPasswordService.sendResetPasswordMail(emailElement.value.replace(/\s/g, '').toLowerCase()).then(() => {
                this.toast.success(this.translate.instant("sign-in.reset_link_msg"), this.translate.instant('misc.success'), {
                    timeOut: 3 * 60 * 1000
                });
                this.resetPWLoading = false
                this.modalService.dismissAll();
            }).catch(() => {
                this.toast.success(this.translate.instant("sign-in.reset_link_msg"), this.translate.instant('misc.success'), {
                    timeOut: 3 * 60 * 1000
                });
                this.resetPWLoading = false
                this.modalService.dismissAll();
            });
        }

    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
        }
        this.modalService.open(
            modal,
            modalOptions
        ).result.then((event: any) => {
        }).catch((event: any) => {
        });
    }
}


