import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Coupon, DistributeType } from 'shared_models/coupon';
import { AllowedUpdateFields } from 'shared_models/user-management';
import { HelperService } from 'src/app/services/helper/helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-edit-coupons-modal',
    templateUrl: './edit-coupons-modal.component.html',
    styleUrls: ['./edit-coupons-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgFor, NgIf, NgStyle, LoadingComponent, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class EditCouponsModalComponent implements OnInit {
    @Input() userId: string;
    @Input() appliedCoupons: Coupon[];
    @Input() availableCoupons: Coupon[];
    @Output() onStateChange: EventEmitter<AllowedUpdateFields> = new EventEmitter<AllowedUpdateFields>();
    @ViewChild('select') select: ElementRef

    couponsToExclude: Coupon[] = [];
    couponsToApply: Coupon[] = [];
    shownAvailableCoupons: Coupon[] = [];
    shownAppliedCoupons: Coupon[] = [];
    defaultSelect = true;
    isExcluding = false;
    isApplying = false;
    currentIndex = -1;
    couponForm: FormGroup;
    hasSubmitted = false;
    isShowingInfo = false;
    shouldBeGrey = true;

    constructor(
        private usersService: UsersService,
        private modal: NgbModal,
        private translate: TranslateService,
        private helperService: HelperService,
    ) { }

    ngOnInit(): void {
        //Remove coupons from available coupons that are already applied
        this.availableCoupons = this.availableCoupons.filter(coupon => !this.appliedCoupons.find(c => c.key === coupon.key));
        this.shownAvailableCoupons = Object.assign([], this.availableCoupons);
        this.shownAppliedCoupons = Object.assign([], this.appliedCoupons);
        this.couponForm = new FormGroup({
            selected_coupon: new FormControl(null)
        });
    }

    getTerms(coupon: Coupon): string {
        return this.helperService.parseTranslationArray(coupon.description);
    }

    isFocused() {
        this.shouldBeGrey = false;
    }

    isBlurred() {
        this.shouldBeGrey = true;
    }

    closeModal() {
        this.modal.dismissAll();
    }

    excludeCoupon(coupon: Coupon, i: number) {
        this.availableCoupons.push(coupon);
        this.isExcluding = true;
        this.currentIndex = i;
        this.couponsToExclude.push(coupon);
        this.shownAvailableCoupons.push(coupon);
        this.shownAppliedCoupons = this.shownAppliedCoupons.filter(c => c.key !== coupon.key);
        this.isExcluding = false;
        this.currentIndex = -1;
        this.selected_coupon.setValue(null);
    }

    showInfo(shouldShow: boolean, index: number) {
        this.isShowingInfo = shouldShow;

        if (shouldShow) {
            this.currentIndex = index;
        } else {
            this.currentIndex = -1;
        }

        console.log(this.isShowingInfo, this.currentIndex)
    }

    updateAppliedCoupons(event: any) {
        const couponId: string = event.target.value.split(' ')[1];
        this.isApplying = true;
        const coupon: Coupon = this.availableCoupons.find(c => c.key === couponId);

        if (this.couponsToExclude.find(c => c.key === couponId)) { // check if the coupon was excluded in the same modal session (wihout save happened)
            // if so we simply wanna remove it from the exclude list
            const refreshedExcludes = this.couponsToExclude.filter(c => c.key !== couponId); // remove the coupon we just added, because it is already on the user
            this.couponsToExclude = refreshedExcludes; // set the array
        } else {
            this.couponsToApply.push(coupon); // else add it (normal user interaction flow)
        }

        this.shownAppliedCoupons.push(coupon);
        this.shownAvailableCoupons = this.shownAvailableCoupons.filter(c => c.key !== coupon.key);
        this.isApplying = false;
        this.selected_coupon.setValue(null);
    }

    isForAll(coupon: Coupon) {
        return coupon.distribute_type === DistributeType.ALL;
    }

    async applyChanges() {
        this.hasSubmitted = true;

        if (this.couponsToExclude.length)
            await this.usersService.excludeCoupons(this.couponsToExclude, this.userId, this.couponsToExclude[0].owner_uid);

        if (this.couponsToApply.length) {
            try {
                await this.usersService.applyCoupons(this.couponsToApply, this.userId, this.couponsToApply[0].owner_uid);
            } catch (error) {
                this.helperService.defaultHtmlToast('', this.translate.instant('users.user.coupon_limit_reached'), 'Error');
                this.hasSubmitted = false
                return
            }
        }

        const toUpdate: AllowedUpdateFields = {
            applied_coupons: this.shownAppliedCoupons.sort((a, b) => b.creation_date - a.creation_date),
            available_coupons: this.shownAvailableCoupons
        }
        this.onStateChange.emit(toUpdate);

        this.hasSubmitted = false;
        this.helperService.defaultHtmlToast('', this.translate.instant('users.user.coupons.coupons_updated_succesfully'), 'Success');
        this.closeModal();
    }

    get selected_coupon() { return this.couponForm.get('selected_coupon'); }

}