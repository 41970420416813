/* Used for exports, where it needs the default FilterSortParams for searching for w/e it needs to have in excel,
 * but also needs the mail and language_code for sending the mail and translating column names.
 */
export interface ExportParams extends FilterSortParams {
    mail: string;
    language_code: string;
}

export interface FilterSortParams {
    specificSearch?: {key: string, value: string};
    exportToMail?: string,
    filter: Record<string, any>;
    sortBy: Sort;
    pageNumber: number;
    pageSize: number;
    startAfter?: {
        key: string;
        value: string;
    };
    endBefore?: {
        key: string;
        value: string;
    }
    action?: 'next' | 'prev' | 'first' | 'last';
}

export interface Sort {
    key: string;
    order: 'asc' | 'desc';
}

export interface LocationItem {
    id: string;
    name: string;
    daily_starts: number;
    revenue: number;
    created: string;
}

export interface LocationResponse extends FilterSortParams {
    pages: Record<number, LocationItem[]>; // key is page number
    totalPages: number;
    totalItems: number;
}

export enum ListIdentifiersEnum {
    created = 'created',
    revenue = 'revenue',
    daily_starts = 'daily_starts',
    name = 'name'
}
