<div class="container mb-5">
    <div class="row cont">
        <div class="px-4 pt-3 pb-3 col-12 box topBox">
            <form autocomplete="off" class="col-12 row" [formGroup]="supportForm" (submit)="submit()">
                <div class="input-group-wrapper mb-1 col-12">
                    <h5>{{'support.support' | translate}}</h5>
                </div>
                <div class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.function' | translate}}</label>
                    <select class="form-select" (change)="actionSelected()" (focus)="removeOptions()"
                        (mouseenter)="removeOptions()" formControlName="action">
                        <option [ngValue]="null" disabled hidden="true">{{'support.choose_function' | translate}}
                        </option>
                        <optgroup label={{group}} *ngFor="let group of actionGroups">
                            <option [ngValue]="action" *ngFor="let action of actions"
                                [className]="action.groupKey !== group ? 'removeOption' : ''">{{action.label}}</option>
                        </optgroup>
                    </select>
                    <div class="input-description"
                        *ngIf="(action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')">
                        {{'support.functions_description.switch_serial_on_sub' | translate}}
                    </div>
                    <div *ngIf="formSubmitted && action.invalid">
                        <div class="error-msg" *ngIf="action.errors.required">
                            {{'support.function_required' | translate}}
                        </div>
                    </div>
                </div>

                <div class="input-group-wrapper col-12 m-0"></div>

                <!-- Payment ID -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_transactions/refund'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.payment_id' | translate}}</label>
                    <input autocomplete="off" name="paymentId" class="form-control" type="text"
                        formControlName="paymentId">
                    <div *ngIf="formSubmitted && paymentId.invalid">
                        <div class="error-msg" *ngIf="paymentId.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- Customer uid  -->
                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/edit_ark_settings')
                || (action && action.value && action.value.endpoint === 'api_support/convert_to_manual')
                || (action && action.value && action.value.endpoint === 'api_support/set_claims')
                || (action && action.value && action.value.endpoint === 'api_support/delete_sub')
                || (action && action.value && action.value.endpoint === 'api_account/delete_and_reject')
                || (action && action.value && action.value.endpoint === 'api_support/update_agreement_type')
                || (action && action.value && action.value.endpoint === 'api_support/check_available_for_payout')
                || (action && action.value && action.value.endpoint === 'api_support/create_manual_payout')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.cus_uid' | translate}}</label>
                    <input autocomplete="off" name="customerUid" class="form-control" type="text"
                        formControlName="customerUid">
                    <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/delete_sub')"
                        class="input-description">{{'support.uid_should_be_combined' | translate}}</div>
                    <div *ngIf="formSubmitted && customerUid.invalid">
                        <div class="error-msg" *ngIf="customerUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- Phone or user_id -->
                <div *ngIf="
                        (action && action.value && action.value.endpoint === 'api_support/toggle_test_user') 
                        || (action && action.value && action.value.endpoint === 'api_support/set_trusted_device')
                        || (action && action.value && action.value.endpoint === 'api_transactions/refund')
                        || (action && action.value && action.value.endpoint === 'api_support/wallet_settings')
                        || (action && action.value && action.value.endpoint === 'api_support/refund_wallet')
                        " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.phone_user_uid' | translate}}</label>
                    <input autocomplete="off" name="phoneNumberOrUid" class="form-control" type="text"
                        formControlName="phoneNumberOrUid">
                    <div *ngIf="formSubmitted && phoneNumberOrUid.invalid">
                        <div class="error-msg" *ngIf="phoneNumberOrUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- awPoKey -->
                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/create_manual_payout')
                        " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.awPoKey' | translate}}</label>
                    <input autocomplete="off" name="awPoKey" class="form-control" type="text" formControlName="awPoKey">
                    <div *ngIf="formSubmitted && awPoKey.invalid">
                        <div class="error-msg" *ngIf="awPoKey.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/delete_sub')"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.sub_id' | translate}}</label>
                    <input autocomplete="off" name="subId" class="form-control" type="text" formControlName="subId">
                    <div class="input-description">{{'support.sub_id_description' | translate}}</div>
                    <div *ngIf="formSubmitted && subId.invalid">
                        <div class="error-msg" *ngIf="subId.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/set_claims'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <!-- OBS: "claim" translates to "Roles" in UI -->
                    <label class="mb-1">{{'support.select_role' | translate}}</label>
                    <select class="form-select form-control" formControlName="claim">
                        <option selected disabled [ngValue]="null">{{'support.select_role' | translate}}
                        </option>
                        <option [ngValue]="'operator'">{{'support.operator' | translate}}</option>
                        <option [ngValue]="'developer'">{{'support.developer' | translate}}</option>
                        <option [ngValue]="'admin'">{{'support.admin' | translate}}</option>
                        <option [ngValue]="'stripe_region'">{{'support.stripe_region' | translate}}</option>
                        <option [ngValue]="'lookup'">{{'support.lookup_roles' | translate}}</option>
                    </select>
                    <div *ngIf="formSubmitted && claim.invalid">
                        <div class="error-msg" *ngIf="claim.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/reset_password')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.email_phone' | translate}}</label>
                    <input autocomplete="off" name="email" class="form-control" type="text" formControlName="email">
                    <div *ngIf="formSubmitted && email.invalid">
                        <div class="error-msg" *ngIf="email.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/reset_password')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.set_new_password' | translate}}</label>
                    <input autocomplete="off" name="newPassword" class="form-control" type="text"
                        formControlName="newPassword">
                    <div *ngIf="formSubmitted && newPassword.invalid">
                        <div class="error-msg" *ngIf="newPassword.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>


                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/convert_customer_to_sub_customer')"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.cus_uid_to_sub_cus' | translate}}</label>
                    <input autocomplete="off" name="customerUid" class="form-control" type="text"
                        formControlName="customerUid">
                    <div *ngIf="formSubmitted && customerUid.invalid">
                        <div class="error-msg" *ngIf="customerUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>
                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/convert_customer_to_sub_customer')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.operator_uid' | translate}}</label>
                    <input autocomplete="off" name="operatorUid" class="form-control" type="text"
                        formControlName="operatorUid">
                    <div *ngIf="formSubmitted && operatorUid.invalid">
                        <div class="error-msg" *ngIf="operatorUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/replace_serial')
                || (action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')
                " class="input-group-wrapper col-12 m-0"></div>

                <div *ngIf="
                (action && action.value && action.value.endpoint === 'api_support/replace_serial')
                || (action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.old_serial' | translate}}</label>
                    <input autocomplete="off" name="guidOld" class="form-control" type="text" formControlName="guidOld">
                    <div *ngIf="formSubmitted && guidOld.invalid">
                        <div class="error-msg" *ngIf="guidOld.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="relayNumber.enabled && (
                    (action && action.value && action.value.endpoint === 'api_support/replace_serial') ||
                    (action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')
                )" class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.relay_number' | translate}}</label>
                    <select class="form-select form-control" formControlName="relayNumber">
                        <option selected disabled [ngValue]="null">{{'support.select_relay_number' | translate}}
                        </option>
                        <option [ngValue]="1">1</option>
                        <option [ngValue]="2">2</option>
                    </select>
                    <div *ngIf="formSubmitted && relayNumber.invalid">
                        <div class="error-msg" *ngIf="relayNumber.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="
                (action && action.value && action.value.endpoint === 'api_support/replace_serial') ||
                (action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')
                " class="input-group-wrapper col-12 m-0"></div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/replace_serial')
                || (action && action.value && action.value.endpoint === 'api_support/switch_serial_on_sub')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.new_serial' | translate}}</label>
                    <input autocomplete="off" name="guidNew" class="form-control" type="text" formControlName="guidNew">
                    <div *ngIf="formSubmitted && guidNew.invalid">
                        <div class="error-msg" *ngIf="guidNew.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/move_location')
                || (action && action.value && action.value.endpoint === 'api_support/edit_ark_settings')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">Location ID</label>
                    <input autocomplete="off" name="locationId" class="form-control" type="text"
                        formControlName="locationId">
                    <div *ngIf="formSubmitted && locationId.invalid">
                        <div class="error-msg" *ngIf="locationId.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/move_location'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.customer_uid_from' | translate}}</label>
                    <input autocomplete="off" name="fromCustomerUid" class="form-control" type="text"
                        formControlName="fromCustomerUid">
                    <div *ngIf="formSubmitted && fromCustomerUid.invalid">
                        <div class="error-msg" *ngIf="fromCustomerUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/move_location'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.customer_uid_to' | translate}}</label>
                    <input autocomplete="off" name="toCustomerUid" class="form-control" type="text"
                        formControlName="toCustomerUid">
                    <div *ngIf="formSubmitted && toCustomerUid.invalid">
                        <div class="error-msg" *ngIf="toCustomerUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- User uid -->
                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/delete_user') 
                || (action && action.value && action.value.endpoint === 'api_support/subscription')
                || (action && action.value && action.value.endpoint === 'api_support/get_checkout_session')
                || (action && action.value && action.value.endpoint === 'api_support/create_checkout_link')
                || (action && action.value && action.value.endpoint === 'api_support/remote_close_app')
                || (action && action.value && action.value.endpoint === 'api_support/verify_app_user')
                || (action && action.value && action.value.endpoint === 'api_support/audit_wallet')
                || (action && action.value && action.value.endpoint === 'api_support/user_admin_claims')
                " class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.user_uid' | translate}}</label>
                    <input autocomplete="off" name="userUid" class="form-control" type="text" formControlName="userUid">
                    <div *ngIf="formSubmitted && userUid.invalid">
                        <div class="error-msg" *ngIf="userUid.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- Stripe Region -->
                <div *ngIf="(action && action.value && action.value.endpoint === 'api_transactions/refund') 
                || (action && action.value && action.value.endpoint === 'api_support/get_checkout_session')
                || (action && action.value && action.value.endpoint === 'api_support/delete_user')
                || (action && action.value && action.value.endpoint === 'api_support/create_checkout_link')
                " class="support-tool-refund-reason input-group-wrapper">
                    <label class="mb-1">Stripe Region</label>
                    <div class="refund-reason-inner">
                        <div class="refund-reason-select">
                            <select class="form-select" formControlName="stripeRegion">
                                <option value="eu" selected>EU</option>
                                <option value="us">US</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_transactions/refund'"
                    class="support-tool-refund-reason input-group-wrapper">
                    <label class="mb-1">{{'transactions.refund_tooltip_reason' | translate}}</label>
                    <div class="refund-reason-inner">
                        <div class="refund-reason-select">
                            <select class="form-select" formControlName="refundReason">
                                <option value="duplicate">{{'transactions.refund_reason_duplicate' | translate}}
                                </option>
                                <option value="fraudulent">{{'transactions.refund_reason_fraudulent' | translate}}
                                </option>
                                <option value="requested_by_customer">{{'transactions.refund_reason_requested' |
                                    translate}}</option>
                                <option value="other">{{'transactions.refund_reason_other' | translate}}</option>
                            </select>
                        </div>
                        <div *ngIf="supportForm.get('refundReason').value === 'other'">
                            <input class="form-control refund-reason-other" formControlName="otherReason"
                                placeholder="{{'transactions.refund_enter_reason' | translate}}">
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/toggle_subscription_state'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.location_path' | translate}}</label>
                    <input autocomplete="off" name="locationPath" class="form-control" type="text"
                        formControlName="locationPath">
                    <div *ngIf="formSubmitted && locationPath.invalid">
                        <div class="error-msg" *ngIf="locationPath.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="(action && action.value && action.value.endpoint === 'api_support/toggle_maintenance') || (action && action.value && action.value.endpoint === 'api_support/release_device' )"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.loca_device_path' | translate}}</label>
                    <input autocomplete="off" name="devicePath" class="form-control" type="text"
                        formControlName="devicePath">
                    <div *ngIf="formSubmitted && devicePath.invalid">
                        <div class="error-msg" *ngIf="devicePath.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <!-- Fingerprint -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/set_trusted_device'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.fingerprint' | translate}}</label>
                    <input autocomplete="off" name="deviceFingerprint" class="form-control" type="text"
                        formControlName="deviceFingerprint">
                    <div *ngIf="formSubmitted && deviceFingerprint.invalid">
                        <div class="error-msg" *ngIf="deviceFingerprint.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>
                <!-- RefillAmount -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/wallet_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.refill_amount' | translate}}</label>
                    <input autocomplete="off" name="refillAmount" class="form-control" type="number"
                        formControlName="refillAmount">
                </div>
                <!-- Threshold -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/wallet_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.threshold' | translate}}</label>
                    <input autocomplete="off" name="threshold" class="form-control" type="number"
                        formControlName="threshold">
                </div>
                <!-- Auto refill -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/wallet_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.autoRefill' | translate}}</label>
                    <div class="refund-reason-inner">
                        <div class="refund-reason-select">
                            <select class="form-select" formControlName="autoRefill">
                                <option value="true">true</option>
                                <option value="false">false</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- With Fee -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/refund_wallet'"
                    class="support-tool-refund-reason input-group-wrapper">
                    <label class="mb-1">With Fee</label>
                    <div class="refund-reason-inner">
                        <div class="refund-reason-select">
                            <select class="form-select" formControlName="withFee">
                                <option value="true" selected>true</option>
                                <option value="false">false</option>
                            </select>
                        </div>
                    </div>
                </div>



                <!-- Agreement Type  -->
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/update_agreement_type'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.agreement_type' | translate}}</label>
                    <select class="form-select form-control" formControlName="agreementType">
                        <option selected disabled [ngValue]="null">{{'support.choose_agreement_type' | translate}}
                        </option>
                        <option [ngValue]="'life'">{{'support.life' | translate}}</option>
                        <option [ngValue]="'regular'">{{'support.regular' | translate}}</option>
                    </select>
                    <div *ngIf="formSubmitted && agreementType.invalid">
                        <div class="error-msg" *ngIf="agreementType.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/update_agreement_type' && agreementType.value === 'regular'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.min_fee' | translate}}</label>
                    <input autocomplete="off" name="minFee" class="form-control" type="number" formControlName="minFee">
                    <div class="input-description">{{'support.currency_display' | translate}}</div>
                    <div *ngIf="formSubmitted && minFee.invalid">
                        <div class="error-msg" *ngIf="minFee.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/update_agreement_type' && agreementType.value === 'regular'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.percent_fee' | translate}}</label>
                    <input autocomplete="off" name="percentFee" class="form-control" type="number"
                        formControlName="percentFee" step="0.01" max="1" min="0">
                    <div class="input-description">{{'support.fee_range' | translate}}</div>
                    <div *ngIf="formSubmitted && percentFee.invalid">
                        <div class="error-msg" *ngIf="percentFee.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>


                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.ark_mode' | translate}}</label>
                    <select class="form-select form-control" formControlName="arkMode">
                        <option selected disabled [ngValue]="null">{{'support.choose_ark' | translate}}</option>
                        <option [ngValue]="'hybrid'">{{'support.hybrid' | translate}}</option>
                        <option [ngValue]="'on'">{{'support.on' | translate}}</option>
                        <option [ngValue]="'off'">{{'support.off' | translate}}</option>
                    </select>
                    <div *ngIf="formSubmitted && arkMode.invalid">
                        <div class="error-msg" *ngIf="arkMode.errors.required">
                            {{'support.required' | translate}}
                        </div>
                    </div>
                </div>


                <!-- TIER 1 -->
                <div class="input-group-wrapper col-12 mb-0 mt-2"
                    *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'">
                    <h6>Tier 1</h6>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.number_starts' | translate}}</label>
                    <input autocomplete="off" name="tier1UpTo" class="form-control" type="number"
                        formControlName="tier1UpTo" step="1" max="999999999" min="1">
                    <div *ngIf="formSubmitted && tier1UpTo.invalid">
                        <div class="error-msg" *ngIf="tier1UpTo.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier1UpTo.errors.pattern">
                            {{'support.positive_int' | translate}}
                        </div>
                    </div>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.total_price' | translate}}</label>
                    <input autocomplete="off" name="tier1TotalAmount" class="form-control" type="number"
                        formControlName="tier1TotalAmount" step="0.01" max="999999999" min="0.01">
                    <div class="input-description"
                        [ngStyle]="priceValidation(tier1TotalAmount.value && tier1UpTo.value && tier1TotalAmount.value/tier1UpTo.value ? tier1TotalAmount.value/tier1UpTo.value : null).style">
                        {{'Price per start: '}}{{tier1TotalAmount.value && tier1UpTo.value &&
                        tier1TotalAmount.value/tier1UpTo.value ? tier1TotalAmount.value/tier1UpTo.value : 'N/A'}}</div>
                    <div *ngIf="formSubmitted && tier1TotalAmount.invalid">
                        <div class="error-msg" *ngIf="tier1TotalAmount.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier1TotalAmount.errors.pattern">
                            {{'support.nubmer_should_be' | translate}}
                        </div>
                        <div class="error-msg"
                            [hidden]="priceValidation(tier1TotalAmount.value && tier1UpTo.value && tier1TotalAmount.value/tier1UpTo.value ? tier1TotalAmount.value/tier1UpTo.value : null).valid"
                            *ngIf="tier1TotalAmount.errors.tier1UnwantedDecimals">
                            Only "good looking" and max decimals is allowed.
                        </div>
                    </div>
                </div>

                <!-- TIER 2 -->
                <div class="input-group-wrapper col-12 mb-0"
                    *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'">
                    <h6>Tier 2</h6>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.number_starts' | translate}}</label>
                    <input autocomplete="off" name="tier2UpTo" class="form-control" type="number"
                        formControlName="tier2UpTo" step="1" max="999999999" min="1">
                    <div *ngIf="formSubmitted && tier2UpTo.invalid">
                        <div class="error-msg" *ngIf="tier2UpTo.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier2UpTo.errors.tier2UpToNotRespected">
                            {{'support.tier_two_more' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier2UpTo.errors.pattern">
                            {{'support.positive_int' | translate}}
                        </div>
                    </div>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.total_price' | translate}}</label>
                    <input autocomplete="off" name="tier2TotalAmount" class="form-control" type="number"
                        formControlName="tier2TotalAmount" step="0.01" max="999999999" min="0.01">
                    <div class="input-description"
                        [ngStyle]="priceValidation(tier2TotalAmount.value && tier2UpTo.value && tier2TotalAmount.value/tier2UpTo.value ? tier2TotalAmount.value/tier2UpTo.value : null).style">
                        {{'Price per start: '}}{{tier2TotalAmount.value && tier2UpTo.value &&
                        tier2TotalAmount.value/tier2UpTo.value ? tier2TotalAmount.value/tier2UpTo.value : 'N/A'}}</div>
                    <div *ngIf="formSubmitted && tier2TotalAmount.invalid">
                        <div class="error-msg" *ngIf="tier2TotalAmount.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier2TotalAmount.errors.tier2AmountNotRespected">
                            {{'support.tier_two_more' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier2TotalAmount.errors.pattern">
                            {{'support.positive_int' | translate}}
                        </div>
                        <div class="error-msg"
                            [hidden]="priceValidation(tier2TotalAmount.value && tier2UpTo.value && tier2TotalAmount.value/tier2UpTo.value ? tier2TotalAmount.value/tier2UpTo.value : null).valid"
                            *ngIf="tier2TotalAmount.errors.tier2UnwantedDecimals">
                            Only "good looking" and max decimals is allowed.
                        </div>
                    </div>
                </div>

                <!-- TIER 3 -->
                <div class="input-group-wrapper col-12 mb-0"
                    *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'">
                    <h6>Tier 3</h6>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">Number of starts</label>
                    <input autocomplete="off" name="tier3UpTo" class="form-control" type="number"
                        formControlName="tier3UpTo" step="1" max="999999999" min="1">
                    <div *ngIf="formSubmitted && tier3UpTo.invalid">
                        <div class="error-msg" *ngIf="tier3UpTo.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier3UpTo.errors.tier3UpToNotRespected">
                            {{'support.tier_three_more' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier3UpTo.errors.pattern">
                            {{'support.positive_int' | translate}}
                        </div>
                    </div>
                </div>
                <div *ngIf="action && action.value && action.value.endpoint === 'api_support/edit_ark_settings'"
                    class="input-group-wrapper col-lg-4 col-md-6 col-sm-12">
                    <label class="mb-1">{{'support.total_price' | translate}}</label>
                    <input autocomplete="off" name="tier3TotalAmount" class="form-control" type="number"
                        formControlName="tier3TotalAmount" step="0.01" max="999999999" min="0.01">
                    <div class="input-description"
                        [ngStyle]="priceValidation(tier3TotalAmount.value && tier3UpTo.value && tier3TotalAmount.value/tier3UpTo.value ? tier3TotalAmount.value/tier3UpTo.value : null).style">
                        {{'Price per start: '}}{{tier3TotalAmount.value && tier3UpTo.value &&
                        tier3TotalAmount.value/tier3UpTo.value ? tier3TotalAmount.value/tier3UpTo.value : 'N/A'}}</div>
                    <div *ngIf="formSubmitted && tier3TotalAmount.invalid">
                        <div class="error-msg" *ngIf="tier3TotalAmount.errors.required">
                            {{'support.required' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier3TotalAmount.errors.tier3AmountNotRespected">
                            {{'support.tier_three_more' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="tier3TotalAmount.errors.pattern">
                            {{'support.nubmer_should_be' | translate}}
                        </div>
                        <div class="error-msg"
                            [hidden]="priceValidation(tier3TotalAmount.value && tier3UpTo.value && tier3TotalAmount.value/tier3UpTo.value ? tier3TotalAmount.value/tier3UpTo.value : null).valid"
                            *ngIf="tier3TotalAmount.errors.tier3UnwantedDecimals">
                            Only "good looking" and max decimals is allowed.
                        </div>
                    </div>
                </div>


                <div *ngIf="action && action.value && 
               ( action.value.endpoint ==='api_transactions/refund'
                || action.value.endpoint ==='api_support/remote_close_app'
                || action.value.endpoint ==='api_support/set_claims'
                || action.value.endpoint ==='api_account/delete_and_reject'
                || action.value.endpoint ==='api_support/delete_user'
                || action.value.endpoint ==='api_support/move_location'
                || action.value.endpoint ==='api_support/toggle_test_user'
                || action.value.endpoint ==='api_support/set_trusted_device'
                || action.value.endpoint ==='api_support/wallet_settings'
                || action.value.endpoint ==='api_support/refund_wallet'
                || action.value.endpoint ==='api_support/subscription'
                || action.value.endpoint ==='api_support/convert_to_manual'
                || action.value.endpoint ==='api_support/delete_sub'
                || action.value.endpoint ==='api_support/check_available_for_payout'
                || action.value.endpoint ==='api_support/create_checkout_link'
                || action.value.endpoint ==='api_support/create_manual_payout'
                || action.value.endpoint ==='api_support/get_checkout_session'
                || action.value.endpoint ==='api_support/edit_ark_settings'
                || action.value.endpoint ==='api_support/convert_customer_to_sub_customer'
                || action.value.endpoint ==='api_support/verify_app_user'
                || action.value.endpoint ==='api_support/audit_wallet'
                || action.value.endpoint ==='api_support/update_agreement_type')"
                    class="input-group-wrapper col-12 mb-0">
                    <input type="checkbox" formControlName="dispatchReviewMessage" [checked]="dispatchReviewMessage.value" id="customCheck1" name="review-message">
                    <label for="customCheck1" style="padding:1rem;">Send review SMS (3-5 min delay)</label>
                </div>


            </form>

            <div *ngIf="result && !loading" class="input-group-wrapper col-12 mt-4">
                <label class="mb-1">{{'support.result' | translate}} - {{ status }}</label>
                <textarea name="" cols="90" rows="15" [(ngModel)]="result" readonly class="textarea-result"></textarea>
            </div>

            <div *ngIf="!result && loading" class="input-group-wrapper col-12 mt-4">
                <label class="mb-1">{{'support.loading' | translate}}...</label>
                <div>
                    <img style="width:auto;height:15rem;border: 1px solid #ced4da;" alt="" [src]="gifStr" />
                </div>
            </div>

            <div class="input-group-wrapper col-12 mb-4 mt-4" *ngIf="action && action.value">
                <button class="btn btn-link-dark btn-tiny me-2" tabindex="-1" type="button"
                    (click)="actionSelected()">{{'support.reset' | translate}}</button>
                <button class="btn btn-primary btn-tiny" (click)="submit()" type="submit"
                    [disabled]="loading">{{'support.submit' | translate}}</button>
            </div>

        </div>
    </div>
</div>