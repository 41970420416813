<ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
<div class="page">
    <div class="main-container">
        <img src="../../../assets/logo/aw_logo.svg" alt="Airwallet logo" class="logo" />
        <div class="sign-in-and-signup-container">
            <div class="sign-in-and-signup-card">
                <div class="mb-3 mt-3 p-3 text-center" *ngIf="isUnderMaitenance">
                    <h2>{{ "sign-in.maintenance" | translate }}</h2>
                    <p>{{ "sign-in.back_at" | translate }}</p>
                    <p>{{ backAt }}</p>
                </div>
                <div *ngIf="!isUnderMaitenance && !showLoad">
                    <form [formGroup]="signInForm" (keydown.enter)="signIn(signInForm)" autocomplete="on">
                        <div class="input-group-wrapper">
                            <label for="email-input">{{ "misc.email" | translate }}</label>
                            <input class="form-control" type="email" autocomplete="email" formControlName="email" name="email" id="email-input" />
                            <div class="feedback-container">
                                @if (formSubmitted && email.invalid) {
                                    <div class="error-msg" *ngIf="email.errors.required">
                                        {{ "misc.email_required" | translate }}
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="input-group-wrapper-feedback">
                            <label for="password-input">{{ "misc.password" | translate }}</label>
                            <input name="password" id="password-input" class="form-control" type="password" formControlName="password" />
                            <div class="feedback-container">
                                @if (formSubmitted && password.invalid) {
                                    <div class="error-msg" *ngIf="password.errors.required">
                                        {{ "misc.password_required" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="password.errors.maxlength">
                                        {{ "misc.password_too_long" | translate }}
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button class="sign-up-link m-0" (click)="openModal(forgotPw)" [disabled]="showLoading" tabindex="-1">
                                {{ "sign-in.forgot_password" | translate }}
                            </button>
                        </div>

                        <div class="d-grid gap-2">
                            <button class="btn btn-secondary" (click)="signIn(signInForm)" [disabled]="showLoading">
                                {{ !showLoading ? sign_in : "" }}
                                <loading-indicator *ngIf="showLoading" [size]="'large'" [color]="'white'"></loading-indicator>
                            </button>
                        </div>
                    </form>
                    <p class="mb-5">
                        {{ "sign-in.need_account" | translate }}
                        <button class="sign-up-link" [routerLink]="['/sign-up']" [disabled]="showLoading" tabindex="-1">
                            {{ "sign-in.sign_up" | translate }} {{ "sign-in.here" | translate }}
                        </button>
                    </p>

                    <p class="support-call">
                        {{ "misc.need_help" | translate }}
                        <br />
                        <a href="{{ supportPhone.telLink }}" tabindex="-1">{{ supportPhone.phoneNumber }}</a>
                    </p>
                </div>

                <div *ngIf="showLoad">
                    <loading-indicator [size]="'large'" [color]="'primary'"></loading-indicator>
                </div>
            </div>
        </div>
    </div>
    <p class="version-text">{{ "sign-in.version" | translate }}{{ version }}</p>
</div>

<ng-template #forgotPw let-modal>
    <app-custom-modal>
        <ng-container modal-title>
            <h4 class="modal-title">{{ "sign-in.forgot_password" | translate }}</h4>
        </ng-container>
        <ng-container modal-dismiss>
            <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
        </ng-container>
        <ng-container modal-body>
            <div class="input-group-wrapper">
                <label>{{ "misc.email" | translate }}</label>
                <input id="forgot-password" name="forgot-email" class="form-control" value="{{ signInForm.get('email').value }}" type="email" />
            </div>
        </ng-container>
        <ng-container modal-footer>
            <div class="d-grid gap-2">
                <button class="btn btn-secondary mt-3" (click)="resetPassword()" [disabled]="resetPWLoading">
                    {{ !resetPWLoading ? ("sign-in.reset_pw_request" | translate) : "" }}
                    <loading-indicator *ngIf="resetPWLoading" [size]="'large'" [color]="'white'"></loading-indicator>
                </button>
            </div>
        </ng-container>
    </app-custom-modal>
</ng-template>
