import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShallowAccountDetails } from '../../../../../../shared_models/billing/shallow-account-details';
import { Price } from '../../../../../../shared_models/billing/price';
import { Plan } from '../../../../../../shared_models/billing/plan';
import { BillingDiscount } from '../../../../../../shared_models/billing-discount';
import { DashboardUser } from '../../../../../../shared_models/dashboard-user';
import { BillingService } from '../../../../services/billing/billing.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StripeRegions } from '../../../../../../shared_models/stripe';
import { Billing, BillingDevice, DevicesToSubscribe, DeviceWithDiscountRef } from '../../../../../../shared_models/billing/billing';
import { Details } from '../../../../../../shared_models/details';
import { SnapshotAction } from '@angular/fire/compat/database';
import hash from 'object-hash';
import moment from 'moment';

import { Subscription } from 'rxjs';
import { CustomerService } from '../../../../services/customer/customer.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AwSubscription, CardSubObject } from '../../../../../../shared_models/billing/subscription';
import { LoadingComponent } from '../../../loading/loading.component';
import { AwCheckboxComponent } from '../../../misc/aw-checkbox/aw-checkbox.component';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '../../../../../environments/environment';
import { BillingCardComponent } from '@components/settings/billing/billing-card-modal/billing-card.component';

interface PriceLabel {
    firstPart: string;
    secondPart: string;
    currency: string;
    combined: string;
}

@Component({
    selector: 'app-billing-subscription-modal',
    templateUrl: './billing-subscription-modal.component.html',
    styleUrls: ['./billing-subscription-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, FormsModule, ReactiveFormsModule, MatRadioGroup, MatRadioButton, NgFor, AwCheckboxComponent, LoadingComponent, TranslateModule, KeyValuePipe, BillingCardComponent]
})
export class BillingSubscriptionModalComponent implements OnInit {
    @Input() selectedDevices: BillingDevice[] = [];
    @Output() subscriptionCreated: EventEmitter<{
        devicesForSub: BillingDevice[];
        billedBy: 'card' | 'invoice';
    }> = new EventEmitter<{ devicesForSub: BillingDevice[]; billedBy: 'card' | 'invoice' }>();
    @Output() cardSubCreated: EventEmitter<{
        requiresAction?: boolean;
        paymentIntentId?: string;
        clientSecret?: string;
        subscriptionId?: string;
    }>;
    @Input() globalDiscount: number;

    @ViewChild(BillingCardComponent) billingCardComponent: BillingCardComponent;

    billing: Billing;
    details: Details;
    initLoad: boolean = true;
    country: string = 'Denmark';
    currency: string = 'dkk';
    formSubmitted: boolean;
    subscriptionPending: boolean;
    billingForm: UntypedFormGroup;
    shallowAccountDetails: ShallowAccountDetails;
    invoicePrices: Price[] = [];
    cardPrices: Price[] = [];
    selectedPlan: Plan;
    devices: Record<string, BillingDevice> = {};
    billedBy: 'card' | 'invoice';
    vatTier: number;
    billedByProvided: boolean;
    companyDetails: any;
    coupon: BillingDiscount;
    user: DashboardUser = this.helperService.getUser();
    eanFingerprint: string;
    loadingEanResponse = false;
    deviceDiscountRefs: DeviceWithDiscountRef[] = [];
    insuranceInfoHover: boolean;
    region: StripeRegions;
    useEan = false;
    eanNumberIsValid: boolean;

    discounts: Record<number, number> = {};
    hasCard: boolean = false;

    modalref: NgbModalRef;

    constructor(
        private billingService: BillingService,
        private formBuilder: UntypedFormBuilder,
        protected helperService: HelperService,
        private translate: TranslateService,
        private customerService: CustomerService,
        private router: Router,
        private modalService: NgbModal
    ) {
        this.currency = this.user.settings.currency;
        this.country = this.user.settings.country;
    }

    async ngOnInit() {
        await this.setBilling();
        await this.setDetails();
        this.createBillingForm();
        await this.setStripeCustomerDetails();
        this.setDiscounts();
        this.getPrices();
        await this.billingService.getVatZone().then(tier => (this.vatTier = tier.vat));
        this.onChanges();
        this.initLoad = false;
    }

    async setBilling(): Promise<void> {
        this.billing = await this.billingService.readBilling(this.user.uid); // CommentTag: why is this fetched again when it is alreafy fetched in the parent component?
        this.billing.company_registration_details && this.billing.company_registration_details.billed_by ? (this.billedBy = this.billing.company_registration_details.billed_by) : null;
    }

    setDetails(): Promise<void> {
        return new Promise((resolve, reject) => {
            const detailsSub: Subscription = this.customerService
                .readCustomerDetails(this.user.uid)
                .snapshotChanges()
                .subscribe(detailsSnap => {
                    detailsSub.unsubscribe();
                    this.details = detailsSnap.payload.val();
                    this.region = this.details.stripe_region;
                    if (!this.details.vat_number_details && !this.details.vat_number_not_provided && this.details.business_type !== 'individual') {
                        this.router.navigate([`account/update_vat`]);
                    }
                    resolve();
                }, reject);
        });
    }

    onChanges(): void {
        this.billingForm.get('paymentMethod').valueChanges.subscribe(val => {
            if (val) {
                if (val === 'card') {
                    this.handleEanStateChange({ value: false });
                    this.order_reference.setValidators(null); // reference is hidden and not required if card is selected
                } else {
                    this.order_reference.setValidators([Validators.required, Validators.maxLength(15)]); // reference is shown and required if invoice is selected
                }
                this.order_reference.updateValueAndValidity();
            }
        });
    }

    createBillingForm(): void {
        this.billingForm = this.formBuilder.group({
            ean_number: new UntypedFormControl(null),
            economic_contact_name: new UntypedFormControl(null),
            order_reference: new UntypedFormControl(null, this.billedBy === 'invoice' ? [Validators.required] : null), // reference is hidden and not required if card is selected
            insurance: new UntypedFormControl(true),
            plan: new UntypedFormControl(null, [Validators.required]),
            paymentMethod: new UntypedFormControl(this.billedBy ?? 'invoice', [Validators.required])
        });

        if (this.region === 'us') {
            this.billingForm.patchValue(
                {
                    paymentMethod: 'card'
                },
                { emitEvent: false }
            );
        }
    }

    //Handles validation and resets input fields for EAN number and EAN contact person
    handleEanStateChange(event: { value: boolean }): void {
        this.billingForm.get('ean_number').setErrors(null);
        this.eanFingerprint = null;
        this.useEan = typeof event.value === 'boolean' ? event.value : !!parseInt(event.value);
        this.ean_number.setValidators(this.useEan ? [Validators.required, Validators.maxLength(13)] : null);
        this.economic_contact_name.setValidators(this.useEan ? [Validators.required] : null);
        this.ean_number.updateValueAndValidity();
        this.economic_contact_name.updateValueAndValidity();

        if (!this.useEan)
            this.billingForm.patchValue({
                ean_number: null,
                economic_contact_name: null
            });
    }

    async validateEanNumber(): Promise<void> {
        this.billingForm.get('ean_number').setErrors({ pending: true });
        this.eanNumberIsValid = false;
        this.loadingEanResponse = true;
        this.eanFingerprint = hash.keys(`${this.ean_number.value}${moment()}`);
        const { isValid, fingerprint } = await this.billingService.validateEan(this.ean_number.value, this.eanFingerprint);
        if (this.eanFingerprint === fingerprint) {
            this.loadingEanResponse = false;
            this.eanNumberIsValid = isValid;
            this.ean_number.setErrors(isValid ? null : { invalid: true });
        }
    }

    clearPending() {
        this.billingForm.get('ean_number').setErrors(null);
    }

    async setStripeCustomerDetails(): Promise<void> {
        if (!(this.billing && this.billing.shallow_account_details)) return;

        if (!this.billing.stripe_customer_id) {
            // creating if no customer account is related
            console.log('No stripe customer id, creating one and saving it...');

            const postStripeCustomerRes: { status: string; data: { customer_id: string } } = await this.billingService
                .postStripeCustomer({
                    billing: this.billing,
                    details: this.details
                })
                .catch(err => {
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), this.translate.instant('billing.refresh'), 'Error', { disableTimeOut: true });
                    return;
                });

            await this.billingService
                .setStripeCustomerId(this.user.uid, postStripeCustomerRes.data.customer_id)
                .then(() => {
                    // CommentTag: You just called an endpoint to create a customer, why not just set the stripe customer id on the endpoint?
                    this.billing = { ...this.billing, stripe_customer_id: postStripeCustomerRes.data.customer_id };
                })
                .catch(err => {
                    console.error(err);
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), this.translate.instant('billing.refresh'), 'Error', { disableTimeOut: true });
                    return;
                });
        }

        this.billing.shallow_account_details ? (this.shallowAccountDetails = this.billing.shallow_account_details) : null;

        if (this.billing.company_registration_details) {
            this.companyDetails = this.billing.company_registration_details;
            this.billedByProvided = true;
            this.billedBy = this.companyDetails.billed_by;
        }

        if (this.ean_number.value) {
            this.useEan = true;
            this.billingForm.patchValue({
                plan: { value: null, disabled: true }
            });
        }
    }

    setDiscounts(): void {
        this.selectedDevices.forEach(device => {
            if (device.discount) {
                const discountAmount = device.discount;
                if (this.discounts.hasOwnProperty(discountAmount)) {
                    this.discounts[discountAmount] += 1;
                } else {
                    this.discounts[discountAmount] = 1;
                }
            }
        });
    }

    getPrices(): void {
        const readPricesSub = this.billingService
            .readPrices(this.details.stripe_region, this.country)
            .snapshotChanges()
            .subscribe((pricesSnap: SnapshotAction<Record<string, Price>>) => {
                readPricesSub.unsubscribe();
                if (pricesSnap.payload.exists()) {
                    const prices: Record<string, Price> = pricesSnap.payload.val();
                    this.invoicePrices = [];
                    this.cardPrices = [];

                    for (const priceProp in prices) {
                        const price: Price = prices[priceProp];
                        price.id = priceProp; // setting the id for the price on each object
                        if (price.active) {
                            this.invoicePrices.push(price);

                            if (price.interval <= 3 * 12)
                                // max is 3 years
                                this.cardPrices.push(price);
                        }
                    }
                    // sort so that the longest subscription period is the first element in the array (i.e. the cheapest per month)
                    this.invoicePrices.sort((a, b) => (a.interval < b.interval ? 1 : -1));
                    this.cardPrices.sort((a, b) => (a.interval < b.interval ? 1 : -1));
                } else {
                    this.helperService.defaultHtmlToast(`${this.translate.instant('billing.missing_sub_price')}:${this.currency.toUpperCase()}`, this.translate.instant('billing.please_contact'), 'Info');
                }
            });
    }

    getAnnualSavings(currentUnitAmount: number, currentInterval: number, upperPriceUnitAmount: number, upperPriceInterval: number): number {
        const discount: number = this.coupon ? this.coupon.percent_off / 100 : 0;
        const currentAnnualCost = ((currentUnitAmount * 12) / currentInterval) * (1 - discount);
        const highestAnnualCost = ((upperPriceUnitAmount * 12) / upperPriceInterval) * (1 - discount);
        const savings = (highestAnnualCost - currentAnnualCost) / 100;
        return this.helperService.roundToTwoDecimals(savings);
    }

    getHighestPrice(invoicePrices: Price[]): Price {
        // Sort the prices in ascending order of annual cost
        const sortedPrices: Price[] = invoicePrices.sort((a, b) => {
            const aAnnualCost: number = (a.unit_amount * 12) / a.interval;
            const bAnnualCost: number = (b.unit_amount * 12) / b.interval;
            return aAnnualCost - bAnnualCost;
        });

        // Get the lowest three prices
        const lowestThreePrices: Price[] = sortedPrices.slice(0, 3);

        // Find the highest price among the lowest three
        return lowestThreePrices.reduce((prev, current) => {
            const prevAnnualCost: number = (prev.unit_amount * 12) / prev.interval;
            const currentAnnualCost: number = (current.unit_amount * 12) / current.interval;
            return currentAnnualCost > prevAnnualCost ? current : prev;
        });
    }

    validateSelectionsForLabelData(): boolean {
        if (this.selectedPlan && this.selectedPlan.price && this.selectedPlan.price.unit_amount) if (Object.keys(this.selectedDevices).length > 0) return true;

        return false;
    }

    async planPreference(price: Price, billed_by: 'card' | 'invoice') {
        console.log(this.order_reference.value);
        const selectedPlan = { price, billed_by };
        this.selectedPlan = selectedPlan;
        this.billedBy = billed_by;
        this.plan.setValue(selectedPlan);
    }

    /**
     * This function is called when the user clicks the "Subscribe" button.
     * It sets up the subscription data and devices to subscribe, then sends the data to the backend.
     * If the subscription requires a payment method, it will handle that by opening a modal where user can type in card data.
     * If the subscription requires 3D secure, it will handle that by opening a modal where user can authenticate.
     */
    async setupSubscription(): Promise<void> {
        this.formSubmitted = true;
        this.subscriptionPending = true;
        if (this.billingForm.valid) {
            try {
                const subscriptionData: AwSubscription = this.setupSubscriptionData();
                const devicesToSubscribe: DevicesToSubscribe[] = this.setupDevicesToSubscribe();

                if (this.useEan) {
                    await this.billingService.updateBillingWithEanDetails(this.user.uid, {
                        ean_number: this.ean_number.value,
                        economic_contact_name: this.economic_contact_name.value
                    });
                }

                if (this.billedBy === 'card' && !this.hasCard) {
                    await this.billingCardComponent.saveCard();
                }

                const backendResponse = await this.billingService.createDeviceSubscription(devicesToSubscribe, subscriptionData);
                const needsToConfirm3DSecure = backendResponse.requiresAction;
                if (needsToConfirm3DSecure) {
                    try {
                        await this.handleConfirm3DSecure(backendResponse);
                    } catch {
                        this.subscriptionPending = false;
                        return;
                    }
                }

                this.helperService.defaultHtmlToast(this.translate.instant('billing.setup_complete'), this.translate.instant(`billing.invoice_sent`), 'Success');
                this.subscriptionCreated.emit({ devicesForSub: this.selectedDevices, billedBy: this.billedBy });
                this.modalService.dismissAll('Cancel');
            } catch (error) {
                this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.try_again'), 'Error');
                this.subscriptionPending = false;
            }
        } else {
            console.log(this.billingForm.controls.order_reference.errors);
            this.helperService.defaultHtmlToast(this.translate.instant('billing.form_invalid'), this.translate.instant('billing.check_fields'), 'Warning');
        }
        this.subscriptionPending = false;
    }

    private async handleConfirm3DSecure(beResponse): Promise<void> {
        const stripeRegion = this.user.settings.stripe_region;
        const stripeKey: string = stripeRegion === StripeRegions.EU ? environment.stripePublicKeyEU : stripeRegion === StripeRegions.US ? environment.stripePublicKeyUS : 'no key';
        const stripe: Stripe = await loadStripe(stripeKey); // Initialize Stripe with your publishable key

        if (stripe) {
            const { error, paymentIntent } = await stripe.confirmCardPayment(beResponse.clientSecret);

            if (error) {
                console.error('3DS authentication failed:', error);
                if (error.code == 'card_declined' && error.decline_code == 'insufficient_funds') {
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.insufficient_funds'), 'Error');
                } else {
                    this.helperService.defaultHtmlToast(this.translate.instant('billing.payment_failed'), this.translate.instant('billing.try_again'), 'Error');
                }
                throw error;
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                const cardSubObject: CardSubObject = {
                    uid: this.user.uid,
                    subKey: beResponse.subKey,
                    stripeSubId: beResponse.stripeSubId
                };
                await this.billingService.resolveCardSub(cardSubObject, beResponse.sub, beResponse.devices);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.setup_complete'), this.translate.instant('billing.card_success_charged'), 'Success');
            }
        }
    }

    private setupSubscriptionData(): AwSubscription {
        return {
            created: moment().unix(),
            period_end: parseInt(moment().add(this.selectedPlan.price.interval, 'month').format('X')),
            period_start: moment().unix(),
            price_id: this.selectedPlan.price.id,
            billed_by: this.billedBy,
            insurance: this.insurance.value,
            purchase_order: this.order_reference.value
        };
    }

    private setupDevicesToSubscribe(): DevicesToSubscribe[] {
        const devicesToSubscribe: DevicesToSubscribe[] = [];
        for (const device of this.selectedDevices) {
            const deviceId: string = device.device_key;
            const deviceSerial: string = device.serial_number;
            const deviceForSubObj: DevicesToSubscribe = { serial: deviceSerial, id: deviceId };
            if (device.next_discount_ref) {
                deviceForSubObj.discount_ref = device.next_discount_ref;
            }
            devicesToSubscribe.push(deviceForSubObj);
        }
        return devicesToSubscribe;
    }

    /**
     * This function is called when the card modal is closed,
     * if the card is saved, it tries to setup the subscription again.
     * @param hasCard
     */
    async catchHasSaved(hasCard: boolean): Promise<void> {
        this.hasCard = hasCard;
    }

    getIntervalLabel(interval: number | null): string {
        // interval can be null if no plan is yet picked
        if (!interval) return `${this.translate.instant('billing.choose_a_plan')}`;

        if (interval === 1) return `${this.translate.instant('billing.monthly')}`;

        if (interval > 1) return `${this.translate.instant('billing.billed_every')} ${interval} ${this.translate.instant('billing.months')}`;
    }

    getPriceLabel(result: string): PriceLabel {
        const firstPart: string = result.substring(0, result.length - 3);
        const secondPart: string = result.substring(result.length - 3, result.length);
        return {
            firstPart,
            secondPart,
            currency: this.currency.toUpperCase(),
            combined: `${firstPart}${secondPart} ${this.currency.toUpperCase()}`
        };
    }

    getPriceLabelPlan(unitAmount: number, interval: number): PriceLabel {
        const result: string = this.helperService.localizeNumber(unitAmount / 100 / interval);
        return this.getPriceLabel(result);
    }

    getPriceLabelDevice(): PriceLabel {
        const result: string = this.helperService.localizeNumber(this.selectedPlan && this.selectedPlan.price && this.selectedPlan.price.unit_amount ? this.selectedPlan.price.unit_amount / this.selectedPlan.price.interval / 100 : 0);
        return this.getPriceLabel(result);
    }

    getInsurancePriceLabelSub(): PriceLabel {
        const result: string = this.helperService.localizeNumber(this.validateSelectionsForLabelData() && this.insurance.value ? (this.selectedPlan.price.unit_amount * this.selectedDevices.length * 0.2) / 100 : 0);
        return this.getPriceLabel(result);
    }

    getPriceLabelSubTotal(): PriceLabel {
        const result: string = this.helperService.localizeNumber(this.getPriceForUnits(this.selectedDevices.length));
        return this.getPriceLabel(result);
    }

    getPriceLabelVat(): PriceLabel {
        const vatAmount = this.calculateVatAndSubtotalAfterDiscount().vatAmount;
        const result: string = this.helperService.localizeNumber(vatAmount);
        return this.getPriceLabel(result);
    }

    getDiscountLabel(amountOfUnits: number, discountPercent: number): PriceLabel {
        const discountAmount: number = this.getPriceForUnits(amountOfUnits) * (discountPercent / 100);
        const result: string = this.helperService.localizeNumber(discountAmount);
        return this.getPriceLabel(result);
    }

    getPriceLabelTotal(): PriceLabel {
        const { subtotal, vatAmount } = this.calculateVatAndSubtotalAfterDiscount();
        const totalWithVat: number = subtotal + vatAmount;
        const result: string = this.helperService.localizeNumber(totalWithVat);
        return this.getPriceLabel(result);
    }

    private calculateVatAndSubtotalAfterDiscount(): { subtotal: number; vatAmount: number } {
        let subtotal: number = this.getPriceForUnits(this.selectedDevices.length);

        // Subtract additional discounts if applicable
        Object.keys(this.discounts).forEach(discountPercent => {
            const amountOfUnits = this.discounts[discountPercent];
            const discountAmount = this.getDiscountLabel(amountOfUnits, parseInt(discountPercent)).combined;
            const discountAmountNumber = parseFloat(discountAmount.replace(/[^0-9.,-]+/g, ''));
            subtotal -= discountAmountNumber;
        });

        // Calculate VAT
        const vatAmount: number = subtotal * (this.vatTier * 0.01);

        return { subtotal, vatAmount };
    }

    getPriceForUnits(amountOfUnits: number): number {
        if (this.validateSelectionsForLabelData()) {
            return (this.selectedPlan.price.unit_amount / 100) * amountOfUnits * (this.insurance.value ? 1.2 : 1);
        }
        return 0;
    }

    get plan() {
        return this.billingForm.get('plan');
    }

    get paymentMethod() {
        return this.billingForm.get('paymentMethod');
    }

    get ean_number() {
        return this.billingForm.get('ean_number');
    }

    get economic_contact_name() {
        return this.billingForm.get('economic_contact_name');
    }

    get order_reference() {
        return this.billingForm.get('order_reference');
    }

    get insurance() {
        return this.billingForm.get('insurance');
    }

    protected readonly Object = Object;
}
