<div class="title-area">
    <div class="search-attributes" [hidden]="!authService.hasLimitedAccess('operator_filter_bar', (role$ | async))">
        <ng-container *ngIf="!isMobile">
            <button (click)="onDayFilterButtonClick($event, 6)" class="pill-button">{{'misc.last_seven_days' |
                translate}}</button>
            <button (click)="onDayFilterButtonClick($event, 29)" class="pill-active-button">{{'misc.last_thirty_days' |
                translate}}</button>
            <button (click)="onDayFilterButtonClick($event, 364)" class="pill-button">{{'misc.last_year' |
                translate}}</button>
        </ng-container>
        <!-- DATE START -->
        <input [hidden]="isMobile" id="operator-from" name="from"
            class="form-select form-control data-select pointer datepicker-field" readonly
            placeholder="{{'operator.filter_bar.date_from' | translate}}" type="text"
            (changeDate)="datePickerChanged('from')">
        <!-- DATE END -->
        <input [hidden]="isMobile" id="operator-to" name="to"
            class="form-select form-control data-select pointer datepicker-field" readonly
            placeholder="{{'operator.filter_bar.date_to' | translate}}" type="text"
            (changeDate)="datePickerChanged('to')">
    </div>
    <button class="btn btn-primary btn-tiny" [routerLink]="['/customers/create']"
        *ngIf="title === 'Customers' && authService.hasLimitedAccess('create_customer', (role$ | async))">
        <img class="plus-icon-on-btn" src='../../../../assets/icons/plus-for-create.svg'
            alt="{{'customers.create_customer' | translate}}">
        {{'customers.create_customer' | translate}}
    </button>
    <button class="btn btn-primary btn-tiny" (click)="openModal(createContractModal)"
        *ngIf="title === 'Contracts' && authService.hasLimitedAccess('create_contract', (role$ | async))">
        <img class="plus-icon-on-btn" src='../../../../assets/icons/plus-for-create.svg'
            alt="{{'customers.create_customer' | translate}}">
        {{'operator.contract_modal.create_contract' | translate}}
    </button>
    <button *ngIf="title === 'Devices'" style="align-self: center; margin: 0;"
        (click)="showOnlyMaintenance(!filterByMaintenance)"
        [className]="filterByMaintenance ? 'pill-active-button' : 'pill-button'">{{'operator.filter_bar.show_only_maintenance'
        | translate}}</button>

</div>

<ng-template #createContractModal>
    <app-contract-form [isEdit]="false"></app-contract-form>
</ng-template>