import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Billing, BillingDevice, DeviceWithHoverIconProperties } from '../../../../../shared_models/billing/billing';
import { BillingService } from '../../../services/billing/billing.service';
import { DashboardUser } from '../../../../../shared_models/dashboard-user';
import { HelperService } from '../../../services/helper/helper.service';
import { Observable, Subscription } from 'rxjs';
import { Details } from '../../../../../shared_models/details';
import { Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TableHeaderOptions } from '../../../../../shared_models/aw-components/tableHeaderOptions';
import { FilterOption, FilterType, SelectOption } from '../../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort } from '../../../../../shared_models/search-params/FilterSortParams';
import { PageEvent } from '@angular/material/paginator';
import { CustomerService } from '../../../services/customer/customer.service';
import Stripe from 'stripe';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AwDotPopupButtonComponent } from '../../misc/aw-dot-popup-button/aw-dot-popup-button.component';
import { PaginateFetchCacheService } from '../../../services/paginate-fetch-cache/paginate-fetch-cache.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';
import _ from 'lodash';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { BillingSubscriptionModalComponent } from './billing-subscription-modal/billing-subscription-modal.component';
import { AwCheckboxComponent } from '../../misc/aw-checkbox/aw-checkbox.component';
import { AwHoverIconComponent } from '../../misc/aw-hover-icon/aw-hover-icon.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { LoadingComponent } from '../../loading/loading.component';
import { AwFilterButtonComponent } from '../../misc/aw-filter-button/aw-filter-button.component';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { PageLayoutComponent } from '../../misc/aw-page-layout/page-layout.component';
import { SnapshotAction } from '@angular/fire/compat/database';
import { DiscountCoupon } from '../../../../../shared_models/billing/discount-coupon';
import { DiscountCouponService } from '../../../services/discount-coupon/discount-coupon.service';

@Component({
    selector: 'app-billing-new',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
    standalone: true,
    imports: [
        PageLayoutComponent,
        NgIf,
        AwFilterButtonComponent,
        LoadingComponent,
        RouterLink,
        AwTableComponent,
        NgFor,
        CustomToolTipComponent,
        AwHoverIconComponent,
        AwDotPopupButtonComponent,
        NgStyle,
        AwCheckboxComponent,
        BillingSubscriptionModalComponent,
        TranslateModule,
        PaginatePipe
    ]
})
export class BillingComponent implements OnInit {
    protected view = 'billing.active';
    protected noShare = false;
    protected isMobile = false;

    isOperator$: Observable<boolean> = this.authService.isOperator;
    isOperator = false;
    modalRef: NgbModalRef;
    billing: Billing;
    user: DashboardUser = this.helperService.getUser();
    details: Details;
    selectedDevices: BillingDevice[] = [];
    dropdownComponents: AwDotPopupButtonComponent[] = [];
    hasDiscounts: boolean = false;
    globalDiscount: number = 0;

    // Necessary inputs for FE pagination
    pageSizeActive = 15;
    pageSizeInactive = 15;
    totalItemsActive = 0;
    pageNumberActive = 0;
    pageNumberInactive = 0;
    totalItemsInactive = 0;
    tableHeaderOptions: TableHeaderOptions[];
    tableHeaderOptionsInactive: TableHeaderOptions[];
    defaultColumnWidthInactive: number[] = [15, 15, 20, 20, 15, 10, 5];
    defaultColumnWidthActive: number[] = [15, 15, 20, 20, 15, 10, 5];

    filterOptionsInactive: FilterOption[] = [
        {
            key: 'location_key',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'misc.locations',
            selectOptions: []
        },
        {
            key: 'device_type',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'billing.device_types',
            selectOptions: []
        },
        { key: 'created', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'customers.created' }
    ];

    filterOptionsActive: FilterOption[] = [
        {
            key: 'location_key',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'misc.locations',
            selectOptions: []
        },
        {
            key: 'device_type',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'billing.device_types',
            selectOptions: []
        },
        {
            key: 'renewable',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'transactions.status',
            selectOptions: []
        },
        { key: 'period_start', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'billing.activation_date' }
    ];

    activeBillingDevices: DeviceWithHoverIconProperties[] = [];
    inactiveBillingDevices: DeviceWithHoverIconProperties[] = [];

    paramsForActive: FilterSortParams = {
        filter: {},
        sortBy: { key: 'location_name', order: 'asc' },
        pageSize: this.pageSizeActive,
        pageNumber: this.pageNumberActive
    };
    paramsForInactive: FilterSortParams = {
        filter: {},
        sortBy: { key: 'location_name', order: 'asc' },
        pageSize: this.pageSizeInactive,
        pageNumber: this.pageNumberInactive
    };

    //Loading variables
    dotButtonLoadingIndex: number = -1;
    loadingActiveBilling: boolean = false;
    loadingInactiveBilling: boolean = false;
    initLoading: boolean = true;

    constructor(
        private modalService: NgbModal,
        private billingService: BillingService,
        protected helperService: HelperService,
        private router: Router,
        protected translate: TranslateService,
        private customerService: CustomerService,
        private breakpointObserver: BreakpointObserver,
        private authService: AuthService,
        private discountCouponService: DiscountCouponService,
        private pfcService: PaginateFetchCacheService<BillingDevice>
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit() {
        this.isOperator$
            .subscribe(value => {
                this.isOperator = value;
            })
            .unsubscribe();
        await Promise.all([
            this.setTableHeaderOptions(),
            this.setBilling(),
            this.getGlobalDiscount(),
            this.loadActiveBilling(true),
            this.loadInactiveBilling(true),
            this.assignSelectValuesToFilterButton(),
            this.setDetails(),
            this.setSubscriptionForNoShare()
        ])
            .then(() => {
                this.reorderInactiveTableHeadersForMobile();
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.initLoading = false;
            });
    }

    // Reorder the table headers for the inactive table on mobile so that checkbox is in front instead of last
    reorderInactiveTableHeadersForMobile() {
        if (this.isMobile) {
            const lastElement = this.tableHeaderOptionsInactive.pop();
            this.tableHeaderOptionsInactive.unshift(lastElement);
        }
    }

    async setBilling() {
        this.billing = await this.billingService.readBilling(this.user.uid);
    }

    async setDetails() {
        const detailsSub: Subscription = this.customerService
            .readCustomerDetails(this.user.uid)
            .snapshotChanges()
            .subscribe(detailsSnap => {
                detailsSub.unsubscribe();
                this.details = detailsSnap.payload.val();

                if (!this.details.vat_number_details && !this.details.vat_number_not_provided && this.details.business_type !== 'individual') {
                    this.router.navigate([`account/update_vat`]);
                }
            });
    }

    async setSubscriptionForNoShare() {
        const permissionsSub = this.customerService
            .readSubCustomerPermission(this.user.uid)
            .snapshotChanges()
            .subscribe(action => {
                permissionsSub.unsubscribe();
                if (action.payload.child('no_share').val()) this.noShare = true;
            });
    }

    async assignSelectValuesToFilterButton() {
        const selectBoxValues: Record<string, SelectOption[]> = await this.billingService.getSelectBoxValues();
        const keys = ['location_key', 'device_type', 'renewable'];

        keys.forEach(key => {
            // Find the filter option in filterOptionsActive
            let filterOption: FilterOption = this.filterOptionsActive.find(option => option.key === key);
            if (filterOption) {
                filterOption.selectOptions = selectBoxValues[key];
            }

            // Find the filter option in filterOptionsInactive
            filterOption = this.filterOptionsInactive.find(option => option.key === key);
            if (filterOption) {
                filterOption.selectOptions = selectBoxValues[key];
            }
        });
    }

    async handlePageActive(e: PageEvent) {
        this.pageSizeActive = e.pageSize;
        this.pageNumberActive = e.pageIndex;
        this.paramsForActive.pageSize = this.pageSizeActive;
        this.paramsForActive.pageNumber = this.pageNumberActive;
        await this.loadActiveBilling();
    }

    async handlePageInactive(e: PageEvent) {
        this.pageSizeInactive = e.pageSize;
        this.pageNumberInactive = e.pageIndex;
        this.paramsForInactive.pageSize = this.pageSizeInactive;
        this.paramsForInactive.pageNumber = this.pageNumberInactive;
        await this.loadInactiveBilling();
    }

    async getGlobalDiscount(): Promise<void> {
        try {
            const globalDiscount = await this.discountCouponService.getGlobalDiscount();
            this.globalDiscount = globalDiscount.globalDiscount;
        } catch (error) {
            this.globalDiscount = 0;
        }
    }

    async loadActiveBilling(initLoading?: boolean): Promise<void> {
        this.loadingActiveBilling = true;
        const paramsAsString: string = JSON.stringify(this.paramsForActive);
        const apiEndpoint: string = `${environment.baseUrl}/api_billing/get_active_billing?params=${paramsAsString}`;
        const cacheExists = this.pfcService.cacheExists(apiEndpoint, this.paramsForActive);
        this.pfcService
            .getData(apiEndpoint, this.paramsForActive)
            .then(response => {
                this.activeBillingDevices = response.pages[this.pageNumberActive].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
                this.totalItemsActive = response.totalItems;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.loadingActiveBilling = false;
                if (initLoading && cacheExists) {
                    this.updateCachedDataActive(apiEndpoint);
                }
            });
    }

    async loadInactiveBilling(initLoading?: boolean): Promise<void> {
        this.loadingInactiveBilling = true;
        const paramsAsString: string = JSON.stringify(this.paramsForInactive);
        const apiEndpoint = `${environment.baseUrl}/api_billing/get_inactive_billing?params=${paramsAsString}`;
        const cacheExists = this.pfcService.cacheExists(apiEndpoint, this.paramsForInactive);
        this.pfcService
            .getData(apiEndpoint, this.paramsForInactive)
            .then(response => {
                this.inactiveBillingDevices = response.pages[this.pageNumberInactive].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
                this.totalItemsInactive = response.totalItems;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.loadingInactiveBilling = false;
                this.selectedDevices = [];
                if (initLoading && cacheExists) {
                    this.updateCachedDataInactive(apiEndpoint);
                }
            });
    }

    private updateCachedDataActive(endpoint: string) {
        this.pfcService.updateDataIfDifferentFromCache(endpoint, this.paramsForActive).then(fetchedData => {
            if (!_.isEqual(fetchedData.pages[this.pageNumberActive], this.activeBillingDevices)) {
                this.activeBillingDevices = fetchedData.pages[this.pageNumberActive].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
            }
        });
    }

    private updateCachedDataInactive(endpoint: string) {
        this.pfcService.updateDataIfDifferentFromCache(endpoint, this.paramsForInactive).then(fetchedData => {
            if (!_.isEqual(fetchedData.pages[this.pageSizeInactive], this.inactiveBillingDevices)) {
                this.inactiveBillingDevices = fetchedData.pages[this.pageNumberInactive].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    this.selectedDevices = [];
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
            }
        });
    }

    catchTabChanged(event: string) {
        this.view = event;
    }

    // Reset pagenumber if sort is changed to another key, if same sort but different order, keep the pagenumber
    async catchSortChanged(event: Sort) {
        if (this.view === 'billing.active') {
            if (this.paramsForActive.sortBy.key !== event.key) {
                this.pageNumberActive = 0;
            }
            this.paramsForActive.sortBy = event;
            this.paramsForActive.pageNumber = this.pageNumberActive;
            await this.loadActiveBilling();
        } else {
            if (this.paramsForInactive.sortBy.key !== event.key) {
                this.pageNumberInactive = 0;
            }
            this.paramsForInactive.sortBy = event;
            this.paramsForInactive.pageNumber = this.pageNumberInactive;
            await this.loadInactiveBilling();
        }
    }

    // Always reset pagenumber when filter is changed
    async catchFilterChanged(event: any) {
        if (this.view === 'billing.active') {
            if (JSON.stringify(this.paramsForActive.filter) !== JSON.stringify(event)) {
                this.pageNumberActive = 0;
            }
            this.paramsForActive.filter = event;
            this.paramsForActive.pageNumber = this.pageNumberActive;
            await this.loadActiveBilling();
        } else {
            if (JSON.stringify(this.paramsForInactive.filter) !== JSON.stringify(event)) {
                this.pageNumberInactive = 0;
            }
            this.paramsForInactive.filter = event;
            this.paramsForInactive.pageNumber = this.pageNumberInactive;
            await this.loadInactiveBilling();
        }
    }

    getMenuOptions(data: BillingDevice): string[] {
        let options = [];
        if (data.billed_by === 'invoice') {
            options = [data.renewable ? 'billing.cancel' : 'billing.un_do_cancel'];
        } else {
            options = ['device.edit'];
        }
        return options;
    }

    async catchDotMenuClicked(event: string, device: BillingDevice, index: number) {
        this.dotButtonLoadingIndex = index;
        if (event === 'billing.un_do_cancel' || event === 'billing.cancel') {
            await this.changeInvoicedSubscriptionStatusForDevice(device);
            this.changeDiscountStatusForDevice(device);
        } else if (event === 'device.edit') {
            await this.createCardSubscriptionSession();
        }
        this.dotButtonLoadingIndex = -1;
    }

    private changeDiscountStatusForDevice(device: BillingDevice) {
        const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
        const activeDevice = this.activeBillingDevices.find(d => d.device_key === device.device_key);
        if (activeDevice) {
            Object.assign(activeDevice, hoverIconProperties);
        }
    }

    async createCardSubscriptionSession() {
        return this.billingService
            .createCardSubscriptionSession({ customer_id: this.billing.stripe_customer_id })
            .then((session: Stripe.BillingPortal.Session) => {
                window.open(session.url, '_self');
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            });
    }

    private async changeInvoicedSubscriptionStatusForDevice(device: BillingDevice): Promise<void> {
        return this.billingService.changeSubscriptionStatus(device).then(updatedDevice => {
            this.createToastForSubscriptionStatusChange(updatedDevice.renewable);
            const deviceIndex = this.activeBillingDevices.findIndex(d => d.device_key === device.device_key);
            if (deviceIndex !== -1) {
                this.activeBillingDevices[deviceIndex].renewable = updatedDevice.renewable;
            }
            this.activeBillingDevices = [...this.activeBillingDevices];
        });
    }

    private createToastForSubscriptionStatusChange(renewed: boolean): void {
        if (renewed) {
            this.helperService.defaultHtmlToast('', this.translate.instant('billing.device_sub_renewed'), 'Success');
        } else {
            this.helperService.defaultHtmlToast('', this.translate.instant('billing.device_sub_cancelled'), 'Success');
        }
    }

    selectAllDevices() {
        const selectAll = !this.areAllDevicesSelectedCheck();

        if (selectAll) {
            this.selectedDevices = [];
            for (const device of this.inactiveBillingDevices) {
                if (!device.pending_card_payment) {
                    device.is_checked = selectAll;
                    this.selectedDevices.push(device);
                }
            }
        } else {
            for (const device of this.inactiveBillingDevices) {
                device.is_checked = selectAll;
            }
            this.selectedDevices = [];
        }
    }

    areAllDevicesSelectedCheck(): boolean {
        if (!this.inactiveBillingDevices || this.inactiveBillingDevices.length === 0) {
            return false;
        }
        for (const device of this.inactiveBillingDevices) {
            if (!device.is_checked) {
                return false;
            }
        }
        return true;
    }

    toggleDeviceSelection(device: any, isChecked: boolean): void {
        if (isChecked) {
            this.selectedDevices.push(device);
            device.is_checked = this.isDeviceSelected(device);
        } else {
            this.selectedDevices = this.selectedDevices.filter(d => !(d.device_key === device.device_key && d.serial_number === device.serial_number));
            device.is_checked = this.isDeviceSelected(device);
        }
    }

    isDeviceSelected(device: BillingDevice): boolean {
        return this.selectedDevices.some(d => d.device_key === device.device_key && d.serial_number === device.serial_number);
    }

    async openBillingSubscriptionModal(modal: NgbModalModule) {
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });
    }

    noDeviceSelectedToast() {
        this.helperService.defaultHtmlToast(this.translate.instant('billing.no_device_selected'), this.translate.instant('billing.minimum_devices'), 'Warning');
    }

    onDropdownOpened(openDropdown: AwDotPopupButtonComponent): void {
        this.dropdownComponents.forEach(dropdown => {
            if (dropdown !== openDropdown) {
                dropdown.closeDropdown();
            }
        });
    }

    async onSubscriptionCreated() {
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_active_billing`);
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_inactive_billing`);
        await this.loadInactiveBilling(true);
        await this.loadActiveBilling(true);
        this.selectedDevices = [];
    }

    getCustomerDiscounts(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const readPricesSub: Subscription = this.billingService
                .readHasDiscounts(this.user.uid)
                .snapshotChanges()
                .subscribe(
                    (discountSnap: SnapshotAction<Record<string, DiscountCoupon>>) => {
                        // CommentTag: This is weird constant is called readPrices but it reading discounts, is it correct?
                        readPricesSub.unsubscribe();
                        if (discountSnap.payload.exists()) {
                            const discounts: Record<string, DiscountCoupon> = discountSnap.payload.val();
                            if (Object.keys(discounts).length > 0) {
                                return resolve(true);
                            }
                        }
                        return resolve(false);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    async setTableHeaderOptions() {
        this.hasDiscounts = await this.getCustomerDiscounts(); // Check if customer has discounts

        if (this.hasDiscounts) {
            this.defaultColumnWidthInactive = [15, 15, 15, 15, 10, 10, 5];
            this.defaultColumnWidthActive = [15, 15, 15, 15, 10, 10, 5];
        }

        this.tableHeaderOptions = [
            {
                sortKey: 'serial_number',
                title: this.translate.instant('device.serial_num'),
                width: this.defaultColumnWidthInactive[0] + '%'
            },
            {
                sortKey: 'customer_name',
                title: !this.isOperator ? '' : this.translate.instant('misc.customer'),
                width: this.defaultColumnWidthInactive[1] + '%'
            },
            {
                sortKey: 'location_name',
                title: this.translate.instant('misc.location'),
                width: this.defaultColumnWidthInactive[2] + '%'
            },
            {
                sortKey: 'device_name',
                title: this.translate.instant('misc.device'),
                width: this.defaultColumnWidthInactive[3] + '%'
            },
            {
                sortKey: 'renewable',
                title: this.translate.instant('transactions.status'),
                width: this.defaultColumnWidthInactive[4] + '%'
            },
            {
                sortKey: 'activated',
                title: this.translate.instant('billing.activated'),
                width: this.defaultColumnWidthInactive[5] + '%',
                alignment: 'right'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[6] + '%',
                alignment: 'right'
            }
        ];

        this.tableHeaderOptionsInactive = [
            {
                sortKey: 'serial_number',
                title: this.translate.instant('device.serial_num'),
                width: this.defaultColumnWidthInactive[0] + '%'
            },
            {
                sortKey: 'customer_name',
                title: !this.isOperator ? '' : this.translate.instant('misc.customer'),
                width: this.defaultColumnWidthInactive[1] + '%'
            },
            {
                sortKey: 'location_name',
                title: this.translate.instant('misc.location'),
                width: this.defaultColumnWidthInactive[2] + '%'
            },
            {
                sortKey: 'device_name',
                title: this.translate.instant('misc.device'),
                width: this.defaultColumnWidthInactive[3] + '%'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[4] + '%'
            },
            {
                sortKey: 'created',
                title: this.translate.instant('customers.created'),
                width: this.defaultColumnWidthInactive[5] + '%',
                alignment: 'right'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[6] + '%',
                alignment: 'right'
            }
        ];

        if (this.hasDiscounts) {
            const discountColumn = {
                sortKey: 'discount',
                title: this.translate.instant('billing.discount'), // Assuming you have a translation key for 'discount'
                sortDirection: 'desc' as 'desc',
                width: '15%'
            };
            // Inserting the discount column after the 'device_name' column
            const deviceNameIndex = this.tableHeaderOptionsInactive.findIndex(option => option.sortKey === 'device_name');
            this.tableHeaderOptionsInactive.splice(deviceNameIndex + 1, 0, discountColumn);
            // Inserting the discount column after the 'device_name' column
            const statusIndex = this.tableHeaderOptions.findIndex(option => option.sortKey === 'renewable');
            this.tableHeaderOptions.splice(statusIndex + 1, 0, discountColumn);
        }
    }
}
