/**
 * Albanian translation for bootstrap-datepicker
 * Tomor Pupovci <https: //www.github.com/ttomor>
 */
export default {
  sq: {
    days: ["E Diel", "E Hënë", "E Martē", "E Mërkurë", "E Enjte", "E Premte", "E Shtunë"],
    daysShort: ["Die", "Hën", "Mar", "Mër", "Enj", "Pre", "Shtu"],
    daysMin: ["Di", "Hë", "Ma", "Më", "En", "Pr", "Sht"],
    months: ["Janar", "Shkurt", "Mars", "Prill", "Maj", "Qershor", "Korrik", "Gusht", "Shtator", "Tetor", "Nëntor", "Dhjetor"],
    monthsShort: ["Jan", "Shk", "Mar", "Pri", "Maj", "Qer", "Korr", "Gu", "Sht", "Tet", "Nën", "Dhjet"],
    monthsTitle: "Muaj",
    today: "Sot",
    weekStart: 1,
    format: "dd/mm/yyyy",
    clear: "Pastro"
  }
};
