import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/compat/database";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { Action } from "../../../../shared_models/operator/actions";
import { LoggedInDashboardUser } from "../../../../shared_models/dashboard-user";
import { BehaviorSubject, Subscription, lastValueFrom } from "rxjs";
import { CustomerService } from "../customer/customer.service";
import { SubCustomer } from "../../../../shared_models/sub-customer";

@Injectable({
    providedIn: 'root'
})
export class ActionsService {

    private alertToggled$ = new BehaviorSubject<any>({});
    private adviceToggled$ = new BehaviorSubject<any>({});
    selectedAlertToggled$$ = this.alertToggled$.asObservable();
    selectedAdviceToggled$ = this.adviceToggled$.asObservable();

    subcustomerUids: Record<string, string> = {};

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private authService: AuthService,
        private customerService: CustomerService,
    ) { }

    setAlertToggled(toggled: boolean) {
        this.alertToggled$.next(toggled);
    }
    setAdviceToggled(toggled: boolean) {
        this.adviceToggled$.next(toggled);
    }
    async getActionsFromDB(uid: string): Promise<any> {
        const actionRecords = {}
        await this.getSubcustomerUidAndName(uid).then(async (subcustomers) => {
            const promiseArray: Promise<any>[] = []

            for (const subcustomerKey in subcustomers) {
                promiseArray.push(this.getActionFromSDK(subcustomerKey, subcustomers[subcustomerKey]))
            }

            await Promise.all(promiseArray).then((actions) => {
                actions.forEach((actionRecord: Record<string, Action>) => {
                    if (actionRecord) {
                        for (const actionRecordKey in actionRecord) {
                            actionRecords[actionRecordKey] = actionRecord[actionRecordKey]
                        }
                    }
                })
            });
        })
        return actionRecords
    }

    async getActionFromSDK(subcustomerUid: string, subcustomerName: string) {
        return new Promise((resolve, reject) => {
            const actionSub: Subscription = this.readActions(subcustomerUid).snapshotChanges().subscribe((actionSnap) => {
                actionSub.unsubscribe()
                const actionRecord: Record<string, Action> = actionSnap.payload.val()
                for (const actionKey in actionRecord) {
                    actionRecord[actionKey].customer_name = subcustomerName
                }
                return resolve(actionRecord);
            });
        });
    }

    async getSubcustomerUidAndName(uid: string): Promise<Record<string, string>> {
        return new Promise((resolve: any, reject: any) => {
            this.subcustomerUids[uid] = 'My account'
            const subcustomer_sub: Subscription = this.customerService.readSubCustomers(uid).snapshotChanges().subscribe((subcustomer_snap) => {
                subcustomer_sub.unsubscribe()
                if (subcustomer_snap.payload.val()) {
                    const subcustomers: Record<string, SubCustomer> = subcustomer_snap.payload.val()
                    for (const subuid in subcustomers) {
                        this.subcustomerUids[`${subuid}_operated_by_${uid}`] = subcustomers[subuid].name
                    }
                }
                return resolve(this.subcustomerUids);
            });
        });
    }

    async getActionsFromBackend(): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(
                `${environment.baseUrl}/api_actions/actions/all`,
            )).then((res: Record<string, Action>) => {
                // Adds customer name to action objects, as Backend does not do this.
                for (const actionKey in res) {
                    res[actionKey].customer_name = this.subcustomerUids[res[actionKey].customer_id]
                }
                return resolve(res);
            })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    readActions(uid: string): AngularFireObject<Record<string, Action>> {
        return this.db.object(`actions/${uid}`);
    }

    async checkAction(action: Action, logKeys: string[]): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${environment.baseUrl}/api_actions/close`,
                {
                    action,
                    logKeys,
                    userName: (JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser).name
                },
            )).then((res: Record<string, Action>) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    async uncheckAction(action: Action, logKey: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${environment.baseUrl}/api_actions/unclose`,
                {
                    action,
                    logKey,
                    userName: (JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser).name
                },
            )).then((res: Record<string, Action>) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    async checkMultipleActions(action: Action, logKeys: string[]): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${environment.baseUrl}/api_actions/close_multiple`,
                {
                    action,
                    logKeys,
                    userName: (JSON.parse(localStorage.getItem('loggedInUser')) as LoggedInDashboardUser).name
                },
            )).then((res: Record<string, Action>) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    convertActionsToArray(actionsObj: Action[]): Action[] {
        const actionArray = []
        for (const actionKey in actionsObj) {
            actionArray.push(actionsObj[actionKey])
        }
        return actionArray
    }

    showNoActionsBox(actions, showClosed): boolean {
        const activeActions = []
        for (const action of actions) {
            if (action.active_actions) {
                activeActions.push(action)
            }
        }
        if (activeActions.length === 0 && !showClosed) {
            return true
        }
        return false
    }
}
