<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ 'billing.new_subscription' | translate }}</h4>
    </ng-container>
    <ng-container modal-body>
        <form [formGroup]="billingForm" *ngIf="!initLoad">
            <div class='billing-card-block'>
                <div class="payment-overview">
                    <dl *ngIf="details">
                        <div>
                            <dt class="details-name">{{ billing.shallow_account_details.name }}</dt>
                        </div>
                        <div>
                            <dt *ngIf="(details.vat_number_details && details.vat_number_details.value) || details.vat_number_not_provided"
                                class="details-text">{{
                                    (details.vat_number_details && details.vat_number_details.value) ||
                                    ((details.business_type !== 'individual' && details.vat_number_not_provided) ?
                                        ('account.vat_number_not_provided_company'|translate) :
                                        ('account.vat_number_not_provided'|translate))
                                }}
                            </dt>
                        </div>
                        <div>
                            <dt *ngIf="billing.shallow_account_details.address.line1" class="details-text">
                                {{ billing.shallow_account_details.address.line1 }}
                            </dt>
                        </div>
                        <div>
                            <dt *ngIf="billing.shallow_account_details.address.line2" class="details-text">
                                {{ billing.shallow_account_details.address.line2 }}
                            </dt>
                        </div>
                        <div>
                            <dt class="details-text">
                                {{ billing.shallow_account_details.address.postal_code }},
                                {{ billing.shallow_account_details.address.city }}
                            </dt>
                        </div>
                        <div>
                            <dt class="details-text">
                                {{
                                    shallowAccountDetails.address.state ? (shallowAccountDetails.address.state + ', ') :
                                        ''
                                }}{{ helperService.getCountryTranslated(shallowAccountDetails.address.country) }}
                            </dt>
                            <dd class="details-payment-method" *ngIf="companyDetails">
                                {{ 'billing.payment_method' | translate }}: {{
                                    ('billing.' +
                                        companyDetails.billed_by.toLowerCase()) | translate
                                }}{{
                                    billedByProvided &&
                                    billing.ean_number ? ' (EAN)' : ''
                                }}
                            </dd>
                        </div>
                        <div>
                            <dt *ngIf="companyDetails && companyDetails.ean_number" class="details-text">
                                EAN number: {{ companyDetails.ean_number }}
                            </dt>
                        </div>
                    </dl>
                </div>

                <!--                Payment type radio buttons-->
                <div style="margin: 10px 0" *ngIf="!billedByProvided && region!=='us'">
                    <label>{{ 'billing.payment_by' | translate }}</label>
                    <mat-radio-group formControlName="paymentMethod" class="aw-radio-wrapper">
                        <mat-radio-button class=" aw-radio" [value]="'invoice'" labelPosition="before"
                                          [checked]="paymentMethod.value==='invoice'">
                            {{ 'billing.invoice' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio" [value]="'card'" labelPosition="before"
                                          [checked]="paymentMethod.value==='card'">
                            {{ 'billing.credit_card' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- EAN radio buttons-->
                <div [hidden]="paymentMethod.value === 'card' || shallowAccountDetails.address.country !=='DK' || billedByProvided"
                     style="margin-bottom: 10px;">
                    <label>{{ 'billing.use_ean'|translate }}</label>
                    <mat-radio-group class="aw-radio-wrapper" (change)="handleEanStateChange($event)">
                        <mat-radio-button class=" aw-radio" [value]="true" labelPosition="before">
                            {{ 'misc.yes' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="aw-radio" [value]="false" labelPosition="before" [checked]="!useEan">
                            {{ 'misc.no' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- EAN input fields-->
                <div [hidden]="!useEan || billedByProvided">
                    <div class="input-group-wrapper ean-information">
                        <label class='input-label'>{{ 'billing.ean_number' | translate }}</label>
                        <input autocomplete='new-ean_number' (focusout)="validateEanNumber()" (focus)="clearPending()"
                               name='ean_number' class='form-control' type='text' formControlName='ean_number'>
                        <div *ngIf='ean_number.invalid && (eanFingerprint || formSubmitted)'>
                            <div class='error-msg' *ngIf='ean_number.errors.required'>
                                {{ 'billing.ean_required' | translate }}
                            </div>
                            <div class='error-msg' *ngIf='ean_number.errors.maxLength'>
                                {{ 'billing.ean_max' | translate }}
                            </div>
                            <div class='error-msg' *ngIf='ean_number.errors.invalid'>
                                {{ 'billing.ean_invalid' | translate }}
                            </div>
                            <div class='text-primary' *ngIf='ean_number.errors.pending'>
                                {{ 'billing.ean_validating' | translate }}
                            </div>
                        </div>
                        <div *ngIf="ean_number.valid && eanNumberIsValid" class='success-msg'>
                            {{ 'billing.ean_valid' | translate }}
                        </div>
                    </div>
                    <div class="input-group-wrapper">
                        <label class='input-label'>{{ 'billing.contact_person' | translate }}</label>
                        <input autocomplete='new-economic_contact_name' name='economic_contact_name'
                               class='form-control' type='text' formControlName='economic_contact_name'>
                        <div *ngIf='formSubmitted && economic_contact_name.invalid'>
                            <div class='error-msg' *ngIf='economic_contact_name.errors.required'>
                                {{ 'billing.contact_required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Billing plans-->
                <label class="billing-plan-label">Billing plan</label>
                <div class='plans'>
                    <!-- Invoice plans -->
                    <div [className]="'plans-wrapper-invoice'" [hidden]="paymentMethod.value === 'card'">
                        <!-- [hidden]="price.interval === 1" on the line below is because we are currently not offering monthly on invoice due to all the invoices it will generate -->
                        <div *ngFor='let price of invoicePrices; let i = index' class='plan-option'
                             id='planPreferenceDivInvoice' [hidden]="price.interval === 1">
                            <input [type]="billedByProvided && billedBy === 'card' ? null : 'radio'" name='plan'
                                   value='invoice_{{price.id}}' formControlName='plan'
                                   [readonly]="billedByProvided && billedBy === 'card'"
                                   (click)="billedByProvided && billedBy === 'card' ? null : planPreference(price, 'invoice')"
                                   id='invoice_{{price.id}}'>
                            <label for='invoice_{{price.id}}'>
                                <div class='plan-price-label'>
                                    <div class="top-price-label">
                                        <span class='first'>
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).firstPart }}
                                        </span>
                                        <span class='second'>{{ getPriceLabelPlan(price.unit_amount, price.interval).secondPart }}</span>
                                    </div>
                                    <div class='plan-price-label-subnote'>
                                        <div>
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).currency }}
                                            {{ 'billing.machine_month' | translate }}
                                        </div>
                                        <div *ngIf="coupon" class="pt-1">{{ 'billing.normal_price' | translate }}
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).combined }}
                                        </div>
                                    </div>
                                </div>
                                <div class='interval-label'>{{ getIntervalLabel(price.interval) }}</div>
                                <div class='annual-savings' *ngIf="i !== 2">
                                    {{ 'billing.save' | translate }} {{
                                        (getAnnualSavings(price.unit_amount,
                                            price.interval, getHighestPrice(invoicePrices).unit_amount,
                                            getHighestPrice(invoicePrices).interval) * selectedDevices.length).toFixed(2)
                                    }}
                                    {{ currency.toUpperCase() }}/{{ 'misc.year' | translate }}
                                </div>
                                <div class="plan-checkmark">
                                    <img src="../../../../../assets/icons/check-circle.svg" alt="Checkmark">
                                </div>
                            </label>
                        </div>
                    </div>
                    <!-- Card plans -->
                    <div [className]="'plans-wrapper-card'" [hidden]="paymentMethod.value==='invoice'">
                        <div *ngFor='let price of cardPrices; let i = index' class='plan-option'
                             id='planPreferenceDivCard'>
                            <input [type]="'radio'" name='plan' value='card_{{price.id}}' formControlName='plan'
                                   (click)="planPreference(price, 'card')" id='card_{{price.id}}'>
                            <label for='card_{{price.id}}'>
                                <div class='plan-price-label'>
                                    <div class="top-price-label">
                                        <span class='first'>
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).firstPart }}
                                        </span>
                                        <span class='second'>
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).secondPart }}
                                        </span>
                                    </div>
                                    <div class='plan-price-label-subnote'>
                                        <div>
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).currency }}
                                            {{ 'billing.machine_month' | translate }}
                                        </div>
                                        <div *ngIf="coupon" class="pt-1">{{ 'billing.normal_price' | translate }}
                                            {{ getPriceLabelPlan(price.unit_amount, price.interval).combined }}
                                        </div>
                                    </div>
                                </div>
                                <div class='interval-label'>{{ getIntervalLabel(price.interval) }}</div>
                                <div class='annual-savings' *ngIf="i !== 2">
                                    {{ 'billing.save' | translate }} {{
                                        getAnnualSavings(price.unit_amount,
                                            price.interval, getHighestPrice(cardPrices).unit_amount,
                                            getHighestPrice(cardPrices).interval)
                                    }} {{ currency.toUpperCase() }}/{{
                                        'misc.year' |
                                            translate
                                    }}
                                </div>
                                <div class="plan-checkmark">
                                    <img src="../../../../../assets/icons/check-circle.svg" alt="Checkmark">
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                    <div class="feedback-container" style="min-height: 20px;">
                        @if (formSubmitted && plan.invalid) {
                            <div class='error-msg' *ngIf='plan.errors.required'>
                                {{ 'billing.plan_required' | translate }}
                            </div>
                        }
                    </div>

                <!-- Payment details -->

                <div class="order-reference-input" [hidden]="paymentMethod.value==='card'">
                    <label class='input-label'>{{ 'billing.order_reference' | translate }}</label>
                    <input autocomplete='order_reference' name='order_reference' class='form-control' type='text'
                           formControlName='order_reference' placeholder="-">
                    <div *ngIf='formSubmitted && order_reference.invalid'>
                        <div class='error-msg' *ngIf='order_reference.errors.required'>
                            {{ 'billing.order_reference_required' | translate }}
                        </div>
                        <div class='error-msg' *ngIf='order_reference.errors.maxlength'>
                            {{ 'billing.order_reference_max_length' | translate }}
                        </div>
                    </div>
                </div>

                <div [hidden]="paymentMethod.value !== 'card'">
                    <app-billing-card-modal (hasCard)="catchHasSaved($event)"/>
                </div>

                <div class="insurance-checkbox custom-tool-tip-wrapper">
                    <label class="input-label">Airwallet+
                        <img style="margin-bottom: 3.5px;" (mouseenter)="insuranceInfoHover=true"
                             (mouseleave)="insuranceInfoHover=false" src="../../../../assets/icons/info-circle.svg"
                             alt="{{'billing.insurance' | translate}}">

                        <div class="custom-tool-tip placement-down" *ngIf="insuranceInfoHover">
                            <div class="mb-2">
                                <span><img style="margin: 0 8px 3px 0;"
                                           src="../../../../assets/icons/info-circle_white.svg"
                                           alt="{{'billing.insurance' | translate}}"></span>
                                <span class="tooltip-title">{{
                                        'billing.insurance' |
                                            translate
                                    }}</span>
                            </div>
                            <div>
                                <span style=" font-weight: 600;"> {{
                                        'billing.insurance_description_long_pt1' |
                                            translate
                                    }} </span>{{ 'billing.insurance_description_long_pt2' | translate }}
                            </div>
                            <ul>
                                <li>{{ 'billing.insurance_description_long_pt3' | translate }}</li>
                                <li>{{ 'billing.insurance_description_long_pt4' | translate }}</li>
                                <li>{{ 'billing.insurance_description_long_pt5' | translate }}</li>
                                <li>{{ 'billing.insurance_description_long_pt6' | translate }}</li>
                            </ul>
                            <div class="mt-2">
                                {{ 'billing.insurance_description_long_pt7' | translate }}
                            </div>
                            <div class="mt-2">
                                {{ 'billing.insurance_description_long_pt8' | translate }}
                            </div>
                        </div>
                    </label>
                    <app-aw-checkbox [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                     [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                     [text]="('billing.insurance_label' | translate)" [textPosition]="'after'"
                                     [initialCheckedValue]="true" (checkStateEmitter)="insurance.setValue($event)"/>
                </div>


                <div class="billing-card-block">
                    <div class='payment-overview'>
                        <dl>
                            <div>
                                <dt>{{ 'billing.sub_period' | translate }}:</dt>
                                <dd>{{ getIntervalLabel(selectedPlan ? selectedPlan.price.interval : null) }}</dd>
                            </div>
                            <div>
                                <dt>{{ 'billing.quantity' | translate }}:</dt>
                                <dd>{{ selectedDevices.length }} {{
                                        selectedDevices.length === 1 ? ('misc.device' |
                                            translate) : ('misc.devices' | translate)
                                    }}
                                </dd>
                            </div>
                            <div>
                                <dt>{{ 'billing.monthly_price' | translate }}:</dt>
                                <dd>{{ getPriceLabelDevice().combined }}</dd>
                            </div>
                            <div>
                                <dt>{{ 'billing.insurance' | translate }}:</dt>
                                <dd>
                                    {{ getInsurancePriceLabelSub().combined }}
                                </dd>
                            </div>
                            <div>
                                <dt>{{ 'billing.sub_total' | translate }}:</dt>
                                <dd>
                                    {{ getPriceLabelSubTotal().combined }}
                                </dd>
                            </div>

                            <section *ngIf="Object.keys(discounts).length > 0">
                                <br>
                                <div>
                                    <dt><strong>{{ 'billing.discount' | translate }}</strong></dt>
                                </div>
                                <div>
                                    <table style="width: 100%">
                                        <tr *ngFor="let dis of discounts | keyvalue">
                                            <td style="width: 15px">{{ dis.value }}</td>
                                            <td style="width: 15px">x</td>
                                            <td>{{ dis.key }}%</td>
                                            <td style="text-align: end">
                                                <dd>-{{ getDiscountLabel(dis.value, dis.key).combined }}</dd>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </section>

                            <!--Total-->
                            <section class='total-wrapper'>
                                <!-- VAT -->
                                <div>
                                    <dt>{{ vatTier }}% {{ 'billing.vat' | translate }}:</dt>
                                    <dd>{{ getPriceLabelVat().combined }}</dd>
                                </div>
                                <div>
                                    <dt>{{ ('billing.total_vat' | translate) }}:</dt>
                                    <dd class="billing-total-val">
                                        {{ getPriceLabelTotal().combined }}
                                    </dd>
                                </div>
                            </section>
                        </dl>
                    </div>
                </div>
            </div>
        </form>

        <loading-indicator *ngIf="initLoad" [size]="'large'" [color]="'secondary'" class="mt-4">
        </loading-indicator>
    </ng-container>
    <ng-container modal-footer>
        <div class="subscribe-btn" *ngIf="!initLoad">
            <button class="btn btn-secondary" [disabled]="subscriptionPending" (click)="setupSubscription()">
                {{ subscriptionPending ? '' : 'billing.subscribe' | translate }}
                <loading-indicator *ngIf="subscriptionPending" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>